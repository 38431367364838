import "../../App.css";
import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { FinanceSidebarData } from "../../Module/financeSidebarData";

//revenue details
const TransactionDetails = lazy(() => import("../CXOPanel/transactionDetails"));
const TransactionEdutech = lazy(() => import("../CXOPanel/transactionEdutech"));
const TransactionEdutechStudyMaterial = lazy(() =>
  import("../CXOPanel/transactionEdutechStudyMaterial")
);
const TransactionPublication = lazy(() => import("./transactionPublication"));
const PaymentPublication = lazy(() => import("../CXOPanel/paymentPublication"));

//revenue analysis
const SourceAnalysis = lazy(() => import("../CXOPanel/sourceWiseAnalysis"));
const StateAnalysis = lazy(() => import("../CXOPanel/stateWiseAnalysis"));
const RevenueProjection = lazy(() => import("../CXOPanel/revenueProjection"));
const RevenueAnalysis = lazy(() => import("../CXOPanel/cxoDashboard"));
const AllExamWiseRevenue = lazy(() => import("../CXOPanel/allExamWiseRevenue"));
const ExamWiseRevenue = lazy(() => import("../CXOPanel/examWiseRevenue"));

//admission - edutech
const BatchwiseCount = lazy(() => import("./batchwiseCount"));
const DatewiseCount = lazy(() => import("./datewiseCount"));
const RevenueDetails = lazy(() => import("./revenueDetails"));

const AssuredRevenue = lazy(() => import("./assuredRevenue"));
const ReferEarn = lazy(() => import("./ReferEarn/referEarn"));
const ReferPay = lazy(() => import("./ReferEarn/referralPayment"));
const ReferEarnDetail = lazy(() => import("./ReferEarn/referEarnDetail"));

//invoice management
const AddInvoice = lazy(() => import("./addInvoice"));
const InvoiceList = lazy(() => import("./invoiceList"));

const RefundList = lazy(() => import("./refundList"));

const ClassroomStudents = lazy(() => import("./classroomStudents"));

const UserInvoice = lazy(() => import("./userInvoice"));

const DownloadReports = React.lazy(() => import("../CXOPanel/downloadReports"));

function FinanceDashboard() {
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={FinanceSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<TransactionEdutech />} />
              <Route
                path="transactionEdutech"
                element={<TransactionEdutech />}
              />
              <Route
                path="transactionEdutechStudyMaterial"
                element={<TransactionEdutechStudyMaterial />}
              />
              <Route path="BatchwiseCount" element={<BatchwiseCount />} />
              <Route path="DatewiseCount" element={<DatewiseCount />} />
              <Route path="RevenueDetails" element={<RevenueDetails />} />
              <Route
                path="transactionPublication"
                element={<TransactionPublication />}
              />
              <Route path="assuredRevenue" element={<AssuredRevenue />} />
              <Route path="addInvoice" element={<AddInvoice />} />
              <Route path="referEarn" element={<ReferEarn />} />
              <Route path="referEarnDetail" element={<ReferEarnDetail />} />
              <Route path="referralPay" element={<ReferPay />} />
              <Route path="pendingForProcess" element={<InvoiceList />} />
              <Route path="pendingForPayment" element={<InvoiceList />} />
              <Route path="paidInvoice" element={<InvoiceList />} />
              <Route path="refundList" element={<RefundList />} />
              <Route
                path="financeClassroomStudent"
                element={<ClassroomStudents />}
              />
              <Route
                path="paymentPublication"
                element={<PaymentPublication />}
              />
              <Route
                path="transactionDetails"
                element={<TransactionDetails />}
              />
              <Route path="sourceWiseAnalysis" element={<SourceAnalysis />} />
              <Route path="stateWiseAnalysis" element={<StateAnalysis />} />
              <Route path="RevenueProjection" element={<RevenueProjection />} />
              <Route path="revenueAnalysis" element={<RevenueAnalysis />} />
              <Route
                path="allExamWiseRevenue"
                element={<AllExamWiseRevenue />}
              />
              <Route path="examWiseRevenue" element={<ExamWiseRevenue />} />
              <Route path="userInvoice" element={<UserInvoice />} />
              <Route path="downloadReports" element={<DownloadReports />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default FinanceDashboard;
