import React, { useEffect } from "react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  postLogin,
  setInitialState,
  updateValues,
} from "../../Action/authAction";
import jwt_decode from "jwt-decode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logoCircle from "../../Images/logo1.png";

function Login() {
  const [username, setusername] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setpassword] = useState("");
  const [open, setOpen] = React.useState(false);
  let [hasErrorDiv] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  useEffect(() => {
    dispatch(updateValues("loader", false));
    dispatch(setInitialState());
  }, []);

  const handleSubmit = (event) => {
    const postData = {
      username: username,
      password: password,
    };
    dispatch(
      postLogin(
        postData,
        (res) => {
          var decoded = jwt_decode(res.data.token.access.token);
          console.log("the decoded value", decoded);
          dispatch(updateValues("userInfo", decoded));
          dispatch(
            updateValues("tokenExpire", res?.data?.token?.access?.expires)
          );
          if (decoded.role === "SUPADM" || decoded.role === 16)
            navigate("SuperAdminPanel");
          else if (decoded.role === "ADM" || decoded.role === 1)
            navigate("AdminPanel");
          else if (decoded.role === "DATAENTRY" || decoded.role === 6)
            navigate("DataEntryPanel");
          else if (decoded.role === "CATEGORY" || decoded.role === 2)
            navigate("CategoryPanel");
          else if (decoded.role === "PUBLICATION" || decoded.role === 11)
            navigate("Publication");
          else if (decoded.role === "FINANCE" || decoded.role === 7)
            navigate("Finance");
          else if (decoded.role === "TEACHER" || decoded.role === 17)
            navigate("TeacherPanel");
          else if (decoded.role === "CONFIG" || decoded.role === 3)
            navigate("ConfigurationData");
          else if (decoded.role === "CXO" || decoded.role === 5)
            navigate("CXOPanel");
          else if (decoded.role === "SALES" || decoded.role === 12)
            navigate("SalesUpsellingPanel");
          else if (decoded.role === "TECHNICAL" || decoded.role === 19)
            navigate("TechnicalSupportPanel");
          else if (decoded.role === "INFRA" || decoded.role === 9)
            navigate("InfraPanel");
          else if (decoded.role === "VPOPERATIONS" || decoded.role === 21)
            navigate("VpOperationsPanel");
          else if (decoded.role === "COUNSELLOR" || decoded.role === 4)
            navigate("CounsellorPanel");
          else if (decoded.role === "HOD" || decoded.role === 8)
            navigate("AcademicHeadPanel");
          else if (decoded.role === "SEO" || decoded.role === 13)
            navigate("SEOPanel");
          else if (decoded.role === "UPSELLING" || decoded.role === 20)
            navigate("UpSellingPanel");
          else if (decoded.role === "WEBINAR" || decoded.role === 22)
            navigate("BdePanel");
          else if (decoded.role === "SOCIALMEDIA" || decoded.role === 14)
            navigate("SocialMediaPanel");
          else if (decoded.role === "PAIDAD" || decoded.role === 10)
            navigate("PaidAdsPanel");
          else if (decoded.role === "SOCIALMEDIATEAM" || decoded.role === 15)
            navigate("SMTPanel");
          else if (decoded.role === "teachertask" || decoded.role === 18)
            navigate("TeacherTaskPanel");
          else if (decoded.role === "HROPERATIONS")
            navigate("HrOperationPanel");
          else if (decoded.role === "YOUTUBE")
            navigate("YoutubeDashboardPanel");
          else if (decoded.role === "INFRAMANAGER")
            navigate("InfraManagerPanel");
          else if (decoded.role === "MANAGER") navigate("ManagerPanel");
          else if (decoded.role === "RESULT") navigate("ResultPanel");
          else if (decoded.role === "DM MANAGER")
            navigate("DigitalMarketingPanel");
          else if (decoded.role === "CLO") navigate("CloPanel");
          else if (decoded.role === "SALESDIRECTOR")
            navigate("SalesDirectorPanel");
          // else if (decoded.role === "SUBROLE") navigate("InfluencerPanel");
          else if (decoded.role === "SUBROLE") navigate("SubRolePanel");
          else if (decoded.role === "INFLUENCER") navigate("InfluencerPanel");
        },
        () => {}
      )
    );

    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const validation = () => {
    return username === "" || password === "";
  };
  return (
    <div className="loginBg d-flex">
      <div className="loginContainer">
        <div className="logoDiv">
          <img src={logoCircle} className="img-fluid loginlogo " alt="logo" />
        </div>
        <form className="p-5" onSubmit={handleSubmit}>
          <h3 className="mb-5">Log in to your account</h3>
          <label>
            <input
              id="uname"
              type="text"
              name="username"
              value={username}
              className="form-control"
              onChange={(e) => {
                setusername(e.target.value);
              }}
              required
            />
            <span>Username</span>
          </label>

          <label className="mt-4">
            <input
              id="password"
              type={passwordShown ? "text" : "password"}
              name="password"
              value={password}
              className="form-control"
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              required
            />
            <span>Password</span>
            <span
              className="position-absolute pt-2 cursor viewPassword"
              onClick={handleToggle}
            >
              {passwordShown ? (
                <VisibilityIcon color="action" />
              ) : (
                <VisibilityOffIcon color="action" />
              )}{" "}
            </span>
          </label>

          <div className="col-auto d-flex mt-5">
            <button
              type="submit"
              className={`btn btn-primary mb-1 px-4 btn-primary`}
              disabled={validation()}
            >
              {" "}
              Submit{" "}
            </button>
          </div>

          {hasErrorDiv}
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            />
          </Stack>
        </form>
        {/* <div className="loginheader p-4 "></div> */}
      </div>
    </div>
  );
}

export default Login;
