import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router-dom";
import { InfluencerPanelSidebarData } from "../../Module/influencerPanelSidebarData";

const Dashboard = lazy(() => import("./dashboard"));
const RevenueAnalysis = lazy(() => import("./revenueAnalysis"));
const TotalRevenue = lazy(() => import("./totalRevenue"));
const LeadOrder = lazy(() => import("./leadOrder"));
const InfluencerRank = lazy(() => import("./influencerRank"));

const InfluencerDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={InfluencerPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="revenueAnalysis" element={<RevenueAnalysis />} />
              <Route path="totalRevenue" element={<TotalRevenue />} />
              <Route path="leadOrder" element={<LeadOrder />} />
              <Route path="influencerRank" element={<InfluencerRank />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default InfluencerDashboard;
