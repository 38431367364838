import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { SalesDirectorPanelSidebarData } from "../../Module/salesDirectorPanelSidebarData";

const TransactionEdutech = React.lazy(() =>
  import("../CXOPanel/transactionEdutech")
);
const TransactionEdutechStudyMaterial = React.lazy(() =>
  import("../CXOPanel/transactionEdutechStudyMaterial")
);

const SourceAnalysis = React.lazy(() =>
  import("../CXOPanel/sourceWiseAnalysis")
);
const StateAnalysis = React.lazy(() => import("../CXOPanel/stateWiseAnalysis"));
const AllExamWiseRevenue = React.lazy(() =>
  import("../CXOPanel/allExamWiseRevenue")
);

const CxoBatchwiseCount = React.lazy(() =>
  import("../CXOPanel/cxoBatchWiseCount")
);
const DatewiseCount = React.lazy(() => import("../CXOPanel/cxoDateWiseCount"));
const AssuredRevenue = React.lazy(() => import("../Finance/assuredRevenue"));
const SecondInstallmentPaid = React.lazy(() =>
  import("../Finance/secondInstallmentPaid")
);
const SecondInstallmentDue = React.lazy(() =>
  import("../Finance/secondInstallmentDue")
);

const RegisteredUser = React.lazy(() => import("../CXOPanel/registeredUser"));
const StudentCount = React.lazy(() => import("../CXOPanel/studentCount"));
const WebsiteLeadCount = React.lazy(() =>
  import("../CXOPanel/websiteLeadCount")
);
const ClassroomStudents = React.lazy(() =>
  import("../CXOPanel/classroomStudents")
);
const ExpertSupport = React.lazy(() => import("../CXOPanel/expertSupport"));
const LeadAnalysis = React.lazy(() => import("../CXOPanel/leadAnalysis"));
const ReferenceGenerated = React.lazy(() =>
  import("../CXOPanel/referenceGenerated")
);

const ReferenceManagerCount = React.lazy(() =>
  import("../CXOPanel/referenceManagerCount")
);
const ReferenceTeamLeadCount = React.lazy(() =>
  import("../CXOPanel/referenceTeamLeadCount")
);
const ReferenceLeadsCount = React.lazy(() =>
  import("../CXOPanel/referenceLeadsCount")
);
const DailyMeeting = React.lazy(() => import("../CXOPanel/dailyMeeting"));
const TeamLeadAssignedDateAnalysis = React.lazy(() =>
  import("../CXOPanel/teamLeadAssignedDateAnalysis")
);
const LeadAssignedDateAnalysis = React.lazy(() =>
  import("../CXOPanel/leadAssignedDateAnalysis")
);
const LeadAssignedStatusOverview = React.lazy(() =>
  import("../CXOPanel/leadAssignedStatusOverview")
);
const CompleteLeadDetail = React.lazy(() =>
  import("../CXOPanel/completeLeadDetail")
);

const LeadGenerationOverview = React.lazy(() =>
  import("../CXOPanel/leadGenerationOverview")
);
const LeadOverview = React.lazy(() => import("../CXOPanel/leadOverview"));
const TeamLeadsOverview = React.lazy(() =>
  import("../CXOPanel/teamLeadsOverview")
);
const CounsellorLeadOverview = React.lazy(() =>
  import("../CXOPanel/counsellorLeadOverview")
);

const SalesPerformance = React.lazy(() =>
  import("../CXOPanel/salesPerformance")
);

const StudentResult = React.lazy(() =>
  import("../CategoryPanel/studentResult")
);
const ResultCompilation = React.lazy(() =>
  import("../CategoryPanel/resultCompilation")
);

function SalesDirectorPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={SalesDirectorPanelSidebarData} />
      <div className="dashboard">
        {/* <Header /> */}
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<TransactionEdutech />} />
              <Route
                path="transactionEdutech"
                element={<TransactionEdutech />}
              />
              <Route
                path="transactionEdutechStudyMaterial"
                element={<TransactionEdutechStudyMaterial />}
              />

              <Route path="sourceWiseAnalysis" element={<SourceAnalysis />} />
              <Route path="stateWiseAnalysis" element={<StateAnalysis />} />
              <Route
                path="allExamWiseRevenue"
                element={<AllExamWiseRevenue />}
              />

              <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
              <Route path="datewiseCount" element={<DatewiseCount />} />
              <Route path="assuredRevenue" element={<AssuredRevenue />} />
              <Route
                path="secondInstallmentPaid"
                element={<SecondInstallmentPaid />}
              />
              <Route
                path="secondInstallmentDue"
                element={<SecondInstallmentDue />}
              />

              <Route path="registeredUser" element={<RegisteredUser />} />
              <Route path="allSourceLead" element={<StudentCount />} />
              <Route path="websiteLeadCount" element={<WebsiteLeadCount />} />
              <Route path="classroomStudents" element={<ClassroomStudents />} />
              <Route path="expertSupport" element={<ExpertSupport />} />
              <Route path="leadAnalysis" element={<LeadAnalysis />} />
              <Route
                path="referenceGenerated"
                element={<ReferenceGenerated />}
              />

              <Route
                path="referenceManagerCount"
                element={<ReferenceManagerCount />}
              />
              <Route
                path="referenceTeamLeadCount"
                element={<ReferenceTeamLeadCount />}
              />
              <Route
                path="referenceLeadsCount"
                element={<ReferenceLeadsCount />}
              />
              <Route path="dailyMeeting" element={<DailyMeeting />} />
              <Route
                path="teamLeadAssignedDateAnalysis"
                element={<TeamLeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedDateAnalysis"
                element={<LeadAssignedDateAnalysis />}
              />
              <Route
                path="leadAssignedStatusOverview"
                element={<LeadAssignedStatusOverview />}
              />
              <Route
                path="completeLeadDetail"
                element={<CompleteLeadDetail />}
              />

              <Route
                path="leadGenerationOverview"
                element={<LeadGenerationOverview />}
              />
              <Route path="leadOverview" element={<LeadOverview />} />
              <Route path="teamLeadsOverview" element={<TeamLeadsOverview />} />
              <Route
                path="counsellorLeadOverview"
                element={<CounsellorLeadOverview />}
              />

              <Route path="salesPerformance" element={<SalesPerformance />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default SalesDirectorPanelDashboard;
