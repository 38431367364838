export const SocialMediaManagerData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
];
export const SocialMediaSideBarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },

  {
    title: "DUMMY ACCOUNT",
    subNav: [
      {
        title: "CREATE",
        path: "createDummyAccount",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editDummyAccount",
      },
    ],
  },
  {
    title: "FACEBOOK GROUP",
    subNav: [
      {
        title: "Create",
        path: "createFacebookGroup",
        cName: "sub-nav",
      },
      {
        title: "Edit/Delete",
        path: "editDeleteFacebookGroup",
        cName: "sub-nav",
      },
    ],
  },
];
