import React, { useEffect, useState } from "react";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAdminSupport,
  getExamListNew,
  getParentCourseNew,
} from "../../Action/courseAction";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../Component/usePagination";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import { getTableDataSlots } from "../../Utils/dataConstants";
import DatePicker from "../../Component/datePicker";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import moment from "moment";

const TechnicalSupportNew = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const supportTypeList = [
    { _id: 1, name: "App Related" },
    { _id: 2, name: "Course Related" },
  ];

  const [state, setState] = useState({
    startDate: moment.utc(moment.utc(new Date())).format("YYYY-MM-DD"),
    endDate: moment.utc(moment.utc(new Date())).format("YYYY-MM-DD"),
    examName: "",
    parentCourseVal: "",
    examList: [],
    parentCourseList: [],
    technicalSupportList: [],
    selectedStatus: 1,
    page: 0,
    count: 0,
    rowsPerPage: 10,
    currentPage: 1,
    supportType: {
      _id: "",
      name: "",
    },
  });
  const updateState = (key, value) => {
    if (typeof key === "object") {
      setState((prevState) => ({
        ...prevState,
        ...key,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };
  const totalPages = Math.ceil(state?.count / state?.rowsPerPage);
  const _DATA = usePagination(state?.count, state?.rowsPerPage);
  const parentCourse = location.state?.baseCourseId;
  const screenName = location?.state?.screeName;
  const status = location?.state?.selectedStatus;
  const exam = location.state?.examTypeId;
  const sDate = location?.state?.startDate;
  const eDate = location?.state?.endDate;

  const getData = (postData) => {
    dispatch(
      getAdminSupport(
        postData,
        (res) => {
          updateState({
            count: res?.data?.count,
            technicalSupportList: res?.data?.data,
          });
        },
        () => {}
      )
    );
  };
  const onChangeData = (key, value) => {
    const {
      startDate,
      endDate,
      parentCourseVal,
      examName,
      selectedStatus,
      rowsPerPage,
      supportType,
    } = state;
    if (key === "startDate") {
      updateState("startDate", value);
    } else if (key === "endDate") {
      updateState("endDate", value);
      if (value < state?.startDate) {
        dispatch(showErrorSnackbar("End date must be greater than start date"));
        return;
      }
      let reqBody = {
        skip: 0,
        limit: rowsPerPage,
        supportType: 1,
      };
      if (screenName === "chat") {
        reqBody.startDate = sDate;
        reqBody.endDate = eDate;
        reqBody.status = status;
        if (exam) reqBody.examTypeId = exam?._id;
        if (parentCourse) reqBody.baseCourseId = parentCourse?._id;
      } else {
        reqBody.startDate = startDate;
        reqBody.endDate = value;
        reqBody.status = selectedStatus;
        if (examName) reqBody.examTypeId = examName?._id;
        if (parentCourseVal) reqBody.baseCourseId = parentCourseVal?._id;
      }
      getData(reqBody);
    } else if (key === "examName") {
      updateState("examName", value);
      dispatch(
        getParentCourseNew(value?._id, (resp) => {
          updateState("parentCourseList", resp);
        })
      );
      let reqBody = {
        skip: 0,
        limit: rowsPerPage,
        supportType: 1,
      };
      if (screenName === "chat") {
        reqBody.startDate = sDate;
        reqBody.endDate = eDate;
        reqBody.status = status;
        if (exam?._id) reqBody.examTypeId = exam?._id;
        if (parentCourse?._id) reqBody.baseCourseId = parentCourse?._id;
        if (supportType?.name !== "")
          reqBody.technicalSupportType = supportType?._id;
      } else {
        reqBody.startDate = startDate;
        reqBody.endDate = endDate;
        reqBody.status = selectedStatus;
        reqBody.examTypeId = value?._id;
        if (parentCourseVal) reqBody.baseCourseId = parentCourseVal?._id;
        if (supportType?.name !== "")
          reqBody.technicalSupportType = supportType?._id;
      }
      getData(reqBody);
    } else if (key === "examStream") {
      updateState("parentCourseVal", value);
      let reqBody = {
        skip: 0,
        limit: rowsPerPage,
        supportType: 1,
        examTypeId: examName?._id,
        baseCourseId: value?._id,
      };
      if (screenName === "chat") {
        reqBody.startDate = sDate;
        reqBody.endDate = eDate;
        reqBody.status = status;
        if (supportType?.name !== "")
          reqBody.technicalSupportType = supportType?._id;
      } else {
        reqBody.startDate = startDate;
        reqBody.endDate = endDate;
        reqBody.status = selectedStatus;
        if (supportType?.name !== "")
          reqBody.technicalSupportType = supportType?._id;
      }
      getData(reqBody);
    } else if (key === "supportType") {
      updateState("supportType", value);
      let reqBody = {
        skip: 0,
        limit: rowsPerPage,
        supportType: 1,
        examTypeId: examName?._id,
        baseCourseId: parentCourseVal?._id,
        technicalSupportType: value?._id,
      };
      if (screenName === "chat") {
        reqBody.startDate = sDate;
        reqBody.endDate = eDate;
        reqBody.status = status;
      } else {
        reqBody.startDate = startDate;
        reqBody.endDate = endDate;
        reqBody.status = selectedStatus;
      }
      getData(reqBody);
    }
  };
  const showData = (e, val) => {
    e.preventDefault();
    const {
      rowsPerPage,
      parentCourseVal,
      examName,
      startDate,
      endDate,
      supportType,
    } = state;
    updateState("selectedStatus", val);
    const reqBody = {
      skip: 0,
      limit: rowsPerPage,
      supportType: 1,
      status: val,
      startDate,
      endDate,
    };
    if (screenName === "chat") {
      if (exam?._id) reqBody.examTypeId = exam?._id;
      if (parentCourse?._id) reqBody.baseCourseId = parentCourse?._id;
      if (supportType?.name !== "")
        reqBody.technicalSupportType = supportType?._id;
      getData(reqBody);
    } else {
      if (examName) reqBody.examTypeId = examName?._id;
      if (parentCourseVal) reqBody.baseCourseId = parentCourseVal?._id;
      if (supportType?.name !== "")
        reqBody.technicalSupportType = supportType?._id;

      getData(reqBody);
    }
  };
  const handleChange = (e, page) => {
    onPageChange(page, state?.rowsPerPage, 1);
    updateState({
      page: page - 1,
      currentPage: page,
    });
    _DATA.jump(page);
  };
  const handleChangePage = (event, newPage) => {
    updateState({
      page: newPage,
      currentPage: newPage + 1,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      updateState({
        rowsPerPage: parseInt(state?.count),
        page: 0,
        currentPage: 1,
      });
      onPageChange(state?.page, value, 3);
      return;
    } else {
      updateState({
        rowsPerPage: parseInt(value),
        page: 0,
        currentPage: 1,
      });
      onPageChange(state?.page, value, 2);
    }
  };
  const onPageChange = (page, rowsPerPage, flag) => {
    const {
      startDate,
      endDate,
      selectedStatus,
      parentCourseVal,
      examName,
      count,
      supportType,
    } = state;
    let data = {
      startDate,
      endDate,
      supportType: 1,
      status: selectedStatus,
    };
    if (flag === 1) {
      data.skip = (page - 1) * rowsPerPage;
      data.limit = rowsPerPage;
    } else if (flag === 2) {
      data.skip = 0;
      data.limit = rowsPerPage;
    } else if (flag === 3) {
      data.skip = 0;
      data.limit = count;
    }
    if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
    if (examName) data.examTypeId = examName?._id;
    if (supportType?.name !== "") data.technicalSupportType = supportType?._id;
    getData(data);
  };
  const chat = (id, userId, status) => {
    const { examName, parentCourseVal, selectedStatus, startDate, endDate } =
      state;
    navigate("../chat", {
      state: {
        id: id.toString(),
        userId,
        status,
        screeName: "technicalSupport",
        baseCourseId: {
          _id: parentCourseVal?._id,
          name: parentCourseVal?.name,
        },
        selectedStatus,
        examTypeId: {
          _id: examName?._id,
          name: examName?.name,
        },
        startDate,
        endDate,
      },
    });
  };
  const renderSearch = () => {
    const {
      startDate,
      endDate,
      examName,
      examList,
      parentCourseVal,
      parentCourseList,
      supportType,
    } = state;
    return (
      <>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 d-lg-flex align-items-center">
            <LabelField labelName={"Start Date"} />
            <DatePicker
              setInputText={(value) => onChangeData("startDate", value)}
              value={startDate}
              Isrequired={true}
            />
          </div>
          <div className="col-md-6 col-lg-4 mb-3 d-lg-flex align-items-center">
            <LabelField labelName={"End Date"} />
            <DatePicker
              setInputText={(value) => onChangeData("endDate", value)}
              value={endDate}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 d-lg-flex align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              setInputText={(value) => onChangeData("examName", value)}
              defaultValue={examName?._id}
              options={examList}
              labelName="Select Exam Name"
            />
          </div>
          <div className="col-md-6 col-lg-4 mb-3 d-lg-flex align-items-center">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              setInputText={(value) => onChangeData("examStream", value)}
              defaultValue={parentCourseVal?._id}
              options={parentCourseList}
              labelName="Select Exam Stream"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 d-lg-flex align-items-center">
            <LabelField labelName={"Support Type"} />
            <CustomDropdown
              setInputText={(value) => onChangeData("supportType", value)}
              defaultValue={supportType?._id}
              options={supportTypeList}
              labelName="Select Support Type"
            />
          </div>
        </div>
      </>
    );
  };
  const renderList = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={state?.currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          count={state?.count}
          title={"Issues"}
          tableData={
            state?.technicalSupportList ? state?.technicalSupportList : []
          }
          tabs={["New Issue", "In Progress", "Resolved"]}
          onTabChange={(e, index) => showData(e, index)}
          selectedIndex={state?.selectedStatus}
          page={state?.page}
          rowsPerPage={state?.rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(state?.count)}
        />
        <PaginationTable
          tableData={
            state?.technicalSupportList ? state?.technicalSupportList : []
          }
          pagename={"technicalSupport"}
          rowsPerPage={state?.rowsPerPage}
          page={state?.page}
          tableHead={[
            "S.N",
            "Issue ID",
            "Issue Date",
            "Student Name",
            "Mobile No",
            "Batch Name",
            "Issue Name",
            "Support Type",
            "Token Type",
            "Status",
          ]}
          handleAddOnButton={(id, user, status) => chat(id, user, status)}
          edit={true}
        />
      </>
    );
  };
  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        if (res?.length) updateState("examList", res);
        else updateState("examList", []);
      })
    );
    if (screenName === "chat") {
      updateState({
        startDate: sDate,
        endDate: eDate,
        parentCourseVal: parentCourse?._id,
        examName: exam?._id,
        selectedStatus: status,
      });
      const reqBody = {
        startDate: sDate,
        endDate: eDate,
        supportType: 1,
        status: status,
      };
      getData(reqBody);
    } else {
      const reqBody = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        supportType: 1,
        status: state?.selectedStatus,
      };
      getData(reqBody);
    }
  }, []);
  return (
    <div className="contentBox">
      <PageTitle name="TECHNICAL SUPPORT" />
      <div className="formDiv formWidth col-12">
        {renderSearch()}
        {renderList()}
      </div>
    </div>
  );
};

export default TechnicalSupportNew;
