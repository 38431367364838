import services from "../Constant/service";
import endpoint from "../Constant/endpoint";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";
import { SET, UPDATE } from "../Types/courseTypes";
import { updateValues } from "./authAction";
import moment from "moment";

export const setParentCourse = (key, value) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      [key]: value,
    },
  });
};

export const modifyParentCourse = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: {
      [key]: value,
    },
  });
};

// *****************************************************
// Configuration
// *****************************************************
export const getConfiguration =
  (callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.configuration.getConfiguration,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getConfigurationEmail =
  (callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.configuration.getConfigurationEmail,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateConfiguration =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.configuration.updateConfiguration + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateConfigurationEmail =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.configuration.updateConfigurationEmail + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getConfigurationAdminEmail =
  (callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.configuration.getConfigurationAdminEmail,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateConfigurationAdminEmail =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.configuration.updateConfigurationAdminEmail + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAdditionalDiscount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.configuration.getAdditionalDiscount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// export const updateAdditionalDiscount =
//   (data, id, callback, errorCallback) => async (dispatch) => {
//     dispatch(updateValues("loader", true));
//     services.patchApiCall(
//       endpoint.configuration.updateAdditionalDiscount + id,
//       data,
//       (res) => {
//         dispatch(updateValues("loader", false));
//         if (res?.data?.statusCode === services.status_code.success) {
//           dispatch(showSuccessSnackbar(res?.data?.msg));
//           callback(res);
//         } else {
//           if (res?.code) {
//             dispatch(showErrorSnackbar(res?.response?.data?.msg));
//           } else {
//             dispatch(showErrorSnackbar(res?.data?.msg));
//           }
//         }
//       },
//       (error) => {
//         dispatch(
//           showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//         );
//         dispatch(updateValues("loader", false));
//         errorCallback(error);
//       }
//     );
//   };

export const getCountryCode = (callback, errorCallback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.configuration.getCountryCode,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        errorCallback(res);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Parent Course
// *****************************************************

export const getParentCourseNew = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.examStream.getExamStreamByExamId,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamStreamByTeacherId = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.examStream.getExamStreamByTeacherId,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ****************************************************
// city List
// ****************************************************
export const getCities = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getCities + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
// ****************************************************
// State List
// ****************************************************
export const getState = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getState,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//******************************************************
// Exam Name
// *****************************************************
// Action Not in used
// export const AddExam = (data, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.postApiCall(
//     endpoint.exam.addExam,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.statusCode === services.status_code.success) {
//         dispatch(showSuccessSnackbar(res?.msg));
//         callback(res?.data?.data);
//       } else {
//         dispatch(showErrorSnackbar(res?.msg));
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// Action Not in used
// export const getExamByState = (data, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.getApiCall(
//     endpoint.exam.getExamByState,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.data?.statusCode === services.status_code.success) {
//         // dispatch(showSuccessSnackbar(res?.data?.msg));
//         callback(res?.data?.data);
//       } else {
//         dispatch(showErrorSnackbar(res?.msg));
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// Action Not in used
// export const getExamByStateNew = (data, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.getApiCall(
//     endpoint.exam.getExamByStateNew,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.data?.statusCode === services.status_code.success) {
//         callback(res?.data?.data);
//       } else {
//         dispatch(showErrorSnackbar(res?.msg));
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// Action Not in used
// export const UpdateExam = (data, examId, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.patchApiCall(
//     endpoint.exam.updateExam + examId,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.data?.statusCode === services.status_code.success) {
//         dispatch(showSuccessSnackbar(res?.data?.msg));
//         callback(res);
//       } else {
//         if (res?.code) {
//           dispatch(showErrorSnackbar(res?.response?.data?.msg));
//         } else {
//           dispatch(showErrorSnackbar(res?.data?.msg));
//         }
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// *****************************************************
// Sub Exam
// *****************************************************
// Action Not in used
// export const AddSubExam = (data, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.postApiCall(
//     endpoint.subExam.addSubExam,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.statusCode === services.status_code.success) {
//         dispatch(showSuccessSnackbar(res?.msg));
//         callback(res);
//       } else {
//         if (res?.msgCode === "511") {
//           dispatch(
//             showErrorSnackbar("subExamNames contains a duplicate value")
//           );
//         } else if (res?.msgCode === "717") {
//           dispatch(showErrorSnackbar(res?.msg));
//         } else {
//           dispatch(showErrorSnackbar(res?.data?.msg));
//         }
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// Action Not in used
// export const updateSubExam = (data, id, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.patchApiCall(
//     endpoint.subExam.updateSubExam + id,
//     data,
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.data?.statusCode === services.status_code.success) {
//         dispatch(showSuccessSnackbar(res?.data?.msg));
//         callback(res?.data?.data);
//       } else {
//         if (res?.code) {
//           dispatch(showErrorSnackbar(res?.response?.data?.msg));
//         } else {
//           dispatch(showErrorSnackbar(res?.data?.msg));
//         }
//       }
//     },
//     (error) => {
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

// *****************************************************
// Language
// *****************************************************

export const getAssignExamList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.assignExam.assignExamList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const assignExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.putApiCall(
    endpoint.assignExam.assignExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const assignExamNew = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.putApiCall(
    endpoint.assignExam.assignExamNew,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamList = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.assignExam.assignExamListParent + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamListNew = (callback) => async (dispatch, getState) => {
  const { examList } = getState().courseReducer;
  if (examList?.length > 0) callback(examList);
  else {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.exam.getExamList,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(setParentCourse("examList", res?.data?.data));
          callback(res?.data?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  }
};

export const getExamListTeacher = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.assignExam.assignExamListParentTeacher,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Subject
// *****************************************************

// Action Not in used
// export const getSubjectListFromLecture =
//   (data, callback) => async (dispatch) => {
//     dispatch(updateValues("loader", true));
//     services.postApiCall(
//       endpoint.subject.getSubjectListFromLecture,
//       data,
//       (res) => {
//         dispatch(updateValues("loader", false));
//         if (res?.statusCode === services.status_code.success) {
//           callback(res);
//         } else {
//           dispatch(showErrorSnackbar(res?.msg));
//         }
//       },
//       (error) => {
//         dispatch(
//           showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//         );
//         dispatch(updateValues("loader", false));
//       }
//     );
//   };

export const getFilteredSubjectList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subject.getFilteredSubjectList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Add Books
// *****************************************************
export const addBooks = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addBooks,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBooksList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getBooksList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBooksPackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.assignBooks.getBooksPackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    () => {
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteBook = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.course.deleteBook,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deletePackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.package.deletePackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else if (res?.response?.data?.msgCode === "1115") {
        dispatch(showErrorSnackbar(res?.response?.data?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExtendedPackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.packageExtend.deleteExtendedPackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExtendedPackage =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.packageExtend.updateExtendedPackage + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(modifyParentCourse("state", { id, ...data }));
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const uploadBookIcon = (data, type, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.course.uploadBookCover + type,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadPyqPdf = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.examSet.uploadPyqPdf,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadMedia = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.examSet.uploadMedia,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateBook = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.updateBook + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(modifyParentCourse("state", { id, ...data }));
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *****************************************************
// Assign Books
// *****************************************************
export const postAssignBooks = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.assignBooks.addPackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteAssignBook = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.assignBooks.deleteAssignBook + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//  **************************************************************
//  Main Category
//  ************************************************************

// *****************************************************
// Section
// *****************************************************

export const getSectionList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.section.getSectionList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// export const getNewSectionList =
//   (callback, errorCallback) => async (dispatch) => {
//     dispatch(updateValues("loader", true));
//     services.getApiCall(
//       endpoint.section.getNewSectionList,
//       "",
//       (res) => {
//         dispatch(updateValues("loader", false));
//         if (res?.data?.statusCode === services.status_code.success) {
//           callback(res?.data);
//         } else {
//           errorCallback(res);
//           dispatch(showErrorSnackbar(res?.msg));
//         }
//       },
//       (error) => {
//         dispatch(
//           showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//         );
//         dispatch(updateValues("loader", false));
//         errorCallback(error);
//       }
//     );
//   };

// Action not in Used
// export const getOldSectionList =
//   (id, callback, errorCallback) => async (dispatch) => {
//     dispatch(updateValues("loader", true));
//     services.getApiCall(
//       endpoint.section.getOldSectionList,
//       id,
//       (res) => {
//         dispatch(updateValues("loader", false));
//         if (res?.data?.statusCode === services.status_code.success) {
//           callback(res?.data);
//         } else {
//           errorCallback(res);
//           dispatch(showErrorSnackbar(res?.msg));
//         }
//       },
//       (error) => {
//         dispatch(
//           showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//         );
//         dispatch(updateValues("loader", false));
//         errorCallback(error);
//       }
//     );
//   };

// Action not in Used
// export const addBindSection =
//   (data, callback, errorCallback) => async (dispatch) => {
//     dispatch(updateValues("loader", true));
//     services.postApiCall(
//       endpoint.section.addBindSection,
//       data,
//       (res) => {
//         dispatch(updateValues("loader", false));
//         if (res?.statusCode === services.status_code.success) {
//           callback();
//           dispatch(showSuccessSnackbar(res?.msg));
//         } else {
//           dispatch(showErrorSnackbar(res?.msg));
//           errorCallback();
//         }
//       },
//       (error) => {
//         dispatch(
//           showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//         );
//         dispatch(updateValues("loader", false));
//         errorCallback();
//       }
//     );
//   };

// *****************************************************
// Assign Unit (Lecture Series)
// *****************************************************
export const addLecturesUnit =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignUnits.addLecturesUnit,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getLecturesList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignUnits.getLecturesList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteLectures =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.assignUnits.deleteLecturesUnit,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateLecturesUnit =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.assignUnits.updateLecturesUnit + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
// ****************************************************
// Package Extend
// ****************************************************
export const getPackageListByID =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.course.getPackageListByID,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const saveExtendedPackage =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.packageExtend.saveExtendedPackage,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ****************************************************
// Teacher
// ****************************************************
export const CreateTeacher =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.addTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getTeacherList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.teacher.getTeacherList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeacherListAll = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.teacher.getTeacherListAll,
    {},
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTeacherListNew = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.teacher.getTeacherListNew,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAllTeachersList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getAllTeachersList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRemainingParentList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getRemainingParentList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRemainingExamList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getRemainingExamList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRemainingSubExamList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.getRemainingSubExamList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeachersData =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.teacher.getTeachersData,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteTeacher =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.teacher.deleteTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateTeacher =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.teacher.updateTeacher + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data.data);
        } else {
          errorCallback(res);
          if (res?.response?.data?.msgCode === "809") {
            dispatch(showErrorSnackbar("loginId already exist."));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ****************************************************
// Ad Banner Type
// ****************************************************
export const addBannerType =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.adBannerType.addBannerType,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          if (res?.msgCode === "511") {
            dispatch(showErrorSnackbar("Duplicate type is not allowed"));
          } else if (res?.msgCode === "761") {
            dispatch(showErrorSnackbar(res?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBannerTypeList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.adBannerType.getBannerTypeList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          //dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteBannerType =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.adBannerType.deleteBannerType,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateBannerType =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.adBannerType.updateBannerType + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
// *****************************************************
// Pattern
// *****************************************************
export const AddExamSection =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.pattern.addExamSection,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPatternList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.pattern.getExamSection,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOldPatternList =
  (callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.pattern.getOldPatternList,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getNewPatternList =
  (callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.pattern.getNewPatternList,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addBindPatterns =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.pattern.addBindPatterns,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback();
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const getPatternByExamType =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.pattern.getPatternList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getBatchList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *****************************************************
// Admit Card
// *****************************************************

// *****************************************************
// Coupon Code
// *****************************************************
export const addCoupon =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCouponCode.addCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addCounsellorCoupon =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCounsellorCouponCode.addCounsellorCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addCounsellorCouponNew =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCounsellorCouponCode.addCounsellorCouponNew,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addCounsellorCouponExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCouponCode.addCounsellorCouponExam,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMainCategoryById =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.mainCategory.getMainCategoriesById,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getAllCoupons =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.edutechCounsellorCouponCode.getAllCoupons,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const filterCoupon =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCouponCode.filterCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const counsellorCouponList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.counsellorCouponList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const counsellorCouponListNew =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.counsellorCouponListNew,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteCoupon =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.edutechCouponCode.deleteCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          if (res?.code && res?.response?.data?.msgCode === "550") {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCoupon =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.edutechCouponCode.updateCoupon + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCounsellorCoupon =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.course.updateCounsellorCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *****************************************************
// BB Meeting Id
// *****************************************************
export const addBBMeeting =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.bbMeetingId.addBBMeetingNew,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getNewBBBList = (callback, errorCallback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.bbMeetingId.getNewBBBList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        errorCallback(res);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      errorCallback(error);
    }
  );
};

export const getOldBBBList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.bbMeetingId.getOldBBBList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addBindBBBMeetings =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.bbMeetingId.addBindBBBMeetings,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback();
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const getbbMeetingList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.bbMeetingId.getBBMeetingListNew,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteBBBMeeting =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.bbMeetingId.deleteBBBMeetingNew,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateBBBMeeting =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.bbMeetingId.updateBBBMeetingNew + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *****************************************************
// Branch
// *****************************************************

// *****************************************************
// Not Used
// *****************************************************
export const filterCouponByName =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.filterCouponByName,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };
export const sendWhatsAppMsg = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.whatsAppMsg.sendWhatsAppMsg,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getTopicList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.topic.getTopicList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTopicListFromLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.topic.getTopicListFromLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getFilteredTopicList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.topic.getFilteredTopicList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const updateTopic = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.topic.updateTopic + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateSubTopic = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.subTopic.updateSubTopic + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateNewLecture = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.lecture.updateNewLecture + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubTopicList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.subTopic.getSubTopicList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getNewLectureList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.lecture.getNewLectureList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSubTopicListFromLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.subTopic.getSubTopicListFromLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDurations =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.general.getDurations,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const postPackage =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.package.addCoursePackage,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPackageList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.package.getPackageList,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getPackageListWithFilter =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.package.getPackageListWithFilter,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          if (res?.msgCode === "533") {
            dispatch(showErrorSnackbar(res?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPackageBasecourseList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.package.getPackageBasecourseList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const updateLectureSeriesPackage =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.package.updateLectureSeries + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateCoursePackage =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.package.updateCourse + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateTestSeriesPackage =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.package.updateTestSeries + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateStudyMaterialPackage =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.package.updateStudyMaterial + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchName =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getBatchName,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getBatchNameWithSessions =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getBatchNameWithSessions,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExtendedPackages =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.packageExtend.getExtendedPackagesList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const AddTopic = (data, callback, errorCallback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.topic.addTopic,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        errorCallback(res);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      errorCallback(error);
    }
  );
};

export const AddSubTopic =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.subTopic.addSubTopic,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const AddLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.lecture.addLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addDPPExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.addDpp,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const addUnitTestExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.addUnitTest,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addMockTestExam =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.examSet.addMockTest,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

// ****************************************************
// ****************************************************
export const addYoutube =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addYoutube,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addPYQTestExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.addPYQ,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getYoutubeList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.getYoutubeList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const deleteYoutubeMeeting =
  (data, callback, errorCallback) => async (dispatch) => {
    services.deleteApiCall(
      endpoint.course.youtubeMeeting,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const addScholarshipExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.addScholarshipTest,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const postPackageLS =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.package.addLecturesSPackage,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseSubscriptionPlan =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.package.getCourseSubscriptionPlan,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

// *****************************************************
// Ad Banner
// *****************************************************

export const addBanner =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addBanner,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadBanner =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.course.uploadBanner,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAdBannerlist =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.getAdBannerlist,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const deleteAdBanner =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.course.deleteAdBanner,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateAdBanner =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.course.updateAdBanner + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const postPackageSM =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.package.addSMPackage,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAssignedBookList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignBooks.getAssignedBookList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamSet =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.getExamSet,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamSet =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.examSet.deleteExamSet,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/*********************************** */
export const updateExamSection = (data, callback) => async (dispatch) => {
  services.patchApiCall(
    endpoint.course.updateExamSection,
    data,
    (res) => {
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const getExamByID =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.question.getExamByID,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

/*********************************** */

export const addEBooks =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.addEBooks,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getExamQuesList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.examSet.getExamQuesList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

/************************Add Question*************************** */
export const addQues = (data, callback) => async (dispatch) => {
  services.postApiCall(
    endpoint.question.addQues,
    data,
    (res) => {
      if (res?.statusCode === services.status_code.success) {
        // dispatch(modifyParentCourse("state", { id, ...data }));
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const getVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.liveRecordedVideo.getVideoLectExams,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };
export const addVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallNew(
      endpoint.liveRecordedVideo.addVideoLectExams,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getVideoLectExamsList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.liveRecordedVideo.getVideoLectExamsList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.liveRecordedVideo.deleteVideoLectExams,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateVideoLectExams =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.liveRecordedVideo.updateVideoLectExams + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadQuestionImage =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.question.uploadQueImg,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getQuestionById =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.question.getExamQuestion,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };
/*****************Display Question************************** */

export const getExamQuestion =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.examSet.getExamQuesList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamQuestionNew =
  (id, data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.getExamQuesListNew + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamQuestionSubjects =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.examSet.getExamQuestionSubjects,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getExamQuestionTopics =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.getExamQuestionTopics,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamQuestionYear =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.getExamQuestionYear,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
/****************Delete Exam Question*********************** */

export const updateQuestion =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.question.updateQuestion + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/****************Delete Exam Question*********************** */
export const deleteExamQuestion =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.question.deleteExamQuestion,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addPremVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.premierVideo.addPremVideoLectExams,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addPremVideoLectExams1 =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.premierVideo.addPremVideoLectExams1,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addFreeUserVideoLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.freeUserVideo.addFreeUserVideoLectExams,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/*************************************************** */
export const addNotification =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.notification.addNotification,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getNotificationIconList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.notification.getNotificationIconList,
      "",
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getNotificationList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.notification.getNotification,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteNotification =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.notification.deleteNotification,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateNotification =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.notification.updateNotification + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
/*************************************************** */

export const getPremVideoLectExamsList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.premierVideo.getPremVideoLectExamsList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getFreeLiveVideoLectureList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.freeUserVideo.getFreeLiveVideoLectureList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };
// ****************************************************
// Assign Teacher
// ****************************************************
export const asignTeacher =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignTeacher.asignTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getSubjectListWithTopics =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignTeacher.getSubjectListWithTopics,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getAsignedTeacherList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.assignTeacher.getAsignedTeacherList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteAssignTeacher =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.assignTeacher.deleteAssignTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateAssignTeacher =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.assignTeacher.updateAssignTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteTopic =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.topic.deleteTopic,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Topic delete");
      }
    );
  };

export const deleteSubTopic =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.subTopic.deleteSubTopic,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Sub-Topic delete");
      }
    );
  };

export const deleteNewLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.lecture.deleteNewLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Sub-Topic delete");
      }
    );
  };

export const getLiveschedule =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getLiveschedule,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateLiveSchedule =
  (data, isFree, callback) => async (dispatch) => {
    services.patchApiCall(
      isFree
        ? endpoint.course.updateLiveFreeSchedule
        : endpoint.course.updateLiveSchedule,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const getLiveScheduleFiltered =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getLiveScheduleFiltered,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseSupportList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getCourseSupportList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg ? res?.msg : res?.data?.msg));
        callback(res);
      }
    },
    () => {
      callback({});
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCourseSupportResolve =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.course.updateCourseSupportResolve + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const closeRaiseIssue =
  (id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.course.closeRaiseIssue + id,
      {},
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateResolveTeacherIssue =
  (id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.teacherHours.closeIssue + id,
      {},
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeacherHoursList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherHours.getTeacherHoursList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addTeacherHelpDeskIssue =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherHours.addTeacherHelpDeskIssue,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getHelpDeskIssueList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherHours.getHelpDeskIssueList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeacherHelpDeskMessagesList =
  (data, isTeacher, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      isTeacher
        ? endpoint.teacherHours.getTeacherHelpDeskMessagesList
        : endpoint.teacherHours.getTeacherAdminHelpDeskMessagesList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const getTeacherIssuesList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherSupport.teacherIssuesList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/*****************copy Exam set*************************** */

export const copyExamset =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.examSet.copyExamset,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/*************************************************** */
export const getAdminSupport =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.technicalSupport.getAdminSupport,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg ? res?.msg : res?.data?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addLiveVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.liveVideo.addLiveVideoLectExams,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addLiveVideoLectExamsNew =
  (data, isRTMP, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      isRTMP
        ? endpoint.liveVideo.addLiveVideoLectExamsNew
        : endpoint.liveVideo.addLiveVideoLectExamsNewBBB,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRtmpUrl =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.liveVideo.getRtmpUrl,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getLiveVideoLectExams =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.liveVideo.getLiveVideoLectExams,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

/**************************Free Lectures******************************** */
export const addFreeLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addFreeLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const addFreeLectureV2 =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.youtubeLive.addFreeLectureV2,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getFreeLecture = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeLive.getFreeLecture,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      callback([]);
    }
  );
};

export const deleteFreeLecture = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.youtubeLive.deleteFreeLecture,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadYouTubeIcon = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.youtubeLive.uploadYoutubeIcon,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      callback("");
    }
  );
};

export const updateYoutubeLecture =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.youtubeLive.updateYoutubeLecture}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(true);
        } else {
          callback(false);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        callback(false);
      }
    );
  };

/*****************getAppVersion**************** */
export const getAppVersion =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.getAppVersion,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res.data.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const updateAppVersion = (data, id, callback) => async (dispatch) => {
  services.patchApiCall(
    endpoint.course.updateAppVersion + id,
    data,
    (res) => {
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const changeActiveStatusExamset =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.examSet.changeActiveStatusExamset,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res.status === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res.response.data.msg));
          callback(res);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getMessageList =
  (id, data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getMessageList + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg ? res?.msg : res?.data?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const getRaiseIssueMessageList =
  (id, data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.getRaiseIssueMessageList + id,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const getRaiseIssueTeacherMessageList =
  (id, data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.getRaiseIssueTeacherMessageList + id,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const addSupportMessage =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addSupportMessage,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addHelpDeskMessage =
  (data, isTeacher, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      isTeacher
        ? endpoint.teacherHours.addMessage
        : endpoint.teacherHours.addMessageAdmin,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addTeacherIssue =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addTeacherIssue,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addAdminIssue =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addAdminIssue,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadSupportMedia =
  (data, type, callback, errorCallback) => async (dispatch) => {
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.course.uploadSupportMedia + type,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStudentCount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getStudentCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));

          if (res?.data?.data?.length > 0) {
            const newData = res?.data?.data?.sort(
              (a, b) => b.studentCount - a.studentCount
            );
            callback({
              ...res,
              data: { data: newData, count: res?.data?.count },
            });
          } else callback(res);
        } else {
          callback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStateStudentCount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getStateStudentCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          if (res?.data?.data?.length > 0) {
            const newData = res?.data?.data?.sort(
              (a, b) => b.studentCount - a.studentCount
            );
            callback({ ...res, data: { data: newData } });
          } else callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getupcomingExpiry =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getupcomingExpiry,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getexpiredAccount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getexpiredAccount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// ************************* getRegisteredUserList ***************************
export const getRegisteredUserList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getRegisteredUserList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
// ************************* getExpertSupportList ***************************
export const getExpertSupportList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getExpertSupportList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCallToExpertSupportList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.callToExpertSupport.getCallToExpertSupportList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getCallToExpertSupportRemarkList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.callToExpertSupport.getCallToExpertSupportRemarkList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addExpertSupportRemark =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.callToExpertSupport.addExpertSupportRemark,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(showErrorSnackbar(error?.msg ? error?.msg : error?.msg));
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
// ************************* getBranchList ***************************
export const getBranchList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.getBranchList,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

/***********************Get RTMP LIST******************************** */

export const getrtmplist =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.getrtmplist,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
      }
    );
  };

export const getUpcomingLiveList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getUpcomingLiveList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// *******************************addBatch****************************************
export const addBatch = (data, callback, errorCallback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addBatch,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        errorCallback(res);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
      errorCallback(error);
    }
  );
};
// ************************* getBatchListFilter ***************************
export const getBatchListFilter =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.course.getBatchListFilter,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };
// ************************* getClassroomRegisteredList ***************************
export const getClassroomRegisteredList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getClassroomRegisteredList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRegisteredUser =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.registrationDetails.getRegisteredUser,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.status === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateUserBaseCourse =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.registrationDetails.updateUserBaseCourse,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

/***************************************************** */

export const getBatchWiseCount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.admissionAnalysis.getBatchWiseCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDateWiseCount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.admissionAnalysis.getDateWiseCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRevenueCount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getRevenueCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamWiseAnalysis =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getExamWiseAnalysis,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(
            showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getExamWiseRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamWiseRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const createNewSubscription =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.registrationDetails.createNewSubscription,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(updateValues("loader", false));
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(updateValues("loader", false));
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const updateOldSubscription =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.registrationDetails.updateOldSubscription + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
/****************************************************** */
export const getExamResult =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getExamResult,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getEdutechTransaction =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getEdutechTransaction,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getEdutechTransactionNew =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getEdutechTransactionNew,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getEdutechOnlineCourse =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getEdutechOnlineCourse,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStudyMaterialOrderList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getStudyMaterialOrderList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPrintSMOrderList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getPrintSMOrderList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStockManagementList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getBookStockList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUserExamResultDetails =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getUserExamResultDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPublicationTransaction =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getPublicationTransaction,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getPayPublication =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getPayPublication,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateSMTrackingorder = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.updateSMTrackingorder,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(updateValues("loader", false));
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const doubtSolveReview =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.doubtSolveReview.doubtSolveReview,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUserFeedbackData =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getUserFeedbackData,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUserFeedbackDataByTeacher =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getUserFeedbackDataByTeacher,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSessionList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.general.getSessionList,
      data,
      (res) => {
        if (res?.status === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getCourseFeedback =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.course.getCourseFeedback,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLectureFeedback =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.getLectureFeedback,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getCourseTeacherFeedback =
  (data, callback, errorCallback) => async (dispatch) => {
    services.getApiCall(
      endpoint.course.getCourseTeacherFeedback,
      data,
      (res) => {
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const getLiveLectures =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.liveVideo.getLiveLectures,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const addimportRecordedvideo =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.addimportRecordedvideo,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getClassroomBatch =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getClassroomBatch,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateClassroomBatch = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.updateClassroomBatch,
    data,
    (res) => {
      dispatch(updateValues("loader", false));

      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(updateValues("loader", false));
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const deletebatch =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.course.deletebatch,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Subject delete");
      }
    );
  };

export const updateExamAdmin =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.examSet.updateExamAdmin,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateBatchLive =
  (data, id, type, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      type === 1
        ? `${endpoint.liveVideo.updateBatchLive}${id}`
        : `${endpoint.primeClass.updateBatchPre}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const updateBatchRec =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.liveRecordedVideo.updateBatchRec}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const deletePremierVideoLecture =
  (_id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.premierVideo.deletePremierVideoLecture,
      _id,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.status === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Subject delete");
      }
    );
  };

export const deleteFreeLiveVideoLecture =
  (_id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.freeUserVideo.deleteFreeLiveVideoLecture,
      _id,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.status === services.status_code.success) {
          callback(res);
          dispatch(showSuccessSnackbar(res?.msg ? res?.msg : res?.data?.msg));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Subject delete");
      }
    );
  };

export const deleteLiveVideoLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.liveVideo.deleteLiveVideoLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Subject delete");
      }
    );
  };

export const updateLiveLecture =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.liveVideo.updateLiveLecture}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const updateFreeLiveLecture =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.freeUserVideo.updateFreeLiveLecture}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updatePremierLecture =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.premierVideo.updatePremierLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback();
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const getExamSectionById =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.pattern.getExamSectionById,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateExamSectionById =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.pattern.updateExamSectionById,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback();
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const deleteExamSectionById =
  (_id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.pattern.deleteExamSectionById,
      _id,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Exam Section delete");
      }
    );
  };

//Add Teacher Hours
export const addTeacherHours =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherHours.addTeacherHours,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback();
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const editTeacherHoursList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacherHours.editTeacherHoursList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteTeacherHoursList =
  (_id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.teacherHours.deleteTeacherHoursList,
      _id,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.status === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error, "Error in Subject delete");
      }
    );
  };

export const updateTeacherHours =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.teacherHours.updateTeacherHours + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateRecordedImportVideo =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.course.updateRecordedImportVideo}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const getLiveLecturesList =
  (data, callback, errorCallback) => async (dispatch) => {
    services.postApiCall(
      endpoint.liveClassSchedule.getLiveLecturesList,
      data,
      (res) => {
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        errorCallback(error);
      }
    );
  };

export const updateLiveLecturesById =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.liveClassSchedule.updateLiveLecturesById}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
          errorCallback();
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback();
      }
    );
  };

export const addExamSyllabus =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.addExamSyllabus,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateExamSyllabusTitleDesc =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.updateExamSyllabusTitleDesc,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getExamSyllabusList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.getExamSyllabusList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateExamSyllabus =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.aboutExam.updateExamSyllabus + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteExamSyllabus =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.aboutExam.deleteExamSyllabus,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addAllAboutExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.addAllAboutExam,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAllAboutExam =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.getAllAboutExam,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateAllAboutExam =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.aboutExam.updateAllAboutExam + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addExamPaperAnalysis =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.addExamPaperAnalysis,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getExamPaperAnalysis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.aboutExam.getExamPaperAnalysis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updatePaperAnalysis = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.aboutExam.updateExamPaperAnalysis + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamDatesIcons = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.aboutExam.getExamDatesIcons,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addExamDates = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.aboutExam.addExamDates,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamDates = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.aboutExam.getExamDates,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExamDates = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.aboutExam.updateExamDates + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(
          showErrorSnackbar(
            res?.code ? res?.response?.data?.msg : res?.data?.msg
          )
        );
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const startEndLiveStatus = (data, callback) => async (dispatch) => {
  services.patchApiCall(
    endpoint.aboutExam.startEndLiveStatus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        callback(res?.data);
        dispatch(showSuccessSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addBookStock = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addBookStock,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadBookStockReceipt = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.course.uploadBookReceipt,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBookStockHistory = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.course.getStockHistory + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBatchwiseAdmissionDetailslist =
  (id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.admissionAnalysis.getBatchwiseAdmissionDetails + id,
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addIssueType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.teacherHours.addIssueType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const issueTypeList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.teacherHours.issueTypeList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateIssueType = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.teacherHours.editIssueType + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteIssueType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.teacherHours.deleteIssueType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addRaiseIssue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.raiseIssue.addRaiseIssue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadIssueImage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.raiseIssue.uploadIssueImage,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const raiseIssueList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.raiseIssue.raiseIssueList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const teacherRaiseIssueList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.teacherHours.teacherRaiseIssueList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getStockBookList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getStockBookList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const submitStockOut = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.submitStockOut,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSellingSourceList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.course.getSellingSourceList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getScholarshipExamResult =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.aboutExam.getScholarshipExamResult,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getTeacherBatchList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getTeacherBatchList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTeacherSubjectList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getTeacherSubjectList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadstockcover = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.course.uploadstockcover,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getStockHistoryList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getStockHistoryList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteStock = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.deleteStock + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
/******************************************Job Type***************************************************/
export const addJobPosition = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Job.addJobPosition,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addJobTypeLogo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.Job.addJobTypeLogo,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getJobPosition = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.Job.getJobPosition,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateJobPosition = (data, id, callback) => async (dispatch) => {
  services.patchApiCall(
    endpoint.Job.updateJobPosition + id,
    data,
    (res) => {
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
    }
  );
};

export const deleteJobPosition = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.Job.deleteJobPosition,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
/********************************Career *****************************************/
export const addCareer = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.career.addCareer,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCareerList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.career.getCareerList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getdesignations = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.career.getdesignations,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCareer = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.career.updateCareer + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteCareer = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.career.deleteCareer,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getTeacher = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.course.getTeacher,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addissue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.addissue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const issuelist = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.issuelist,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getInfraSupportList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.getInfraSupportList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const infralistActiveClose = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.infralistActiveClose,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const issueRemark = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.issueRemark + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const issueDuration = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.infra.issueDuration,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadTeacherHelpdeskImage =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.teacherHours.uploadTeacherHelpdeskImage,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
// ****************************************************
// Batch Type
// ****************************************************
export const addBatchType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.batchType.addBatchType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(showErrorSnackbar("Duplicate type is not allowed"));
        } else if (res?.msgCode === "761") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBatchTypeList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.batchType.getBatchTypeList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteBatchType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.batchType.deleteBatchType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateBatchType = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.batchType.updateBatchType + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ****************************************************
// Course Type
// ****************************************************
export const addCourseType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.courseType.addCourseType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(showErrorSnackbar("Duplicate type is not allowed"));
        } else if (res?.msgCode === "761") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCourseTypeList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.courseType.getCourseTypeList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteCourseType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.courseType.deleteCourseType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCourseType = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.courseType.updateCourseType + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAllUser = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.user.getAllUser,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteUser = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.user.deleteUser,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateUser = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.user.updateUser + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ****************************************************
// CUT-OFF
// ****************************************************

export const getCutOffList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.cutOff.getCutOff,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(showErrorSnackbar("Duplicate type is not allowed"));
        } else if (res?.msgCode === "761") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCutOff = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.cutOff.updateCutOff + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addCutOff = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.cutOff.addCutOff,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(showErrorSnackbar("Duplicate type is not allowed"));
        } else if (res?.msgCode === "761") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateOrder = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.aboutExam.updateOrder,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addPYQPdf = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addPYQPdf,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadPyqPdfCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.course.uploadPyqPdfCategory,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getPyqPdfList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getPyqPdfList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updatePyqPdf = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.updatePyqPdf + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deletePyqPdf = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.course.deletePyqPdf,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addBindCourses = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.bindCourses.addBindCourses,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback();
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBindCoursesList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.bindCourses.getBindCoursesList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateBindCourses = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.bindCourses.updateBindCourses + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteBindCourses = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.bindCourses.deleteBindCourses,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadResultStudentPhotoVideo =
  (data, type, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.course.uploadStudentPhotoVideo + type,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const uploadExamStudentPhotoVideo =
  (data, type, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.course.uploadExamStudentPhotoVideo + type,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const addResults = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addResults,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addExamResults = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addExamResults,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamResultsTitleDesc = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamResultsTitleDesc,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamYearList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamYearList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getExamMonthList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamMonthList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamResultList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamResultList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getYearList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getYearList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getMonthList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getMonthList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getResultList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getResultList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamResultLists = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getExamResultLists,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getExamResultDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.getExamResultDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const replaceResultDate = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.replaceResultDate,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const copyResultList = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.copyResultList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteStudentResult = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.course.deleteStudentResult,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExamStudentResult = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.course.deleteExamStudentResult,
    `/${data?.type}/${data?.stdId}`,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateStudentResult = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.updateStudentResult + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExamResult = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.updateExamResult,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateExamStudentResult =
  (type, id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.course.updateExamStudentResult}/${type}/${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const addTeacherTask = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.addTeacherTask,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getTeacherTaskList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.teacherTaskPlan.getTeacherTaskList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        const newData = res?.data?.data?.sort(
          (a, b) => moment(b.taskDate) - moment(a.taskDate)
        );
        callback({
          ...res,
          data: { ...res?.data, data: newData },
        });
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteTeacherTask = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.course.deleteTeacherTask,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateTeacherTaskStatus =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.teacherTaskPlan.updateTeacherTaskStatus}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateTeacherTask = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.course.updateTeacherTask + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getListTeacherTask = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getListTeacherTask,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTeacherTaskRankingList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getTeacherTaskRankingList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const uploadSopPpt = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.sopPpt.uploadSopPpt,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addSopPpt = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.sopPpt.addSopPpt,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSopPptList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.sopPpt.getSopPptList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteSopPpt = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.sopPpt.deleteSopPpt,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addYoutubeRecordedVideo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallNew(
    endpoint.course.addYoutubeRecordedVideo,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addYoutubeRecordedVideoV2 =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallNew(
      endpoint.youtubeRecorded.addYoutubeRecordedVideoV2,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getYoutubeRecordedVideoList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.youtubeRecorded.getYoutubeRecordedVideoList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const uploadYoutubeRecPdf = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.youtubeLive.uploadYoutubeRecPdf,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getServersList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.servers.getServersList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getInstanceDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.servers.getInstanceDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getOTP = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.servers.getOTP,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateServerStatus = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.servers.updateServerStatus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadTeacherTaskImage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.teacherTaskPlan.uploadTeacherTaskImage,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAssuredRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getAssuredRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          const newData = res?.data?.data?.sort(
            (a, b) => b.studentCount - a.studentCount
          );
          callback({
            ...res,
            data: { ...res?.data, data: newData },
          });
        } else callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*****************Refer & Earn*************************** */

export const getReferData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getAllReferDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          callback(res?.data?.data);
        } else callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getReferrerData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getReferrerDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          callback(res?.data?.data);
        } else callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getReferPaymentDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getReferPayment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          callback(res?.data?.data);
        } else callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateReferPayment = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.course.updateReferPayment}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
/*****************copy Recorded Lecture*************************** */

export const copyRecordedLecture = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.copyRecordedLecture.copyRecordedLecture,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCopyRecordedLectureList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.copyRecordedLecture.getCopyRecordedLectureList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const deleteCopyRecordedLecture =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.copyRecordedLecture.deleteCopyRecordedLecture,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

/*************************************************** */

export const uploadMediaInvoice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.finance.uploadMediaInvoice,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));

      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addInvoice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.finance.addInvoice,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getInvoiceList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.finance.getInvoiceList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const patchInvoice = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.finance.patchInvoice + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addNewSyllabus = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.syllabus.addNewSyllabus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSyllabusList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.syllabus.getSyllabusList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSyllabusById = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.syllabus.getSyllabusById + data,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const editSyllabusById = (ids, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.syllabus.editSyllabus + ids,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteSyllabusId = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.syllabus.deleteSyllabus + data,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCounsellorList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.counsellor.getCounsellorList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addNewRefund = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.finance.addRefund,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getRefundList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.finance.getAllRefund,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCollegeList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.webinar.getCollegeList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getWebinarList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.webinar.getWebinarList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getPaidAdsList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.paidAds.getPaidAdsList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSocialMediaAd = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.socialMediaAds.getSocialMediaAd,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addConfigCoupon = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.config_coupon.addConfigCoupon,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getConfigCoupon = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.config_coupon.getConfigCoupon,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const editConfigCoupon = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.config_coupon.editConfigCoupon(id),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteConfigCoupon = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.config_coupon.deleteConfigCoupon + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getWebAppLeads = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.course.getWebAppLeads,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          const newData = res?.data?.data?.sort(
            (a, b) => b.studentCount - a.studentCount
          );
          callback({
            ...res,
            data: { data: newData, count: res?.data?.count },
          });
        } else callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getLeads = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.leadDashboard.getLeads,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));

        if (res?.data?.data?.length > 0) {
          const newData = res?.data?.data?.sort(
            (a, b) => b.studentCount - a.studentCount
          );
          callback({
            ...res,
            data: { data: newData, count: res?.data?.count },
          });
        } else callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getStateLeadCount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.leadDashboard.getStateLeadCount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        if (res?.data?.data?.length > 0) {
          const newData = res?.data?.data?.sort(
            (a, b) => b.studentCount - a.studentCount
          );
          callback({ ...res, data: { data: newData } });
        } else callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const sourceAnalysis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.analysis.sourceAnalysis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const stateWiseAnalysis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.analysis.stateWiseAnalysis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadIcn = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.categoryIncludeDescription.uploadIcn,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addCatDesc = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.categoryIncludeDescription.addCatDesc,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCatDesc = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.categoryIncludeDescription.getCatDesc,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        if (res?.msgCode === "511") {
          dispatch(showErrorSnackbar("Duplicate type is not allowed"));
        } else if (res?.msgCode === "761") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateCatDesc = (params, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.categoryIncludeDescription.updateCatDesc}${params}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteCatDesc = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.categoryIncludeDescription.deleteCatDesc}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const copyCategoryInclude = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.categoryIncludeDescription.copyCategoryInclude,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback(false);
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCopyCategoryInclude = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.categoryIncludeDescription.getCopyCategoryInclude,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//Teacher free  lecture yt
export const uploadTeacherImg = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.freeYoutubeLecture.uploadTeacherImg,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addTeacherData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.freeYoutubeLecture.addTeacherData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTeacherData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.freeYoutubeLecture.getTeacherData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const editTeacherData = (data, params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.freeYoutubeLecture.editTeacherData}${params}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteTeacherData = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.freeYoutubeLecture.deleteTeacherData}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTotalLeadCountByDate = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getTotalLeadCountByDate,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getManagerList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getManagerList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSMManagerList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getSMManagerList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeetByManagerId = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getEmployeetByManagerId,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTeamLeadAssignedOverview =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getTeamLeadAssignedOverview + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getCounsellorLeadAssignedOverview =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getCounsellorLeadAssignedOverview + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getLeadAssignedOverviewAnalysis =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getLeadAssignedOverviewAnalysis + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getSourceTypeList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getSourceTypeList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getStatusList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getStatusList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAssignedStudentLeadDetails =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getAssignedStudentLeadDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getStudentDetailsById = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.getStudentDetailsById,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCallHistory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getCallHistory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getLeadPaymentHistory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getLeadPaymentHistory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addManagerRemark = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.addManagerRemark + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//yotube session

export const addYTSession = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeSession.addYTSession,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadYoutubeReferredImage =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("files", data);
    services.postUploadMedia(
      `${endpoint.youtubeSession.uploadYoutubeReferredImage}`,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getDuplicateYoutube = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeSession.getDuplicateYoutube,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYTSession = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeSession.getYTSession,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYTLectureList = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.youtubeSession.getYTLectureList}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYTLectureDetail = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.youtubeSession.getYTLectureDetail}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateYTLectureDetail =
  (params, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.youtubeSession.updateYTLectureDetail}${params}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const addYTLecture = (params, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    `${endpoint.youtubeSession.addYTLecture}${params}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteYTLectureDetail = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.youtubeSession.deleteYTLectureDetail}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTotalLeadCount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getTotalLeadCount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getLeadCountExamWise = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getLeadCountExamWise,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getLeadCountStateWise = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getLeadCountStateWise,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getDailyMeetingForManager =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getDailyMeetingForManager,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getTeacherByExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getTeacherByExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadExamBanner = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.seo.uploadExamBanner,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addExamDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addExamDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.getExamDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExamDetails = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.seo.updateExamDetails + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExamDetails = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.seo.deleteExamDetails(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getWhatsAppRole = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.whatsAppConfig.getWhatsAppRole,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addWhatsappConfig = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.whatsAppConfig.addWhatsappConfig,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateWhatsappConfig =
  (params, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.whatsAppConfig.updateWhatsappConfig}${params}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const deleteWhatsappConfig = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.whatsAppConfig.deleteWhatsappConfig}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getWhatsappConfig = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.whatsAppConfig.getWhatsappConfig,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadFileShort = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("files", data);
  services.postUploadMedia(
    `${endpoint.examShotVideo.uploadFileShort}`,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addShotVideo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.examShotVideo.addShotVideo,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getShotVideo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.examShotVideo.getShotVideo,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateShotVideo = (data, params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.examShotVideo.updateShotVideo}${params}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteShotVideo = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.examShotVideo.deleteShotVideo}${params}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getOldPackageExist = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.oldPackageExist.getOldPackageExist,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    }
  );
};
export const getMsgDeliveredStatus = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.msgDeliveredStatus.getMsgDeliveredStatus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addAcademicHead = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.academicHead.addAcademicHead,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAcademicHeadList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.academicHead.getAcademicHeadList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAcademicHeadDetail = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.academicHead.getAcademicHeadDetail(`${id}`),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteAcademicHeadDetail =
  (params, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.deleteApiCall(
      endpoint.academicHead.deleteAcademicHeadDetail(`${params}`),
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateAcademicHeadDetail =
  (data, params, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.academicHead.updateAcademicHeadDetail(`${params}`),
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addRecordedLectureInCourse =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.addRecordedLectureInCourse.addRecordedLectureInCourse,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const academicHeadGetLeadCount =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.academicHead.getLeadCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));

          if (res?.data?.data?.length > 0) {
            const newData = res?.data?.data?.sort(
              (a, b) => b.studentCount - a.studentCount
            );
            callback({
              ...res,
              data: { data: newData, count: res?.data?.count },
            });
          } else callback(res);
        } else {
          callback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getExamListAcademic = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.academicHead.getExamList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubExamListAcademic = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.academicHead.getSubExamList(`${id}`),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getExamStreamListAcademic =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.academicHead.getBasecourse,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

//copy lectures actions
export const addCopyLecture = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.copyPackage.addCopyLecture,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCopyLecture = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.copyPackage.getCopyLecture,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteCopyLecture = (params, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.copyPackage.deleteCopyLecture(`${params}`),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//transaction details
export const getTransactionDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.transactionDetails.getTransactionDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getUserTransactionDetails =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxo.getUserTransactionDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getQuestionIssue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.questionIssues.getQuestionIssue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const questionDoubtUpdate = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    `${endpoint.questionIssues.questionDoubtUpdate}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTransactionForecast = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.cxo.gettransactionforecast,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// QuestionDoubt**************************************

export const questionIssueList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.questionDoubt.questionIssueList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateQuestionIssue = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.questionDoubt.updateQuestionIssue + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getReferenceAnalysisSuperManager =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getReferenceAnalysisSuperManager,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getReferenceAnalysisManager =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getReferenceAnalysisManager,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getReferencesTeamLeadList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getReferencesTeamLeadList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getRevenueLeadsAnalysisList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.crm.getRevenueLeadsAnalysisList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getLeadCountSource = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.sourceAnalysisLead.getLeadCountSource,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getMonthlyRevenue = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.cxo.getMonthlyRevenue,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// **********Question Issue Type ***********
export const addQuestionIssueType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.questionIssueTypes.addQuestionIssueType,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getQuestionIssueType = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.questionIssueTypes.getQuestionIssueType,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateQuestionIssueType =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.questionIssueTypes.updateQuestionIssueType}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const deleteQuestionIssueType = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.questionIssueTypes.deleteQuestionIssueType}${data}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ************Target*****************

export const getIncentiveAllocationList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.IncentiveAllocation.getIncentiveAllocationList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addIncentiveTarget = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.IncentiveAllocation.addIncentiveTarget,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addIncentiveTargetNewDate =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.IncentiveAllocation.addIncentiveTargetNewDate,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const updateIncentiveTarget =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.IncentiveAllocation.updateIncentiveTarget + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          if (res?.code) {
            dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const deleteIncentiveTarget = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.IncentiveAllocation.deleteIncentiveTarget,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTargetStatusDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.targetDashboard.getTargetStatusDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubDepartment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.course.getSubDepartment}/${data}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getMappingList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.mapping.getMappingList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getReferenceGenerated = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.referenceGenerated.getReferenceGenerated,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
/******************** Add Employee******************************************************* */

export const addEmployee = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.manageEmployee.addEmployee,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateEmployee = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.manageEmployee.updateEmployee + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteEmployee = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.manageEmployee.deleteEmployee,
    data,
    (res) => {
      const status = res?.data?.statusCode
        ? res?.data?.statusCode
        : res?.statusCode;
      const message = res?.data?.msg ? res?.data?.msg : res?.msg;
      dispatch(updateValues("loader", false));
      if (status === services.status_code.success) {
        dispatch(showSuccessSnackbar(message));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(message));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.manageEmployee.getEmployeeList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const employeeProfile = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.manageEmployee.employeeProfile,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeManagersList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.manageEmployee.getEmployeeManagersList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeRoleList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getEmployeeRoleList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeCompanyList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getEmployeeCompanyList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeDepartmentList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getEmployeeDepartmentList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeDesignationList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.general.getEmployeeDesignationList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getEmployeeDetailsById =
  (params, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.manageEmployee.getEmployeeDetailsById,
      params,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const restoreEmployee = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.manageEmployee.restoreEmployee,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getEmployeeSalary = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.manageEmployee.getEmployeeSalary,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addEmployeeAppraisal = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.manageEmployee.addEmployeeAppraisal,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      const statusCode = res?.data?.statusCode
        ? res?.data?.statusCode
        : res?.statusCode;
      if (statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadEmployeeDocs = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.manageEmployee.uploadEmployeeDocs,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services?.status_code?.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*************************Lead Analysis Category Panel**************************** */

export const getLoggedInUserExamList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.targetDashboard.getLoggedInUserExamList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getLoggedInUserExamStreamList =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.targetDashboard.getLoggedInUserExamStreamList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getMonthWiseRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.targetDashboard.monthWiseRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const shopifyRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.targetDashboard.shopifyRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getLeadDashboardAnalysis =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.leadDashboard.getLeadDashboardAnalysis,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          callback([]);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getDailyTargetStudentLeadCount =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.leadDashboard.getDailyTargetStudentLeadCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getWeeklyTargetStudentLeadCount =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.leadDashboard.getWeeklyTargetStudentLeadCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getMonthlyTargetStudentLeadCount =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.leadDashboard.getMonthlyTargetStudentLeadCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
/*************************Exam Mapping*************************/
export const getExmMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.examMapping.getExmMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addExmMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.examMapping.addExmMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteExmMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.examMapping.deleteExmMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*************************department mapping*************************/
export const getDeptMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.departmentMapping.getDeptMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addDeptMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.departmentMapping.addDeptMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteDeptMapping = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.departmentMapping.deleteDeptMapping,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYTAnalytics = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeAnalytics.getYTAnalytics,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYTAnalyticsCategory = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeAnalytics.getYTAnalyticsCategory,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*************************Student Analysis**************************** */
export const getStudentList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.studentAnalysis.getStudentList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getStudentDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.studentAnalysis.getStudentDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
/*************************Company**************************** */

export const addCompany = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Company.addCompany,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCompany = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.Company.updateCompany + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteCompany = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.Company.deleteCompany,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCompanyList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.Company.getCompanyList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*************************Department**************************** */

export const addDepartment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Department.addDepartment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateDepartment = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.Department.updateDepartment + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteDepartment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.Department.deleteDepartment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getDepartmentList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.Department.getDepartmentList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*************************Designation**************************** */

export const addDesignation = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Designation.addDesignation,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateDesignation = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.Designation.updateDesignation + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteDesignation = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.Designation.deleteDesignation,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getDesignationList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.Designation.getDesignationList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// *******************PAYROLL****************

export const getPayrollData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Payroll.getPayrollData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getPayrollCtc = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.Payroll.getPayrollCtc,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ******************************ResultCompilation********************

export const getVerifyStudentNumber =
  (params, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.resultCompilation.getVerifyStudentNumber,
      params,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getCasteCategoryList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.resultCompilation.getCasteCategoryList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*********************SUB DEPARTMENT**********************/
export const addSubDepartment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.subDepartment.addSubDepartment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getSubDepartmentList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.subDepartment.getSubDepartment}/${data}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateSubDepartment = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.subDepartment.updateSubDepartment}/${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteSubDepartment = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.subDepartment.deleteSubDepartment}/${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadResultImage =
  (data, rollNo, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.resultCompilation.uploadResultImage + rollNo,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addUpdateResultCompilation =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.resultCompilation.addUpdateResultCompilation}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(
            showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
          callback(true);
        } else {
          callback(false);
          dispatch(
            showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addResultCompilation = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.resultCompilation.addResultCompilation,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getResultCompilation = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.resultCompilation.getResultCompilation,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const importResultCompilation = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.resultCompilation.importResultCompilation,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateResultCompilation =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.resultCompilation.updateResultCompilation}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(
            showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
          callback(true);
        } else {
          callback(false);
          dispatch(
            showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const uploadStudentImg =
  (data, rollNo, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    let formData = new FormData();
    formData.append("file", data);
    services.postUploadMedia(
      endpoint.resultCompilation.uploadStudentImg + rollNo,
      formData,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addUpgradePackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upgradeGlobal.addUpgradePackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const listUpgradePackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upgradeGlobal.listUpgradePackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateUpgradePackage =
  (data, id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.upgradeGlobal.updateUpgradePackage(id),
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(true);
        } else {
          callback(false);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const deleteUpgradePackage = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.upgradeGlobal.deleteUpgradePackage(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addExtendPackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.extendGlobal.addExtendPackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const listExtendPackage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.extendGlobal.listExtendPackage,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateExtendPackage = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.extendGlobal.updateExtendPackage(id),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteExtendPackage = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    endpoint.extendGlobal.deleteExtendPackage(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

/*********************BDE Target Detils List */
export const getBDETargetDetailsList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.bde.getBDETargetDetailsList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAddUpdateRegisterCount =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.RegisteredLead.getAddUpdateRegisterCount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
/*************************dispatch charges*************************/
export const getDispatchCharges = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.dispatchCharges.getDispatchCharges,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addDispatchCharges = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.dispatchCharges.addDispatchCharges,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateDispatchCharges =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.dispatchCharges.updateDispatchCharges}/${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.status === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const deleteDispatchCharges = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.dispatchCharges.deleteDispatchCharges}/${data}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateTestDiscussionVideo =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      `${endpoint.testDiscussionVideo.updateTestDiscussionVideo}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const getBoardMeetingEdutech = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.boardMeeting.getBoardMeetingEdutech,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getBoardMeetingPublication =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.boardMeeting.getBoardMeetingPublication,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const getBoardMeetingLead = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.boardMeeting.getBoardMeetingLead,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getSupportCount = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.course.getSupportCount,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const targetPerformance = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.cxoPerformance.targetPerformance,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const salesPerformance = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.cxoPerformance.salesPerformance,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const youtubeTargetPerformance =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoPerformance.youtubeTargetPerformance,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const getLeadOrdersSearch = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.getLeadOrdersSearch + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addLeadInCRM = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.crm.addLeadInCRM,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addLeadOrderInCRM = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCallCRM(
    endpoint.crm.addLeadOrderInCRM,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const checkOrderStatus = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.crm.checkOrderStatus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateOrderStatus = (data) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.crm.updateOrderStatus,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getStudyMaterialTransaction =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.course.getStudyMaterialTransaction,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const getWhatsappShootData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.whatsappShootData.getWhatsappShootData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getChannelList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.youtubeTarget.getChannelList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYoutubeTarget = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeTarget.getYoutubeTarget,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getTargetStatusYoutube = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeTarget.getTargetStatusYoutube,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addYoutubeTarget = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeTarget.addYoutubeTarget,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addYoutubeTargetNewDate = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeTarget.addYoutubeTargetNewDate,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateYoutubeTarget = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.youtubeTarget.updateYoutubeTarget}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteYoutubeTarget = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.youtubeTarget.deleteYoutubeTarget}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getReports = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.cxoreports.getResports,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addTransactionEdutechReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionEdutechReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addTransactionEdutechExtendedReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionEdutechExtendedReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addTransactionEdutechUpgradeReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionEdutechUpgradeReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addTransactionStudyMaterialReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionStudyMaterialReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addTransactionEdutechExtendedandUpgradeReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionEdutechExtendedandUpgradeReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addTransactionPublicationReports =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.cxoreports.addTransactionPublicationReports,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addSEOExam = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.seo.addSEOExam,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateInstallment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.configuration.updateInstallment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getInstallment = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.configuration.getInstallment,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getLiveVideoSetting = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.configuration.getLiveVideoSetting,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getTaxSetting = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.configuration.getTaxSetting,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getUpdateAddOn = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.addOnMapping.getUpdateAddOn,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getOrderInvoice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.orderInvoice.getOrderInvoice,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAsset = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.asset.getAsset,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addAsset = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.asset.addAsset,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//employee asset
export const getEmployeeDetails = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.employeeAsset.getEmployeeDetails}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        // dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAssetDetailByBarcode = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.employeeAsset.getAssetDetailByBarcode}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getEmployeeAsset = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.employeeAsset.getEmployeeAsset}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addUpdateEmployeeAsset = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.addUpdateEmployeeAsset,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getEmployeeOtp = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.getEmployeeOtp,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const verifyEmployeeOtp = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.verifyEmployeeOtp,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAllEmployeeAssetList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.getAllEmployeeAssetList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addEmployeeAsset = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.addEmployeeAsset,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateAsset = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.asset.updateAsset}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getEmployeeAssetList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.employeeAsset.getEmployeeAssetList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteEmployeeAsset = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.employeeAsset.deleteEmployeeAsset}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        callback([]);
        dispatch(
          showErrorSnackbar(
            res?.data?.msg ? res?.data?.msg : res?.response?.data?.msg
          )
        );
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getYoutubeLiveLectures = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubePoll.getYoutubeLiveLectures,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addYoutubeQuestionPoll = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubePoll.addYoutubeQuestionPoll,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getQuestionPollsList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubePoll.getQuestionPollsList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateQuestionPollsList =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.youtubePoll.updateQuestionPollsList}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(true);
        } else {
          callback(false);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const deleteYoutubeQuestionPoll = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.youtubePoll.deleteYoutubeQuestionPoll}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
// inventoryManagement
export const uploadInvoice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.inventoryManagement.uploadInvoice,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadDeviceImage = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  let formData = new FormData();
  formData.append("file", data);
  services.postUploadMedia(
    endpoint.inventoryManagement.uploadDeviceImage,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addDevice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.inventoryManagement.addDevice,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const importDevices = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.inventoryManagement.importDevices,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};
export const getDevice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.inventoryManagement.getDevice,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getUpcomingQuestionPollLectures =
  (id, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.getApiCall(
      endpoint.youtubePoll.getUpcomingQuestionPollLectures(id),
      "",
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          callback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        callback([]);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const startYoutubePoll = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.youtubePoll.startYoutubePoll}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getDeviceById = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.inventoryManagement.getDeviceById}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        // callback({});
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      // dispatch(
      //   showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      // );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateDeviceId = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.inventoryManagement.updateDeviceId}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getPDFBBBList =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.pdfBBBList,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
          dispatch(
            showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const uploadPDF =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.teacher.uploadPDF,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
          errorCallback(res);
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

//device company
export const getDeviceCompany = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.deviceCompany.getDeviceCompany,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addDeviceCompany = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.deviceCompany.addDeviceCompany,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateDeviceCompany = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.deviceCompany.updateDeviceCompany}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteDeviceCompany = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.deviceCompany.deleteDeviceCompany}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//device model
export const getDeviceModel = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.deviceModel.getDeviceModel,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addDeviceModel = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.deviceModel.addDeviceModel,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateDeviceModel = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.deviceModel.updateDeviceModel}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.status === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const deleteDeviceModel = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.deviceModel.deleteDeviceModel}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getDeviceSeries = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.deviceSeries.getDeviceSeries,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//influencer
export const addInfluencer = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencer.addInfluencer,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getInfluencer = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencer.getInfluencer,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateInfluencer = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.influencer.updateInfluencer}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteInfluencer = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.influencer.deleteInfluencer}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadAgreement = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.influencer.uploadAgreement,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};
export const getGuidelineAgreement = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencer.getGuidelineAgreement,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAllInfluencer = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencer.getAllInfluencer,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const fetchInfluencerPay = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencer.fetchInfluencerPay,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getInfluencerPayment = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencer.getInfluencerPayment,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateInfluencerPay = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.influencer.updateInfluencerPay,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//Base Course
export const getBaseCourse = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencer.baseCourseList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const activeInfluencer = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencer.activeInfluencer,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCategoryList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencer.getCategoryList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// export const deleteDeviceModel = (id, callback) => async (dispatch) => {
//   dispatch(updateValues("loader", true));
//   services.deleteApiCall(
//     `${endpoint.deviceModel.deleteDeviceModel}${id}`,
//     "",
//     (res) => {
//       dispatch(updateValues("loader", false));
//       if (res?.data?.statusCode === services.status_code.success) {
//         dispatch(
//           showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
//         );
//         callback(true);
//       } else {
//         callback(false);
//         dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
//       }
//     },
//     (error) => {
//       callback(false);
//       dispatch(
//         showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
//       );
//       dispatch(updateValues("loader", false));
//     }
//   );
// };

export const copyCoupon =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.edutechCouponCode.copyCoupon,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          // dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

// competitor analysis
export const getCompetitorList = (callback) => async (dispatch, getState) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.competitorAnalysis.competitorList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addCompetitorVideo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.competitorAnalysis.addCompetitorVideo,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCompetitorVideo = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.competitorAnalysis.getCompetitorVideo,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addCompetitorName = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.competitorAnalysis.addCompetitorName,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateCompetitorVideo =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.competitorAnalysis.updateCompetitorVideo(id),
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addSocialMediaLinks = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.socialMediaAds.addSocialMediaLinks,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSocailMediaLinkList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.socialMediaAds.getSocailMediaLinkList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateSocialMediaLink =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.socialMediaAds.updateSocialMediaLink + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addBaseOfferDiscount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.baseOfferDiscount.addBaseOfferDiscount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          if (res?.msgCode === "740") {
            dispatch(showErrorSnackbar(res?.msg));
          } else {
            dispatch(showSuccessSnackbar(res?.msg));
            callback(res?.data?.discPer);
          }
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        // errorCallback(error);
      }
    );
  };

export const getBaseOfferDiscount =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.baseOfferDiscount.getBaseOfferDiscount,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        // errorCallback(error);
      }
    );
  };

export const updateBaseOfferDiscount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.baseOfferDiscount.updateBaseOfferDiscount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback();
      } else {
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getSubCategory = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.subCategory.getSubCategory + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//facebook group section
export const addFacebookGroup = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.facebookGroup.addFacebookGroup,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getFacebookGroup = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.facebookGroup.getFacebookGroup,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const fetchFacebookGroup = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.facebookGroup.fetchFacebookGroup,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateFacebookGroup = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.facebookGroup.updateFacebookGroup + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const approveFacebookGroup =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.facebookGroup.approveFacebookGroup + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(true);
        } else {
          callback(false);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const deleteFacebookGroup = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.facebookGroup.deleteFacebookGroup}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//dummy account section
export const getDummyAccData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.dummyAccount.getDummyAccData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addDummyAccData = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.dummyAccount.addDummyAccData,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateDummyAccData = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.dummyAccount.updateDummyAccData}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteDummyAccData = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.dummyAccount.deleteDummyAccData}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getCourseDetail = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.branch.getCourseDetail,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addUpcomingBatch = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upcomingBatch.addUpcomingBatch,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const searchBatch = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upcomingBatch.dateSearchBatch,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getUpcomingBatches = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.upcomingBatch.getUpcomingBatches + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const listUpcomingBatches = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upcomingBatch.listUpcomingBatches,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    }
  );
};

export const updateUpcomingbatches =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.upcomingBatch.updateBatches + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(true);
        } else {
          callback(false);
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const uploadConfigCouponFile = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.config_coupon.uploadConfigCouponFile,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};

export const importQuestionBank = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.importQuestions.importQuestionBank,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    }
  );
};

export const showBatchaAnalisis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.upcomingBatch.BatchaAnalisis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const addBasePrice = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.basePrice.addBasePrice,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBasePriceList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.basePrice.basePriceList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        if (res?.msgCode === "533") {
          dispatch(showErrorSnackbar(res?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getBasePrice = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.basePrice.getBasePrice(id),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getAllEmployeeList = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.employeeList.getAllEmployeeList + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateBasePrice = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.basePrice.updateBasePrice(`${id}`),
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res);
      } else {
        if (res?.code) {
          dispatch(showErrorSnackbar(res?.response?.data?.msg));
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getIncentiveRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.incentiveRevenue.getIncentiveRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//fixed deposit section
export const addFixedDiscount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.fixedDiscount.addFixedDiscount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback(false);
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getFixedDiscount = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.fixedDiscount.getFixedDiscount,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getFixedDiscountId = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.fixedDiscount.getFixedDiscountId + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data?.data);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateFixedDiscount = (data, id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    endpoint.fixedDiscount.updateFixedDiscount + id,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//influencer revenue
export const getInfluencerRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencerRevenue.getInfluencerRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//influencer rank
export const getInfluencerRank = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencerRank.getInfluencerRank,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//dashboard influencer
export const getDashboardData = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.influencerDashboard.getDashboardData,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//exam wise revenue influencer
export const influencerExamRevenue = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.influencerExamWise.influencerExamRevenue,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getActiveUser = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.admissionAnalysis.getActiveUser,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//kra kpi
export const addUpdateKraKpi = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.kraKpi.addUpdateKraKpi,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getKraKpi = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.kraKpi.getKraKpi,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getUserKraKpi = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.kraKpi.getUserKraKpi,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
//policy api
export const getUserPolicy = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.kraKpi.getUserPolicy,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAddUpdateUserPolicy = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.kraKpi.getAddUpdateUserPolicy,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data?.policyDetails);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadPolicyMedia = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.kraKpi.uploadPolicyMedia,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};

//Movements of Meeting

export const fetchDepartmentEmployees =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.MovementsOfMeeting.fetchDepartmentEmployees,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const addMeetingDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.MovementsOfMeeting.addMeetingDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getMeetingDetails = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.MovementsOfMeeting.getMeetingDetails,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const updateMeetingDetails =
  (data, id, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      endpoint.MovementsOfMeeting.updateMeetingDetails + id,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        // errorCallback(error);
      }
    );
  };
// aboutus content
export const aboutCompanyContent = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.aboutUs.aboutCompanyContent,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getAboutCompanyContent = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.aboutUs.getAboutCompanyContent,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ------------------ Youtube Seo Tracking ----------------

export const getLectureWiseList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.youtubeSeoTracking.getLectureWiseList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getLectureWiseListById = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.youtubeSeoTracking.getLectureWiseListById + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback("");
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateLectureWiseList =
  (id, data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.patchApiCall(
      `${endpoint.youtubeSeoTracking.updateLectureWiseList}${id}`,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.data?.statusCode === services.status_code.success) {
          dispatch(
            showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
          callback(true);
        } else {
          callback(false);
          dispatch(
            showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
          );
        }
      },
      (error) => {
        callback(false);
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };
export const uploadLectureWiseImg = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.youtubeSeoTracking.uploadLectureWiseImg,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};
//------------ CONTACT US ---------------
export const addContactUs = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.contactUs.addContactUs,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data?.contactData);
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback("");
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getContactUs = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.contactUs.getContactUs,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadContactImg = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.contactUs.uploadContactImg,
    formData,
    (res) => {
      console.log("upload contact img", res);
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};

//------------------ SELLING SOURCE ------------

export const addSellingSource = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.sellingSource.addSellingSource,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getSellingSource = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.sellingSource.getSellingSource,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        // dispatch(
        //   showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        // );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getSellingSourceById = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.sellingSource.getSellingSourceById + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback("");
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateSellingSource = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.sellingSource.updateSellingSource}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteSellingSource = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.sellingSource.deleteSellingSource}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//------------------ VENDOR ------------

export const addVender = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationvendor.addVender,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getVenderList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.publicationvendor.getVenderList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getVenderListById = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.publicationvendor.getVenderListById + id,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback("");
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateVendor = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.publicationvendor.updateVendor}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteVendor = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.publicationvendor.deleteVendor}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//------------------ TDS ------------

export const addTds = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.tds.addTds,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getTds = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.tds.getTds,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateTds = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.patchApiCall(
    `${endpoint.tds.updateTds}${id}`,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteTds = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.tds.deleteTds}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCounsellorRevenueDetails =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.finance.getCounsellorRevenueDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getCounsellorReferenceLeadDetails =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.finance.getCounsellorReferenceLeadDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

export const getRoleList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.finance.roleList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCounsellorListCRM = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCallCRM(
    endpoint.finance.getCounsellorList(data),
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getIncentiveTargetDetails =
  (data, callback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCallCRM(
      endpoint.finance.getIncentiveTargetDetails,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res?.data);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
      }
    );
  };

// ---------PUBLICATION--------
export const scanBarCodeStock = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    `${endpoint.publicationStock.scanBarCodeStock}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updatePublicationStock = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationStock.updatePublicationStock,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const uploadStockMedia = (data, callback) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", data);
  dispatch(updateValues("loader", true));
  services.postUploadMedia(
    endpoint.publicationStock.uploadStockMedia,
    formData,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        callback(res.data);
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
      } else {
        callback("");
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    }
  );
};

export const getDamagedBook = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationStock.getDamagedBook,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getPubStockHistoryList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationStock.getPubStockHistoryList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getStockAnalysis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationStock.getStockAnalysis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showSuccessSnackbar(res?.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

// ---------MY ASSETS--------

export const getMyAssetsList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.employeeAsset.getMyAssetsList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.data?.msg));
        callback(res?.data?.data);
      } else {
        callback({});
        dispatch(showErrorSnackbar(res?.msg ? res?.msg : res?.data?.msg));
      }
    },
    (error) => {
      callback({});
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const addurl = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.oldUrlRedirection.addurl,
    data,
    (res) => {
      console.log("the response", res);
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const getOldUrlList = (callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.getApiCall(
    endpoint.oldUrlRedirection.getOldUrlList,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(res?.data?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const uploadOldUrlList = (id, data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  // console.log("idssss", id);
  // console.log("data", data);
  services.patchApiCall(
    endpoint.oldUrlRedirection.uploadOldUrlList + id,
    data,
    (res) => {
      console.log("respppppp", res);
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res);
      } else {
        dispatch(showErrorSnackbar(res?.data.msg));
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const searchOldUrl = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.oldUrlRedirection.searchOldUrl,
    data,
    (res) => {
      console.log("the response", res);
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const deleteOldUrl = (id, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.deleteApiCall(
    `${endpoint.oldUrlRedirection.deleteOldUrl}${id}`,
    "",
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.data?.statusCode === services.status_code.success) {
        dispatch(
          showSuccessSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg)
        );
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.data?.msg ? res?.data?.msg : res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

//---------------------- Onboarding -------------------------
export const getOnboardingList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.onboardingForm.getOnboardingList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        callback([]);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback([]);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
export const updateOnBoardingList = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.onboardingForm.updateOnBoardingList,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(true);
      } else {
        callback(false);
        dispatch(showErrorSnackbar(res?.msg));
      }
    },
    (error) => {
      callback(false);
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};

export const getCXOPubBookHistory =
  (data, callback, errorCallback) => async (dispatch) => {
    dispatch(updateValues("loader", true));
    services.postApiCall(
      endpoint.publicationStock.getCXOPubBookHistory,
      data,
      (res) => {
        dispatch(updateValues("loader", false));
        if (res?.statusCode === services.status_code.success) {
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error) => {
        dispatch(
          showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
        );
        dispatch(updateValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const CxoPubSourceAnalysis = (data, callback) => async (dispatch) => {
  dispatch(updateValues("loader", true));
  services.postApiCall(
    endpoint.publicationStock.CxoPubSourceAnalysis,
    data,
    (res) => {
      dispatch(updateValues("loader", false));
      if (res?.statusCode === services.status_code.success) {
        dispatch(showSuccessSnackbar(res?.msg));
        callback(res?.data);
      } else {
        dispatch(showErrorSnackbar(res?.msg));
        callback([]);
      }
    },
    (error) => {
      dispatch(
        showErrorSnackbar(error?.data?.msg ? error?.data?.msg : error?.msg)
      );
      dispatch(updateValues("loader", false));
    }
  );
};
