import { length_Three } from "./validators";

export const containsSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg =
  "Special Characters are not allowed and cannot start with a number and White Space";
export const containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg =
  "Cannot start with Special Characters, a number and White Space";
export const containsSpecialCharsOrNumberOrWhiteSpaceErrMsg =
  "Number, White Space and Special Characters are not allowed!";
export const validateIsFileImageTypeErrMsg =
  "File should be type of Image only!";
export const validateIsFileVideoTypeErrMsg =
  "File should be type of Video only!";
export const validateIsFilePDFTypeErrMsg = "File should be type of PDF only!";
export const validateIsFilePDFImageTypeErrMsg =
  "File should be type of PDF and Image only!";
export const validateIsFileExcelTypeErrMsg =
  "File should be type of EXCEL only!";
export const validateIsUrlPatternErrMsg = "Invalid URL pattern!";
export const validateisValidPriceErrMsg = "Invalid Price!";
export const isValidDiscountErrMsg = "Only numbers are allowed in discount.";
export const validateFullNameErrMsg = "Multiple Space not allowed";
export const validateLoginIdErrMsg = "Please enter valid login ID";
export const validatePasswordErrMsg = "Please enter valid password";
export const validatePasswordLengthErrMsg =
  "Password length should be between 8-16.";
export const validateEmailAddressErrMsg = "Please enter valid email address";
export const validateCouponCodeNameErrMsg = "Please start with characters";
export const validateIsNumberOnlyErrMsg = "Please enter number only";
export const noFileUploadedErrMsg = "Please upload a file";
export const containsAllowedSpecialCharsOrNumberOrWhiteSpaceMsg =
  "Only Number char and & , : are allowed";
export const validateEmptyStringErrMsg = "Empty String not allowed";
export const containsNumberErrMsg = "Numbers not allowed";
export const mandatoryFieldsErrMsg = "Please Select Mandatory Fields";
export const multipleSpace = "Multiple space are not allowed";
export const discountValue = "Discount value should be less then 100.";
export const validEndDate = "End date should be greater then start date.";
export const validateExamNameErrMsg = "Please enter valid exam name";
export const validateSubExamNameErrMsg = "Please enter valid sub exam name";
export const validateLanguageNameErrMsg = "Please enter valid language name.";
export const spaceNotAllowed = "Spaces are not allowed.";
export const validateTenDigitNumberOnlyErrMsg = "Please enter 10 digit number.";
export const isValidNumberErrMsg = "Only numbers are allowed.";
export const validateIsFileDocsTypeErrMsg =
  "File should be type of Docx only!";

export const lengthGreaterThanThreeNotAllowedErrMsg = `Length greater than ${
  length_Three - 1
} not allowed`;
