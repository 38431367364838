import React, { useEffect, useState } from "react";
import LabelField from "../../Component/labelField";
import TimerClock from "../../Component/timerClock";
import { defaultDurationTime } from "../../Utils/dataConstants";
import { io } from "socket.io-client";
import {
  getUpcomingQuestionPollLectures,
  startYoutubePoll,
} from "../../Action/courseAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

export default function StartQuestionPoll() {
  const { lectureId } = useSelector((state) => ({
    lectureId: state?.Auth?.lectureId,
  }));
  const dispatch = useDispatch();
  const socket = io.connect("https://stagingbackend.ifasonline.com", {
    secure: true,
    query: {
      room: lectureId,
    },
    withCredentials: true,
    transports: ["websocket"],
  });
  socket.on("connect", () => {
    console.log("Connected to WebSocket server");
  });

  socket.on("connect_error", (error) => {
    console.error("Connection Error:", error);
  });

  socket.on("disconnect", (reason) => {
    console.log("Disconnected:", reason);
  });

  socket.on("error", (error) => {
    console.error("Error:", error);
  });
  const [youtubePollData, setYoutubePollData] = useState([]);
  const [lectureDuration, setLectureDuration] = useState(defaultDurationTime);

  useEffect(() => {
    getUpcomingPoll();
  }, []);

  const handleDurationChange = (id, value) => {
    setLectureDuration((prevDurations) => ({
      ...prevDurations,
      [id]: value,
    }));
  };
  const socketCall = (pollData) => {
    socket.emit("message", {
      text: [
        {
          text: pollData,
          user: {
            _id: "66c860446023e6ee85747a03",
            name: "firstName" + " " + "lastName",
          },
          _id: "66c860446023e6ee85747a03",
        },
      ],
      name: "firstName" + " " + "lastName",
      userId: "lectureId",
      id: "66c860446023e6ee85747a03",
      socketID: socket.id,
    });
  };
  // const socketCall = (pollData) => {
  //   socket.emit('message', {
  //     text: [{ text: "message", user: { _id: "66c860446023e6ee85747a03", name: "firstName" + ' ' + "lastName" }, _id: "66c860446023e6ee85747a03" }],
  //     name: "firstName" + ' ' + "lastName",
  //     userId: "lectureId",
  //     id: "66c860446023e6ee85747a03",
  //     socketID: socket.id,
  //     // pollData:pollData
  //   });
  // }
  const handleStartPoll = (item) => {
    let arr = [],
      minutes = 0;

    arr.push(lectureDuration[item?._id]?.split(":"));
    if (arr[0] === undefined) {
      minutes = 1;
    } else {
      minutes = arr[0][0] * 60 + +arr[0][1] + arr[0][2] / 60;
    }

    dispatch(
      startYoutubePoll(
        item?._id,
        { duration: minutes > 0 ? minutes : 1 },
        (resp) => {
          if (resp) getUpcomingPoll(item);
        }
      )
    );
  };

  const getUpcomingPoll = (item) => {
    dispatch(
      getUpcomingQuestionPollLectures(`${lectureId}`, (resp) => {
        if (item != undefined) {
          const filteredData = resp.filter(
            (respItem, index) => respItem?._id === item?._id
          );
          socketCall(filteredData);
        }
        if (resp?.length > 0) setYoutubePollData(resp);
        else setYoutubePollData([]);
      })
    );
  };

  return (
    <div className="w-100">
      <div className="card-head bg-primary text-white px-4 pt-2">
        <LabelField labelName="Question Polls" />
      </div>
      <div className="card-body">
        <div>
          {youtubePollData?.map((item, index) => {
            return (
              <div style={{ width: "100%" }} className="d-flex w-100">
                <div style={{ width: "65%" }} className="align-items-center">
                  <p className="fw-bold">
                    {ReactHtmlParser(item?.en?.title[0]?.description)}
                  </p>
                  <p>Options</p>
                  {item?.en?.options?.map((i) => {
                    return (
                      <ul>
                        <li
                          style={{
                            color: i?.isCorrect ? "green" : "black",
                            fontWeight: i?.isCorrect ? "bolder" : "normal",
                            fontSize: 16,
                            lineHeight: 1,
                          }}
                        >
                          {ReactHtmlParser(i?.description)}
                        </li>
                      </ul>
                    );
                  })}
                </div>
                {item?.pollStatus !== 1 && item?.pollStatus !== 2 && (
                  <div style={{ width: "25%" }}>
                    <TimerClock
                      labelName={""}
                      value={lectureDuration[item?._id] || defaultDurationTime}
                      placeholder={"Lecture Duration (Min)"}
                      setInputText={(value) =>
                        handleDurationChange(item?._id, value)
                      }
                    />
                  </div>
                )}
                <div
                  style={{ width: "10%" }}
                  className="justify-content-center align-items-center"
                >
                  <button
                    onClick={() => handleStartPoll(item)}
                    className={`btn ${
                      item?.pollStatus === 1 || item?.pollStatus === 2
                        ? "btn-secondary"
                        : "btn-primary"
                    }`}
                    disabled={
                      item?.pollStatus === 1 || item?.pollStatus === 2
                        ? true
                        : false
                    }
                  >
                    {item?.pollStatus === 1
                      ? "Started"
                      : item?.pollStatus === 2
                      ? "Completed"
                      : "Start Poll"}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
