import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { ResultPanelSidebarData } from "../../Module/resultPanelSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";

const StudentResult = lazy(() => import("../CategoryPanel/studentResult"));
const ResultCompilation = lazy(() =>
  import("../CategoryPanel/resultCompilation")
);
const ImportResult = lazy(() => import("./importResult"));

const ResultPanelDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={ResultPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<ResultCompilation />} />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
              <Route path="importResult" element={<ImportResult />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};
export default ResultPanelDashboard;
