import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import moment from "moment";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import DatePicker from "../../Component/datePicker";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2"; 
ChartJS.register(
  CategoryScale,
  LinearScale,  
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const dispatch = useDispatch();
 

const branch=[
    {
        _id:1,
        name:"All"
    },
    {
        _id:2,
        name:"Edutech"
    },
    {
        _id:3,
        name:"Publication"
    },

]
 

  return (
    <div className="contentBox">
       
      <div className="formDiv ">
        <div className="col-12 formWidth">
         <div className="row d-flex justify-content-between col-md-5 px-2 py-3">
         {branch?.map((c , index)=>
         {return(<button type="button" class="btn btn-light shadow col-md-3 mx-4 fw-bold">{c?.name}</button>)})}

         </div>
         <div className="row d-flex justify-content-between pt-3 py-3 mx-3">
            <div className="col-md-3">
                <h4 className="fw-bold text-secondary">NEW REPORT</h4>
                <p className="fw-bold mb-0">Today's revenue</p>
                <div className="d-flex align-items-center">
                    <h3 className="fw-bold me-2">&#8377; 5,00,250</h3>
                    <span className="text-light rounded-pill bagdeColor col-md-2 fw-bold text-center my-4 me-2">
                    &#11014; 5%
                    </span>
                    <span className="text-light rounded-pill bagdeColor col-md-2 fw-bold text-center my-4 ">
                    &#11014; 2000
                    </span>

                </div>
                <div className="d-flex align-items-center">
                    
                <p className="fw-bold mb-0 me-3">Today's revenue</p>
                   <h3 className="fw-bold me-2">&#8377; 4,00,150</h3>

                </div>

            </div>
            <div className="col-md-9 d-flex row justify-content-between">
                <div className="col-md-2 shadow totalOrderDiv p-2 pt-4 text-center rounded">
                    <h5 className="fw-bold">Total Order</h5>
                    <div className="badge rounded-pill bg-light text-dark m-4 fs-5 col-md-6">50 </div>
                     <div className="d-flex justify-content-between px-3">
                       <p className="fs-5"> <span className="divtext fw-bold py-5">&#11014;</span>2%</p>
                       <p className="fs-5">40</p>
                     </div>

                </div>
                <div className="col-md-2 shadow totalLeadDiv p-2 pt-4 text-center rounded">
                    <h5 className="fw-bold">Total Leads</h5>
                    <div className="badge rounded-pill bg-light text-dark m-4 fs-5 col-md-6">5000</div>
                     <div className="d-flex justify-content-between px-3">
                       <p className="fs-5"> <span className="divtext fw-bold py-5">&#11014;</span>5%</p>
                       <p className="fs-5">40</p>
                     </div>

                </div>
                <div className="col-md-2 shadow phdDiv p-2 pt-4 text-center rounded">
                    <h5 className="fw-bold">PHD</h5>
                    <div className="badge rounded-pill bg-light text-dark m-4 fs-5 col-md-6">50,000</div>
                     <div className="d-flex justify-content-between px-3">
                       <p className="fs-5"> <span className="divtext fw-bold py-5">&#11014;</span>5%</p>
                       <p className="fs-5">4000</p>
                     </div>

                </div>
                <div className="col-md-2 shadow mscDiv p-2 pt-4 text-center rounded">
                    <h5 className="fw-bold">MSC</h5>
                    <div className="badge rounded-pill bg-light text-dark m-4 fs-5 col-md-6">60,000</div>
                     <div className="d-flex justify-content-between px-3">
                       <p className="fs-5"> <span className="divtext fw-bold py-5">&#11015;</span>5%</p>
                       <p className="fs-5">4000</p>
                     </div>

                </div>
                <div className="col-md-2 shadow ugcDiv p-2 pt-4 text-center rounded">
                    <h5 className="fw-bold">UGC</h5>
                    <div className="badge rounded-pill bg-light text-dark m-4 fs-5 col-md-6">67,000</div>
                     <div className="d-flex justify-content-between px-3">
                       <p className="fs-5"> <span className="divtext fw-bold py-5">&#11015;</span>5%</p>
                       <p className="fs-5">4000</p>
                     </div>

                </div>
                </div>
     
         </div>
         <div className="d-flex justify-content-between py-3 mx-3">
          <div className="d-flex justify-content-between">
            <div className="col-md-6">
      
            </div>
            <div className="col-md-6">

            </div>
          </div>
            <div className="col-md-6 p-2">
             <h5 className="fw-bold">Revenue Summary</h5>
              <div className="shadow rounded p-3">
               <h6 className="fw-bold p-2">Top 5 Sales</h6>
               <p>Rajasthan</p>
               <p>Maharashtra</p>
               <p>Gujrat</p>
               <p>Andhra Pradesh</p>
               <p>Goa</p>
              </div>
            </div>
            <div className="col-md-6 p-2">
            <h5 className="fw-bold">Lead Summary</h5>
            <div className="shadow rounded p-3">
               <h6 className="fw-bold p-2">Top 5 Sales</h6>
               <p>Rajasthan</p>
               <p>Maharashtra</p>
               <p>Gujrat</p>
               <p>Andhra Pradesh</p>
               <p>Goa</p>
              </div>
            </div>

         </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
