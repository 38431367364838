export const InfraManagerPanelSidebarData = [
  {
    title: "ASSET TYPE",
    subNav: [
      {
        title: "Create",
        path: "addAsset",
        cName: "sub-nav",
      },
      {
        title: "Edit/Delete",
        path: "getAsset",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DEVICE COMPANY",
    subNav: [
      {
        title: "Create",
        path: "createDeviceCompany",
        cName: "sub-nav",
      },
      {
        title: "Edit/Delete",
        path: "editDeviceCompany",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "DEVICE MODEL",
    subNav: [
      {
        title: "Create",
        path: "createDeviceModel",
        cName: "sub-nav",
      },
      {
        title: "Edit/Delete",
        path: "editDeviceModel",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INVENTORY MANAGEMENT",
    subNav: [
      {
        title: "Create",
        path: "addInventory",
        cName: "sub-nav",
      },
      {
        title: "Edit/Delete",
        path: "editInventory",
        cName: "sub-nav",
      },
    ],
  },
];
