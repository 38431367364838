import React, { useState, useEffect } from "react";
import PageTitle from "../../Module/pageTitle";
import DatePicker from "../../Component/datePicker";
import AdminTable from "../../Component/adminTable";
import LabelField from "../../Component/labelField";
import { useDispatch } from "react-redux";
import { showBatchaAnalisis } from "../../Action/courseAction";
import SubmitButton from "../../Component/SubmitButton";

function BatchaAnalisis() {
  const dispatch = useDispatch();
  const [startDate, SetStartDate] = useState("");
  const [endDate, SetEndDate] = useState("");
  const [getBatchList, setGetBatchList] = useState([]);
  const handelSearch = () => {
    const dateSearch = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(
      showBatchaAnalisis(dateSearch, (res) => {
        console.log(" res", res);
        if (res) {
          console.log("datassss", res.data);
          setGetBatchList(res?.data);
        }
      })
    );
  };
  return (
    <div>
      <PageTitle name={"UPCOMING BATCH ANALYSIS"} />
      <div className="container">
        <div className="formDiv contentScrollbar">
          <div className="row formWidth">
            <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
              <div className="d-flex align-items-center  ">
                <LabelField labelName={"Start Date :"} isRequired={true} />
                <DatePicker
                  setInputText={(value) => {
                    SetStartDate(value);
                  }}
                  value={startDate}
                />
              </div>
            </div>
            <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
              <div className="d-flex  align-items-center  ">
                <LabelField labelName={"End Date :"} isRequired={true} />
                <DatePicker
                  setInputText={(value) => {
                    SetEndDate(value);
                  }}
                  value={endDate}
                />
              </div>
            </div>
            <div className="col-2 col-sm-2 col-lg-2 col-xl-2">
              <SubmitButton
                type="button"
                name="Search"
                onClick={handelSearch}
              />
            </div>
          </div>
          <AdminTable
            tableData={getBatchList ? getBatchList : []}
            pagename={"BatchaAnalisis"}
            tableHead={[
              "S.N",
              "Exam Name",
              "Exam Stream",
              "Batch Name",
              "Batch Date",
              "Target",
              "Deficit",
              "Percentage Achieved",
              "",
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default BatchaAnalisis;
