/* eslint-disable react/prop-types */
import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function DeletePopup(props) {
  const { open, handleClose, data, handleSubmit } = props;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldivDelete p-3">
            <div className="col mb-4 d-flex justify-content-end ">
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className="form-check">
              <div className="text-center">
                <span
                  className="h-5 w-5 rounded-circle d-inline-block text-white"
                  style={{
                    background: "red",
                  }}
                >
                  <DeleteForeverIcon />
                </span>
                <h4 className="fw-bold">Are you sure?</h4>
                <div>
                  <p className="fs-5">
                    {data
                      ? ` You want to delete this " ${data} " ?`
                      : ` You want to delete this ?`}
                  </p>
                </div>
              </div>

              <div className="col-auto d-flex justify-content-center mt-3">
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="submit"
                  className="btn btn-primary mb-3 submitbtn me-3"
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  type="submit"
                  className="btn btn-danger mb-3 submitbtn"
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
