/* eslint-disable react/prop-types */
import React from "react";
import LabelField from "./labelField";
import uploadImage from "./../Images/upload.png";
import uploadPDF from "./../Images/file-upload-blank.png";
import uploadedPDF from "./../Images/file-upload.png";
import uploadExcel from "./../Images/file.png";
import uploadedExcel from "./../Images/file-upload-xls.png";
import uploadVideo from "../Images/upload-video.png";
import Edit from "../Images/edit-grey.png";
import docsUpload from "./../Images/docsUpload.png";
import docsWithoutUpload from "./../Images/docsWithoutUpload.png";

function ImagePickerAndViewer({
  labelName,
  value,
  handleChange,
  htmlFor,
  removelabel,
  doctype,
  isRequired,
  disabled,
}) {
  const renderPDF = () => {
    if (value)
      return (
        <img
          htmlFor={htmlFor}
          src={uploadedPDF}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    else
      return (
        <img
          htmlFor={htmlFor}
          src={uploadPDF}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
  };

  const renderExcel = () => {
    if (value)
      return (
        <img
          htmlFor={htmlFor}
          src={uploadedExcel}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    return (
      <img
        htmlFor={htmlFor}
        src={uploadExcel}
        style={{
          width: "50px",
          height: "50px",
        }}
        alt={""}
      />
    );
  };

  const renderVideo = () => {
    if (value && value !== " ") return <div>{value}</div>;
    return (
      <img
        htmlFor={htmlFor}
        src={uploadVideo}
        style={{
          width: "50px",
          height: "50px",
        }}
        alt={""}
      />
    );
  };
  const renderImage = () => {
    if (value && value !== " ")
      return (
        <img
          htmlFor={htmlFor}
          src={value}
          style={{
            height: "50px",
            maxWidth: "100%",
          }}
          alt={""}
        />
      );
    return (
      <img
        htmlFor={htmlFor}
        src={uploadImage}
        style={{
          height: "50px",
          maxWidth: "100%",
        }}
        alt={""}
      />
    );
  };

  const renderDocs = () => {
    if (value && value !== " ") {
      return (
        <img
          src={docsUpload}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    } else {
      return (
        <img
          src={docsWithoutUpload}
          style={{
            width: "50px",
            height: "50px",
          }}
          alt={""}
        />
      );
    }
  };

  const renderMediaInput = () => {
    if (doctype === "pdf") return renderPDF();
    else if (doctype === "video") return renderVideo();
    else if (!doctype) return renderImage();
    else if (doctype === "excel") return renderExcel();
    else if (doctype === "pdf/image") return renderPDF();
    else if (doctype === "docx") return renderDocs();

    <img
      src={Edit}
      className="cursor"
      alt="Edit"
      style={{
        width: "13px",
        position: "absolute",
        right: "0",
        bottom: "0px",
      }}
    />;
  };

  return (
    <div className="d-flex mb-2 align-items-center">
      {removelabel ? null : (
        <LabelField labelName={labelName} isRequired={isRequired} />
      )}

      <label htmlFor={htmlFor} className="custom-file-upload fas cursor ">
        {renderMediaInput()}
        <input
          id={htmlFor}
          style={{ display: "none" }}
          type="file"
          disabled={disabled}
          onChange={(e) => handleChange(e)}
          accept={
            doctype === "pdf"
              ? ".pdf"
              : doctype === "video"
              ? "video/mp4"
              : doctype === "excel"
              ? ".xls,.xlsx"
              : doctype === "docx"
              ? ".docx, doc"
              : doctype === "pdf/image"
              ? "image/png, image/gif, image/jpeg, ,image/webp, .pdf"
              : "image/png, image/gif, image/jpeg,image/webp"
          }
        />
      </label>
    </div>
  );
}

export default ImagePickerAndViewer;
