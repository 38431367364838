import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CustomDropdown from "../../Component/customDropdown";
import DatePicker from "../../Component/datePicker";
import usePagination from "../../Component/usePagination";
import PaginationView from "../../Component/paginationView";
import PaginationTable from "../../Component/paginationTable";
import { Pagination } from "@mui/material";
import { getTableDataSlots } from "../../Utils/dataConstants";
import {
  getExamListNew,
  getParentCourseNew,
  getSocailMediaLinkList,
  getSocialMediaAd,
  setParentCourse,
  updateSocialMediaLink
} from "../../Action/courseAction";

import { getSubExam } from "../../Action/adminPanelAction";
import InputField from "../../Component/inputField";
const EditDeleteSocialMediaLink = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [list, setList] = useState([]);
  //states
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).local().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).local().format("YYYY-MM-DD")
  );
  //state names
  const [examName, setExamName] = useState("");
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [sourceName, setSourceName] = useState("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [parentCourseList, setParentCourseList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [subExamName, setSubExamName] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [faceBookGroupLink, setFaceBookGroupLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [youTubeLink, setYouTubeLinks] = useState("");
  const [telegramLink, setTelegramLinks] = useState("");
  const [telegramGroupLink, setTelegramGroupLinks] = useState("");
  const [socialMediaLinkID , setSocialMediaLinkID] = useState("");
  //state lists
  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  const onListChange = (value) => {
    setExamName(value);
    setResetExam(false);
    setparentCourseVal("");
    setParentCourseList([]);
    setResetParentCourse(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value?._id,
        },
        (res) => {
          if (res.data.length !== 0) {
            setSubExamList(res.data); 
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };
  const fetchData = () => {
    setList([]);
    dispatch(
      getSocailMediaLinkList(
        "",
        (res) => {
          setList(res?.data);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "ALL") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page, rowsPerPage, flag) => {
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
    } else if (flag === 2) {
      data = {
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: rowsPerPage,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
    } else {
      data = {
        startDate: startDate,
        endDate: endDate,
        skip: 0,
        limit: count,
      };
      if (examName) data.examTypeId = examName?._id;
      if (parentCourseVal) data.baseCourseId = parentCourseVal?._id;
    }
    dispatch(
      getSocailMediaLinkList(reqBody, (resp) => {
        setCount(resp?.data?.count);
        setList(resp?.data);
      })
    );
  };
  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
  };

  const onSubExamChange = (value) => {
    setSubExamName(value);
    setParentCourseList([]);

    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };
  const onFacebookLinks=(val)=>{
    setFacebookLink(val)
  }

  const onFaceBookGroupLink=(val)=>{
    setFaceBookGroupLink(val)
  }
  const onChangeInstagramLinks=(val)=>{
    setInstagramLink(val)
  }
const onChangeYouTubeLinks=(val)=>{
    setYouTubeLinks(val)
}
const onChangeTelegramLinks=(val)=>{
    setTelegramLinks(val)
}
const onChangeTelegramGroupLinks=(val)=>{
  setTelegramGroupLinks(val)
}
  const handleUpdate = (e) => {
    e.preventDefault();
    const reqBody={
         fb_page:facebookLink,
      fb_group:faceBookGroupLink,
      ig_page:instagramLink,
      yt_channel:youTubeLink,
      tg_channel:telegramLink,
      tg_group:telegramGroupLink,
    }
    dispatch(
      updateSocialMediaLink(socialMediaLinkID, reqBody, (resp) => {
        if (resp) {
          dispatch(setParentCourse("isEditMode", false)); 
          fetchData();
        }
      })
    );
 
  };
  const handleEdit = (value) => {
    console.log("handleEdit", value);
    setparentCourseVal(value?.baseCourseId );
    setExamName(value?.examTypeId);
    setSubExamName(value?.examTypeId);
    setFacebookLink(value?.fb_page);
    setFaceBookGroupLink(value?.fb_group);
    setInstagramLink(value?.ig_page);
    setYouTubeLinks(value?.yt_channel);
    setTelegramLinks(value?.tg_channel);
    setTelegramGroupLinks(value?.tg_group);
    setSocialMediaLinkID(value?._id)
    dispatch(setParentCourse("isEditMode", true));
  };
  console.log("setSocialMediaLinkID",socialMediaLinkID)
  const handleCancelEditMode = () => {
    dispatch(setParentCourse("isEditMode", false));
  };
  const renderEditMode = () => {
    return (
      <form onSubmit={(e) => handleUpdate(e)}>
        <div className="">
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <label className="me-5 pe-5 fw-bold"> Exam Name</label>
            <CustomDropdown
              setInputText={(value) => onListChange(value)}
              value={examName}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
              defaultValue={examName?._id}
              disabled
            />
          </div>
          {subExamList?.length > 0 ? (
            <div className="col-4 d-flex mb-2 align-items-center">
              <label className="me-5 pe-3 fw-bold">Sub Exam Name</label>

              <CustomDropdown
                setInputText={(value) => onSubExamChange(value)}
                options={subExamList}
                labelName="Select Sub Exam Name"
                value={subExamName}
                defaultValue={examName?._id}
                disabled
              />
            </div>
          ) : null}
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <label className="me-5 pe-5 fw-bold"> Exam Stream</label>
            <CustomDropdown
              setInputText={(value) => changeParentCourseVal(value)}
              value={parentCourseVal}
              options={parentCourseList}
              labelName="Select Exam Stream"
              reset={resetParentCourse}
              defaultValue={parentCourseVal?._id}
              disabled
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"Facebook Link"}
              placeholder={"Enter Facebook Link  "}
              setInputText={(value) => onFacebookLinks(value)}
              value={facebookLink}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"Facebook Group Link"}
              placeholder={"Enter Facebook Group Link"}
              setInputText={(value) => onFaceBookGroupLink(value)}
              value={faceBookGroupLink}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"Instagram Link"}
              placeholder={"Enter Instagram Link  "}
              setInputText={(value) => onChangeInstagramLinks(value)}
              value={instagramLink}
            />
          </div>

          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"YouTube Link"}
              placeholder={"Enter YouTube Link  "}
              setInputText={(value) => onChangeYouTubeLinks(value)}
              value={youTubeLink}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"Telegram Link"}
              placeholder={"Enter Telegram Link  "}
              setInputText={(value) => onChangeTelegramLinks(value)}
              value={telegramLink}
            />
          </div>
          <div className="d-flex align-items-center mb-2 col-4 pe-4">
            <InputField
              labelName={"Telegram Group Link"}
              placeholder={"Enter Telegram group Link  "}
              setInputText={(value) => onChangeTelegramGroupLinks(value)}
              value={telegramGroupLink}
            />
          </div>

          <div className="d-flex align-items-center  justify-content-center mb-2 col-6 pe-4">
            <button
              type="submit"
              className={`btn mb-3 submitbtn ${"btn-primary"}`}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  };
  //render list
  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
        
        </div>
      
        <div ref={tableRef}>
          <PaginationTable
            tableData={list ? list : []}
            pagename={"socialLinks"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.NO",
              "EXAM NAME",
              "EXAM STREAM",
              "Facebook Link",
              "Facebook Group Link",
              "Instagram Link",
              "Telegram Channel Link",
              "Telegram Group Link",
              "You Tube Link",
              "Action",
            ]}
            handleEdit={(value) => handleEdit(value)} 
            edit={true}
          />
        </div>
      </>
    );
  };
  //useeffect hooks
  useEffect(() => {
    setSelectedStatus(2);
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
    let reqBody = {
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: 10,
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      examTypeId: examName?._id,
      subExamTypeId: subExamName?._id,
      baseCourseId: parentCourseVal?._id,
    };

    dispatch(
      getSocailMediaLinkList(postData, (resp) => {
        setList(resp?.data);
      })
    );
  };
  console.log("socialMediaLinks", list);
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT DELETE SOCIAL MEDIA LINKS"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />
      <div className="formDiv">
        <div className="col-12 formWidth">
          <form onSubmit={(e) => handleSubmit(e)}>
            {!isEditMode ? (
              <div className="d-flex pt-2 flex-wrap">
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <label className="me-3 fw-bold"> Exam Name</label>
                  <CustomDropdown
                    setInputText={(value) => onListChange(value)}
                    value={examName}
                    options={examList}
                    labelName="Select Exam Name"
                    reset={resetExam}
                    
                  />
                </div>
                {subExamList?.length > 0 ? (
                  <div className="col-4 d-flex mb-2 align-items-center">
                    <label className="me-5 pe-3 fw-bold">Sub Exam Name</label>

                    <CustomDropdown
                      setInputText={(value) => onSubExamChange(value)}
                      options={subExamList}
                      labelName="Select Sub Exam Name"
                      value={subExamName}
                    />
                  </div>
                ) : null}
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <label className="me-3 fw-bold"> Exam Stream</label>
                  <CustomDropdown
                    setInputText={(value) => changeParentCourseVal(value)}
                    value={parentCourseVal}
                    options={parentCourseList}
                    labelName="Select Exam Stream"
                    reset={resetParentCourse}
                  />
                </div>

                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <button
                    type="submit"
                    className={`btn mb-3 submitbtn ${"btn-primary"}`}
                  >
                    Submit
                  </button>
                </div>
                
              </div>
            ) : (
              ""
            )}
          </form>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </div>
  );
};

export default EditDeleteSocialMediaLink;
