/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useState } from "react";
import "../App.css";
import { useDispatch } from "react-redux";
import { changeActiveStatusExamset } from "../Action/courseAction";
import ToggleSwitchBtn from "./toggleSwitchBtn";
import DeletePopup from "./deletePopup";
import ReactHtmcxoDateWiseCountlParser from "react-html-parser";
import ImagePickerAndViewer from "./imagePickerAndViewer";

function PaginationTable({
  tableData,
  tableHead,
  page,
  rowsPerPage,
  handleAddOnButton,
  pagename,
  handleCancelButton,
  handleEndLiveButton,
  handleRescheduleButton,
  edit,
  handleEdit,
  handleDelete,
  isView,
  handleCheck,
  startResolve,
  handleClick,
  handleNavigate,
}) {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const onstatusChanged = (event, examId) => {
    const postData = {
      examId: examId,
      isActive: event?.target?.checked ? event?.target?.checked : false,
    };
    dispatch(
      changeActiveStatusExamset(
        postData,
        () => { },
        () => { }
      )
    );
  };
  const getSeconds = (ttt) => {
    // let seconds = minutes * 60;
    const { minutes, hours, seconds } = moment.duration(ttt, "minute")._data;
    let temp =
      `${hours < 10 ? "0" + hours : hours}` +
      ":" +
      `${minutes < 10 ? "0" + minutes : minutes}` +
      ":" +
      `${seconds < 10 ? "0" + seconds : seconds}`;
    return <td className="col-2">{temp}</td>;
  };
  const changeIntoIst = (date) => {
    date = new Date(date);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date;
  };
  const handlePdf = (item) => { };

  let temp = 0;
  temp = page * rowsPerPage + 1;

  const displayNames = (subCategories) => {
    var categoryArray = subCategories
      .map((item) => {
        return item.name;
      })
      .join(", ");
    return <td className="col-2">{categoryArray}</td>;
  };

  const OnClickHandle = (data) => {
    if (pagename === "leadAssignedStatusOverview") {
      handleClick(data);
    } else if (
      pagename === "questionPollList" ||
      pagename === "questionPollListEdit"
    ) {
      handleClick(data);
    }
  };

  return (
    <div className="container-fluid pt-3 pe-0 ps-0">
      <div className="mt-3">
        <table className="w-100">
          <thead className="font-12">
            <tr>
              {tableHead?.map((data, index) => {
                return <th key={index}>{data}</th>;
              })}
            </tr>
          </thead>
          <tbody className="">
            {tableData?.length > 0 ? (
              tableData?.map((item, index) => {
                temp++;
                return (
                  <tr
                    key={
                      pagename === "liveClassSchedule"
                        ? `${item._id}${index}`
                        : item._id
                    }
                    className={
                      pagename === "leadAssignedStatusOverview" ? "cursor" : ""
                    }
                    onClick={
                      pagename === "leadAssignedStatusOverview"
                        ? () => OnClickHandle(item)
                        : null
                    }
                  >
                    {pagename === "studyMaterialOrders" ? (
                      item?._id ? (
                        <td>
                          <span>
                            <input
                              id={item?._id}
                              className="check"
                              type="checkbox"
                              checked={item.isSelected}
                              onChange={(item) => {
                                handleCheck(item, index);
                              }}
                            />
                          </span>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" ||
                      pagename === "raiseIssue" ||
                      pagename === "technicalSupport" ||
                      pagename === "helpdesk" ||
                      pagename === "courseSupport" ||
                      pagename === "package" ||
                      pagename === "infraSupport" ||
                      pagename === "InfraIssue" ||
                      pagename === "expertSupport" ||
                      pagename === "callExpertSupport" ||
                      pagename === "studendatewistCount" ||
                      pagename === "registeredUserList" ||
                      pagename === "studyMaterialOrders" ||
                      pagename === "assuredRevenue" ||
                      pagename === "bdeList" ||
                      pagename === "leadAssignedStatusOverview" ||
                      pagename === "scholarshipExamResult" ||
                      pagename === "paymentPublication" ||
                      pagename === "oldPackageExist" ||
                      pagename === "createTarget" ||
                      pagename === "leadAnalysisDailyData" ||
                      pagename === "leadAnalysisWeeklyData" ||
                      pagename === "leadAnalysisMonthlyData" ||
                      pagename === "leadAnalysisYearlyData" ||
                      pagename === "showResult" ||
                      pagename === "competitorData" ||
                      pagename === "showResultExport" ||
                      pagename === "youtubeData" ||
                      pagename === "youtubeData" ||
                      pagename === "employeeAssetActive" ? (
                      <td>{temp - 1}.</td>
                    ) : (
                      <td>{index + 1}</td>
                    )}
                    {pagename === "financeManagement"
                      ? item?._id && (
                        <>
                          <td>
                            {item?.invoiceDate
                              ? moment
                                .utc(item?.invoiceDate)
                                .format("DD-MM-YYYY")
                              : "-"}
                          </td>

                          <td>{item?.vendor}</td>

                          <td>{item?.amount}</td>

                          <td>
                            {item?.companyName !== ""
                              ? item?.companyName
                              : "-"}
                          </td>

                          <td>{item?.addedBy ? item?.addedBy : "-"}</td>

                          <td>{item?.status ? item?.status : "-"}</td>
                        </>
                      )
                      : null}
                    {pagename === "employeeAssetActive" ? (
                      <>
                        <td>{item?.companyName ? item?.companyName : "-"}</td>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td>{item?.employeeId ? item?.employeeId : "-"}</td>
                        <td>
                          {item?.departmentName ? item?.departmentName : "-"}
                        </td>
                        <td>{item?.deviceCount ? item?.deviceCount : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "technicalSupport" ||
                      pagename === "courseSupport" ? (
                      item?.issueId ? (
                        <td>{item?.issueId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ||
                      pagename === "courseSupport" ? (
                      item?.createdAt ? (
                        <td>
                          {new Date(item?.createdAt)
                            .toString()
                            .slice(4)
                            .substring(0, 20)}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "liveClassSchedule" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "liveClassSchedule" ? (
                      item?.examTypeId ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "liveClassSchedule" ? (
                      item?.subExamTypeId ? (
                        <td>{item?.subExamTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "raiseIssue" ||
                      pagename === "raiseTeacherIssue" ? (
                      item?.queryNo ? (
                        <td>{item?.queryNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "raiseIssue" || pagename === "InfraIssue" ? (
                      item?.teacherId ? (
                        <td>{item?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "raiseIssue" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "raiseTeacherIssue" ? (
                      item?.issueTypeId ? (
                        <td>{item?.issueTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "raiseTeacherIssue" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "raiseTeacherIssue" ? (
                      item?.issueTypeId ? (
                        <td>{item?.issueTypeId?.price}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "raiseIssue" || pagename === "InfraIssue" ? (
                      item?.issueType ? (
                        <td>{item?.issueType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "raiseIssue" ||
                      pagename === "raiseTeacherIssue" ||
                      pagename === "InfraIssue" ? (
                      item?.issue ? (
                        <td>{item?.issue}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "raiseIssue" ||
                      pagename === "raiseTeacherIssue" ||
                      pagename === "InfraIssue" ? (
                      item?.issueDateAndTime ? (
                        <td>
                          {" "}
                          {moment
                            .utc(item?.issueDateAndTime)
                            .format("DD/MM/YYYY h:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "cxoDateWiseCount" ? (
                      <>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td>{item?.studentCount ? item?.studentCount : "-"}</td>
                        <td>
                          {item?.withoutGstAmount
                            ? (item?.withoutGstAmount).toFixed(2)
                            : item?.withoutGstAmount === 0
                              ? 0
                              : "-"}
                        </td>
                        <td>
                          {item?.totalGstAmount
                            ? (item?.totalGstAmount).toFixed(2)
                            : item?.totalGstAmount === 0
                              ? 0
                              : "-"}
                        </td>
                        <td>
                          {item?.shopifyAmount
                            ? (item?.shopifyAmount).toFixed(2)
                            : item?.shopifyAmount === 0
                              ? 0
                              : "-"}
                        </td>
                        <td>
                          {item?.amount
                            ? (item?.amount).toFixed(2)
                            : item?.amount === 0
                              ? 0
                              : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "technicalSupport" ||
                      pagename === "assuredRevenue" ? (
                      item?.userId ? (
                        <td>
                          {item?.userId?.firstName} {item?.userId?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "questionPollList" ? (
                      <>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td
                          className="cursor text-primary font-weight-bold"
                          onClick={() => OnClickHandle(item)}
                        >
                          Add Poll
                        </td>
                      </>
                    ) : null}
                    {pagename === "questionPollListEdit" ? (
                      <>
                        <td>
                          {item?.en?.title
                            ? ReactHtmlParser(item?.en.title[0]?.description)
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "questionDoubt" ? (
                      <>
                        <td>
                          {item?.examTypeId?._id ? item?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {item?.examTypeId?._id ? item?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {item?.subExamTypeId?._id
                            ? item?.subExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.baseCourseId?._id
                            ? item?.baseCourseId?.name
                            : "-"}
                        </td>
                        <td>{item?.questionNo ? item?.questionNo : "-"}</td>
                        <td>
                          {item?.userId?._id
                            ? `${item?.userId?.firstName} ${item?.userId?.lastName}`
                            : "-"}
                        </td>
                        <td>
                          {item?.createdAt
                            ? moment(changeIntoIst(item?.createdAt)).format(
                              "DD-MM-YYYY"
                            )
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "studentAnalysisData" ? (
                      <>
                        <td>{item?.firstName ? item?.firstName : "-"}</td>
                        <td>{item?.lastName ? item?.lastName : "-"}</td>
                        <td>{item?.examCount ? item?.examCount : "-"}</td>
                        <td>
                          {item?.liveVideoCount === 0
                            ? 0
                            : item?.liveVideoCount
                              ? item?.liveVideoCount
                              : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "duplicateData" ? (
                      <>
                        <td>{item?.examType ? item?.examType?.name : "-"}</td>
                        <td>
                          {item?.baseCourse ? item?.baseCourse?.name : "-"}
                        </td>
                        <td>
                          {item?.taskDate
                            ? moment(item?.taskDate).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                        <td>{item?.link ? item?.link : "-"}</td>
                        <td>{item?.count ? item?.count : "-"}</td>
                        {item?.teachers?.length > 0 ? (
                          <>
                            {item?.teachers?.map((i, index) => (
                              <tr
                                key={index}
                                style={{ backgroundColor: "transparent" }}
                              >
                                <td>{i?.name ? i?.name : "-"}</td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <td>-</td>
                        )}
                        <td>{item?.workDuration ? item?.workDuration : "-"}</td>
                        <td>
                          {item?.youtubeVideoId ? item?.youtubeVideoId : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "bdeList" ? (
                      item?._id ? (
                        <>
                          <td>
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td>{item?.examTypeName}</td>
                          <td>{item?.basecourseName}</td>
                          <td>{item?.reference ? item?.reference : "-"}</td>
                          <td>
                            {item?.qualification ? item?.qualification : "-"}
                          </td>
                          <td>
                            {item?.educationYear ? item?.educationYear : "-"}
                          </td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td>
                            {item?.webinarDate
                              ? moment(item?.webinarDate).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "scholarshipExamResult" ? (
                      item?.userId ? (
                        <>
                          <td>{item?.firstName}</td>
                          <td>{item?.lastName}</td>
                          <td>
                            {item?.registerAt
                              ? moment(item?.registerAt).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td>{item?.markObtain}</td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "paidAds" ? (
                      item?._id ? (
                        <>
                          <td>
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td>{item?.examTypes?.name}</td>
                          <td>{item?.basecourses?.name}</td>
                          <td>{item?.isMobileVerified ? "YES" : "NO"}</td>
                          <td>{item?.mediaName}</td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "youtubeData" ? (
                      item?._id ? (
                        <>
                          <td>{item?.seriesName ? item?.seriesName : "-"}</td>
                          <td>
                            {item?.examTypes ? item?.examTypes?.name : "-"}
                          </td>
                          <td>
                            {item?.subExamTypes
                              ? item?.subExamTypes?.name
                              : "-"}
                          </td>
                          <td>
                            {item?.basecourses ? item?.basecourses?.name : "-"}
                          </td>
                          <td>{item?.subjects ? item?.subjects?.name : "-"}</td>
                          <td>
                            {item?.teachers?.map((i, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>{`${i?.name}`}</td>
                                </tr>
                              );
                            })}
                          </td>
                          {/* <td>{item?.teachers ? item?.teachers?.name : "-"}</td> */}
                          <td>{item?.assignBy ? item?.assignBy : "-"}</td>
                          <td>{item?.company ? item?.company : "-"}</td>
                          <td>{item?.lectureTime ? item?.lectureTime : "-"}</td>
                          <td>
                            {`${moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            )}`}
                          </td>
                          <td>
                            {item?.totalLecture ? item?.totalLecture : "-"}
                          </td>
                          <td>{item?.type ? item?.type : "-"}</td>
                          <td>{item?.note ? item?.note : "-"}</td>
                          <td>
                            {item?.status ? item?.status?.toUpperCase() : "-"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "teacherYTData" ? (
                      item?._id ? (
                        <>
                          <td>{item?.seriesName ? item?.seriesName : "-"}</td>
                          <td>
                            {item?.examTypes ? item?.examTypes?.name : "-"}
                          </td>
                          <td>
                            {item?.subExamTypes
                              ? item?.subExamTypes?.name
                              : "-"}
                          </td>
                          <td>
                            {item?.basecourses ? item?.basecourses?.name : "-"}
                          </td>
                          <td>{item?.subjects ? item?.subjects?.name : "-"}</td>
                          <td>
                            {item?.teachers?.map((i, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>{`${i?.name}`}</td>
                                </tr>
                              );
                            })}
                          </td>
                          <td>{item?.lectureTime ? item?.lectureTime : "-"}</td>
                          <td>
                            {item?.totalLecture ? item?.totalLecture : "-"}
                          </td>
                          <td>{item?.type ? item?.type : "-"}</td>
                          <td>{item?.note ? item?.note : "-"}</td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "questionIssueList" ? (
                      <>
                        <td>{item?.issueType ? item?.issueType : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "oldPackageExist" ? (
                      <>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td>
                          {item?.primarySubCategoryName
                            ? item?.primarySubCategoryName
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "messageDeliveredStatus" ? (
                      <>
                        <td>{item?.firstName ? item?.firstName : "-"}</td>
                        <td>{item?.lastName ? item?.lastName : "-"}</td>
                        <td>
                          {item?.examTypeId ? item?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {item?.baseCourseId ? item?.baseCourseId?.name : "-"}
                        </td>
                        {/* <td></td> */}
                        <td>{item?.coupon ? item?.coupon?.name : "-"}</td>
                        <td>
                          {item?.isWhatsappMsgSent
                            ? JSON.stringify(item.isWhatsappMsgSent)
                            : ""}
                        </td>
                        <td>
                          {item?.whatsAppStatus
                            ? moment
                              .utc(item?.whatsAppStatus?.time)
                              .format("DD-MM-YYYY, h:mm:ss a")
                            : "-"}
                        </td>
                        <td>
                          {item?.createdAt
                            ? moment
                              .utc(item?.createdAt)
                              .format("DD-MM-YYYY, h:mm:ss a")
                            : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "transactionDetails" ? (
                      <>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td>
                          {item?.actualAmount
                            ? `\u20B9 ${item?.actualAmount}`
                            : `\u20B9 0`}
                        </td>
                        <td>
                          {item?.gstAmount
                            ? `\u20B9 ${item?.gstAmount}`
                            : `\u20B9 0`}
                        </td>
                        <td>
                          {item?.amount ? `\u20B9 ${item?.amount}` : `\u20B9 0`}
                        </td>
                        <td>
                          {item?.razorPay ? item?.razorPay?.toUpperCase() : "-"}
                        </td>
                        <td>{item?.bank_account ? item?.bank_account : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "wpConfigData" ? (
                      <>
                        <td>
                          {item?.courseSectionType === 1
                            ? "ONLINE COURSE"
                            : item?.courseSectionType === 2
                              ? "STUDY MATERIAL"
                              : item?.courseSectionType === 3
                                ? "TEST SERIES"
                                : item?.courseSectionType === 5
                                  ? "LECTURE SERIES"
                                  : item?.courseSectionType === 6
                                    ? "CRASH COURSE"
                                    : item?.courseSectionType === 7
                                      ? "PYQ COURSE"
                                      : item?.courseSectionType === 8
                                        ? "PRACTICE COURSE"
                                        : "-"}
                        </td>
                        <td>
                          {item?.sectionType?.map((i, index) => {
                            return (
                              <td key={index}>
                                {i === 1
                                  ? "ONLINE COURSE"
                                  : i === 2
                                    ? "STUDY MATERIAL"
                                    : i === 3
                                      ? "TEST SERIES"
                                      : i === 5
                                        ? "LECTURE SERIES"
                                        : i === 6
                                          ? "CRASH COURSE"
                                          : i === 7
                                            ? "PYQ COURSE"
                                            : i === 8
                                              ? "PRACTICE COURSE"
                                              : "-"}
                                {item?.sectionType?.length > 1 ? "," : ""}
                              </td>
                            );
                          })}
                        </td>
                        <td>{item?.isWhatsappMessage ? "TRUE" : "FALSE"}</td>
                        <td>
                          {item?.subCategoryId?.name
                            ? item?.subCategoryId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.payType === 1
                            ? "FULL PAYMENT"
                            : item?.payType === 2
                              ? "INCOMPLETE PAYMENT"
                              : "-"}
                        </td>
                      </>
                    ) : null}
                    {pagename === "youtubeDataLectureList" ? (
                      item?._id ? (
                        <>
                          <td>
                            {item?.lectureDate
                              ? moment(item?.lectureDate).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td>
                            {item?.lectureTitle ? item?.lectureTitle : "-"}
                          </td>
                          <td>{item?.description ? item?.description : "-"}</td>
                          <td>{item?.lectureLink ? item?.lectureLink : "-"}</td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "userInvoice" ? (
                      item?._id ? (
                        <>
                          <td>{item?.orderNo ? item?.orderNo : "-"}</td>
                          <td>
                            {item?.orderDate
                              ? moment(item?.orderDate).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td>{item?.buyerName ? item?.buyerName : "-"}</td>
                          <td>
                            {item?.batchName
                              ? item?.batchName?.map((i) => {
                                return <span>{i}</span>;
                              })
                              : "-"}
                          </td>
                          <td>
                            {item?.actualAmount ? item?.actualAmount : "-"}
                          </td>
                          <td>
                            {item?.discountAmount ?? item?.discountAmount}
                          </td>
                          <td>{item?.edutechAmount ?? item?.edutechAmount}</td>
                          <td>
                            {item?.publicationAmount ?? item?.publicationAmount}
                          </td>
                          <td
                            className="text-decoration-none"
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            <a
                              href={item?.invoice}
                              download="User Invoice"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.invoice ? "View Pdf" : "-"}
                            </a>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "studentTestData" ? (
                      <>
                        <td>{item?.examName ? item?.examName : "-"}</td>
                        <td>{item?.totalMarks ? item?.totalMarks : "-"}</td>
                        <td>
                          {item?.correct === 0
                            ? 0
                            : item?.correct
                              ? item?.correct
                              : "-"}
                        </td>
                        <td>
                          {item?.incorrect === 0
                            ? 0
                            : item?.incorrect
                              ? item?.incorrect
                              : "-"}
                        </td>
                        <td>
                          {item?.skipped
                            ? item?.skipped
                            : item?.skipped === 0
                              ? 0
                              : "-"}
                        </td>
                        <td>{item?.rank ? item?.rank : "-"}</td>
                      </>
                    ) : null}
                    {pagename === "socialAds" ? (
                      item?._id ? (
                        <>
                          <td>
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td>{item?.examTypes?.name}</td>
                          <td>{item?.basecourses?.name}</td>
                          <td>{item?.isMobileVerified ? "YES" : "NO"}</td>
                          <td>{item?.mediaName}</td>
                          <td>
                            {item?.createdAt
                              ? moment(item?.createdAt).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )
                    ) : null}
                    {pagename === "influencerList" ? (
                      <>
                        <td>{item?.name ? item?.name : "-"}</td>
                        <td>
                          {item?.departmentId ? item?.departmentId?.name : "-"}
                        </td>
                        <td>{item?.perPostPrice ? item?.perPostPrice : "-"}</td>
                        <td>{item?.frequency ? item?.frequency : "-"}</td>
                        <>
                          {item?.platformDetails?.map((i) => (
                            <td>{i?.name}</td>
                          ))}
                        </>
                      </>
                    ) : null}
                    {pagename === "referRevenue" ? (
                      item?.userId ? (
                        <>
                          <td>
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td>{item?.mobileNo}</td>
                          <td>{item?.referralCode}</td>
                          <td>{item?.referralCount}</td>
                          <td>{item?.successfullEnrolled}</td>
                          <td>
                            &#8377;
                            {item?.cashbackAmount === undefined
                              ? 0
                              : item?.cashbackAmount}
                          </td>
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "referRevenueDetail"
                      ? item && (
                        <>
                          <td>
                            {item?.registrationStatus !== 1
                              ? `${item?.referredUserId?.firstName} ${item?.referredUserId?.lastName}`
                              : "-"}
                          </td>
                          <td>{item?.referredContactNumber}</td>
                          <td>
                            {item?.registrationStatus !== 1
                              ? item?.referredUserId?.baseCourseId?.name
                              : "-"}
                          </td>
                          <td>
                            {item?.registrationStatus !== 1
                              ? item?.referredUserId?.examTypeId?.name
                              : "-"}
                          </td>
                          <td>
                            &#8377;
                            {item?.referralAmount === undefined
                              ? 0
                              : item?.referralAmount}
                          </td>
                          <td
                            style={{
                              color:
                                item?.registrationStatus === 1
                                  ? "#F23E36"
                                  : "#158833",
                              fontWeight: "bold",
                            }}
                          >
                            {item?.registrationStatus === 3
                              ? "Enrolled"
                              : item?.registrationStatus === 2
                                ? "Registered"
                                : "Pending"}
                          </td>
                        </>
                      )
                      : null}
                    {pagename === "referCXOPayment"
                      ? item?.userId && (
                        <>
                          <td>
                            {item?.referredUserId?.firstName}{" "}
                            {item?.referredUserId?.lastName}
                          </td>
                          {item?.coursesName?.map((item) => {
                            return (
                              <>
                                <tr
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>{item !== "" ? item : "-"}</td>
                                </tr>
                              </>
                            );
                          })}
                          <td>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</td>
                          <td>
                            {item?.userReferCode !== ""
                              ? item?.userReferCode
                              : "-"}
                          </td>
                          <td>
                            &#8377;
                            {item?.subscribedAmount === undefined
                              ? 0
                              : item?.subscribedAmount}
                          </td>
                          <td>
                            &#8377;
                            {item?.withoutGstReferralAmount
                              ? item?.withoutGstReferralAmount
                              : 0}
                          </td>
                          <td
                          >
                            {item?.status === 2 ? "Pending" : "Paid"}
                          </td>
                        </>
                      )
                      : null}
                    {pagename === "referRevenuePay"
                      ? item?.userId && (
                        <>
                          <td>
                            {item?.referredUserId?.firstName}{" "}
                            {item?.referredUserId?.lastName}
                          </td>
                          {item?.coursesName?.map((item) => {
                            return (
                              <>
                                <tr
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <td>{item !== "" ? item : "-"}</td>
                                </tr>
                              </>
                            );
                          })}
                          {/* <td>{item?.referredUserId?.mobileNo}</td> */}
                          {/* <td>{item?.userId?.source}</td> */}
                          <td>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</td>
                          <td>
                            {item?.userReferCode !== ""
                              ? item?.userReferCode
                              : "-"}
                          </td>
                          <td>
                            &#8377;
                            {item?.subscribedAmount === undefined
                              ? 0
                              : item?.subscribedAmount}
                          </td>
                          <td>
                            &#8377;
                            {item?.withoutGstReferralAmount
                              ? item?.withoutGstReferralAmount
                              : 0}
                          </td>
                          <td
                          // style={{
                          //   color:
                          //     item?.isPaid === true ? "#158833" : "#00000",
                          // }}
                          >
                            {item?.status === 0 ? "Rejected" : item?.status === 1 ? 'Pending' : item?.status === 2 ? 'Approved' : 'Paid'}
                            {/* {item?.userId?.type} */}
                          </td>
                          {/* <td>{item?.successfullRefer !== '' ? item?.successfullRefer : '-'}</td> */}
                          {/* {item?.status !== 1 && (
                            <td style={{color: item?.status === 1 ? "#158833" : "#00000", fontWeight:'bold'}} >{item?.status !== 1 ? 'Paid' : ''}</td>
                          )} */}
                        </>
                      )
                      : null}
                    {/* {pagename === "assuredRevenue" ? (
                      item?.userId ? (
                        <td>{item?.userId?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}

                    {pagename === "assuredRevenue" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "assuredRevenue" ? (
                      item?.courseId ? (
                        displayNames(item?.courseId)
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "assuredRevenue" ? (
                      item?.dueAmount ? (
                        <td>{item?.dueAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "assuredRevenue" ? (
                      item?.dueDate ? (
                        <td>
                          {moment.utc(item?.dueDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ? (
                      item?.userId ? (
                        <td>{item?.userId?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ||
                      pagename === "liveClassSchedule" ||
                      pagename === "courseSupport" ? (
                      item?.courseId ? (
                        <td>{item?.courseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "liveClassSchedule" ? (
                      item?.fromDate ? (
                        <td>
                          {item?.fromDate}
                          {item?.sessionTime ? `, ${item?.sessionTime}` : null}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "liveClassSchedule" ? (
                      item?.sessionId ? (
                        <td>{item?.sessionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "liveClassSchedule" ? (
                      <td>
                        {item.status === 1 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-danger btn-sm"
                              onClick={() => handleCancelButton(item)}
                            >
                              Cancel
                            </button>
                            <button
                              className="add_Question-btn btn btn btn-outline-primary btn-sm"
                              onClick={() => handleRescheduleButton(item)}
                            >
                              Reschedule
                            </button>
                          </div>
                        ) : item.status === 2 ? (
                          <button
                            className="add_Question-btn btn btn btn-outline-danger btn-sm me-4"
                            onClick={() => handleEndLiveButton(item)}
                          >
                            End Live
                          </button>
                        ) : item.status === 9 ? (
                          "Live Cancelled"
                        ) : item.status === 3 ? (
                          "Completed"
                        ) : null}
                      </td>
                    ) : null}

                    {pagename === "courseSupport" ? (
                      item?.subjectId ? (
                        <td>{item?.subjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "courseSupport" ? (
                      item?.topicId ? (
                        <td>{item?.topicId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "courseSupport" ? (
                      item?.userId ? (
                        <td>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ? (
                      item?.technicalSupportType ? (
                        <td>
                          {item?.technicalSupportType === 1
                            ? "App Related"
                            : "Course Related"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ? (
                      item?.issue ? (
                        <td>{item?.issue}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "helpdesk" ? (
                      item?._id ? (
                        <td>{item?._id}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "helpdesk" ? (
                      item?.queryNo ? (
                        <td>{item?.queryNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "helpdesk" ? (
                      item?.issueDate ? (
                        <td>
                          {moment
                            .utc(item?.issueDate)
                            .format("DD/MM/YYYY h:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "helpdesk" ? (
                      item?.category ? (
                        <td>
                          {item?.category === 1
                            ? "Batch Support"
                            : item?.category === 2
                              ? "General Support"
                              : "Infra Support"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "helpdesk" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "helpdesk" ? (
                      item?.examTypeId ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "helpdesk" ? (
                      item?.courseId ? (
                        <td>{item?.courseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "helpdesk" ? (
                      item?.message ? (
                        <td>{item?.message}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* {pagename === "technicalSupport" ? (
                        <td>
                          {item?.technicalSupportType == 1
                            ? "App Related"
                            : "Course Related"}
                        </td>
                      ) : null} */}

                    {pagename === "InfraIssue" ? (
                      item?.studioNumber ? (
                        <td>{item?.studioNumber}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/*********************infraSupport********************* */}

                    {pagename === "infraSupport" ? (
                      item?.teacherId ? (
                        <td>{item?.teacherId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.studioNumber ? (
                        <td>{item?.studioNumber}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "infraSupport" ? (
                      item?.issueTypeId ? (
                        <td>{item?.issueTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.message ? (
                        <td>{item?.message}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.issueRemark ? (
                        <td>{item?.issueRemark}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.createdAt ? (
                        <td>
                          {moment
                            .utc(item?.createdAt)
                            .format("DD/MM/YYYY h:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.updatedAt === item?.createdAt ? (
                        <td>-</td>
                      ) : (
                        <td>
                          {moment
                            .utc(item?.updatedAt)
                            .format("DD/MM/YYYY h:mm:ss a")}
                        </td>
                      )
                    ) : null}
                    {pagename === "infraSupport" ? (
                      item?.Duration ? (
                        <td>{Math.floor(item?.Duration)} Min</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubeSeoTrackList" ? (
                      <>
                        <td>{item?.examTypes ? item?.examTypes?.name : "-"}</td>
                        <td>
                          {item?.subExamTypes ? item?.subExamTypes?.name : "-"}
                        </td>
                        <td>
                          {item?.basecourses ? item?.basecourses?.name : "-"}
                        </td>
                        <td>{item?.lectureTitle ? item?.lectureTitle : "-"}</td>
                        {item?.teachers?.map((itm) => {
                          return (
                            <tr className="bg-transparent">
                              <td>{itm?.name}</td>
                            </tr>
                          );
                        })}
                        <td>
                          {item?.lectureDate
                            ? moment(item?.lectureDate).format("DD MMM YYYY")
                            : "-"}
                        </td>
                      </>
                    ) : null}

                    {pagename === "studentCount" ? (
                      item?.examTypeId ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "studentCount" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "studentCount" ? (
                      item?.studentCount || item?.studentCount >= 0 ? (
                        <td>{item?.studentCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "studentCount" ? (
                      item?.countPercentage ? (
                        <td>{item?.countPercentage}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "paymentPublication" ? (
                      <>
                        <td>{item?.orderNo ? item?.orderNo : "-"}</td>
                        <td>
                          {item?.date
                            ? moment(item?.date).format("DD-MM-YYYY HH:MM A")
                            : "-"}
                        </td>
                        {/* <td>
                          {item?.userDetails
                            ? `${item?.userDetails?.firstName} ${item?.userDetails?.lastName}`
                            : "-"}
                        </td> */}
                        {/* <td>
                          {item?.cityDetails?.name
                            ? item?.cityDetails?.name
                            : "-"}
                        </td> */}
                        {/* <td>
                          {item?.stateDetails?.name
                            ? item?.stateDetails?.name
                            : "-"}
                        </td> */}
                        <td>
                          {item?.coursedata ? item?.coursedata[0]?.name : "-"}
                        </td>
                        <td>
                          {item?.netPayableAmount
                            ? item?.netPayableAmount
                            : "-"}
                        </td>
                        <td>
                          {item?.courses ? item?.courses[0]?.couponName : "-"}
                        </td>
                        <td>
                          {item?.totalDiscountAmount
                            ? item?.totalDiscountAmount
                            : "0"}
                        </td>
                        <td>
                          {item?.paymentGatewayResponse
                            ? item?.paymentGatewayResponse[0]?.status
                            : "-"}
                        </td>
                      </>
                    ) : null}

                    {/* *****************************studyMaterialOrders**************************** */}

                    {pagename === "studyMaterialOrders" ? (
                      item?.orderNo ? (
                        <td>{item?.orderNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "studyMaterialOrders" ? (
                      item?.date ? (
                        <td>{moment.utc(item?.date).format("DD-MM-YYYY")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.usersData ? (
                        <td>
                          {item?.usersData?.firstName}{" "}
                          {item?.usersData?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.address?.stateId ? (
                        <td>{item?.address?.stateId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.usersData?.mobileNo ? (
                        <td>{item?.usersData?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.netPayableAmount ? (
                        <td>{item?.netPayableAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.baseCourse ? (
                        <td>{item?.baseCourse?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "studyMaterialOrders" ? (
                      item?.dispatchedDate ? (
                        <td>
                          {moment
                            .utc(item?.dispatchedDate)
                            .format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyPackageList" ? (
                      <>
                        <td>
                          {item?.fromExamTypeId?.name
                            ? item?.fromExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.fromSubExamTypeId?.name
                            ? item?.fromSubExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.fromBaseCourseId?.name
                            ? item?.fromBaseCourseId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.fromCourseId?.name
                            ? item?.fromCourseId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.toExamTypeId?.name
                            ? item?.toExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.toSubExamTypeId?.name
                            ? item?.toSubExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.toBaseCourseId?.name
                            ? item?.toBaseCourseId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.toCourseId?.name
                            ? item?.toCourseId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.status === 0
                            ? "NOT EXECUTED"
                            : item?.status === 1
                              ? "SCRIPT RUNNING ON"
                              : item?.status === 2
                                ? "SCRIPT EXECUTED"
                                : "-"}
                        </td>
                      </>
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromExamTypeId ? (
                        <td>{item?.fromExamTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromSubExamTypeId ? (
                        <td>{item?.fromSubExamTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromBaseCourseId ? (
                        <td>{item?.fromBaseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromCourseId ? (
                        <td>{item?.fromCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromSubjectId ? (
                        <td>{item?.fromSubjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.fromTopicId ? (
                        <td>{item?.fromTopicId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.toCourseId ? (
                        <td>{item?.toCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.toSubjectId ? (
                        <td>{item?.toSubjectId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.toTopicId ? (
                        <td>{item?.toTopicId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "copyRecordedLecture" ? (
                      item?.status >= 0 ? (
                        <td>
                          {item?.status === 0
                            ? "NOT EXECUTED"
                            : item?.status === 1
                              ? "SCRIPT RUNNING ON"
                              : item?.status === 2
                                ? "SCRIPT EXECUTED"
                                : "-"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* ***************************** Registered User List**************************** */}

                    {pagename === "registeredUserList" ||
                      pagename === "leadAssignedStatusOverview" ? (
                      item?.firstName ? (
                        <td>{item?.firstName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ||
                      pagename === "leadAssignedStatusOverview" ? (
                      item?.lastName ? (
                        <td>{item?.lastName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* {pagename === "registeredUserList" ? (
                      item?.mobileNo ? (
                        <td>{item?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}

                    {pagename === "registeredUserList" ? (
                      item?.createdAt ? (
                        <td>
                          {moment.utc(item.createdAt).format("DD - MM - YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ? (
                      item?.createdAt ? (
                        <td>
                          {moment.utc(item.createdAt).format("hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ||
                      pagename === "leadAssignedStatusOverview" ? (
                      item?.state ? (
                        <td>{item?.state}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.course ? (
                        <td>{item?.course}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.status ? (
                        <td>{item?.status}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ? (
                      item?.examType ? (
                        <td>{item?.examType}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ? (
                      item?.baseCourse ? (
                        <td>{item?.baseCourse}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registeredUserList" ||
                      pagename === "leadAssignedStatusOverview" ? (
                      item?.source ? (
                        <td>{item?.source}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.remark ? (
                        <td className="text-break">{item?.remark}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* {pagename === "leadAssignedStatusOverview" ? (
                      <td>
                        {item?.statusId === 2 || item?.statusId === 3
                          ? item.allotedDate
                            ? moment(item.allotedDate)
                                .utc()
                                .format("DD-MM-YYYY hh:mm:ss a")
                            : ""
                          : item?.statusId !== 2 || item?.statusId !== 3
                          ? moment(item.callBackDate)
                              .utc()
                              .format("DD-MM-YYYY hh:mm:ss a")
                          : "-"}
                      </td>
                    ) : null} */}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.callBackDate ? (
                        <td>
                          {moment(item.callBackDate)
                            .utc()
                            .format("DD-MM-YYYY hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.allotedDate ? (
                        <td>
                          {moment(item.allotedDate)
                            .utc()
                            .format("DD-MM-YYYY hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.registerDate ? (
                        <td>
                          {moment(item.registerDate)
                            .utc()
                            .format("DD-MM-YYYY hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAssignedStatusOverview" ? (
                      item?.admissionDate ? (
                        <td>
                          {moment(item.admissionDate)
                            .utc()
                            .format("DD-MM-YYYY hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "registeredUserList" ? (
                      item?.actualOrigin ? (
                        <td>{item?.actualOrigin}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* ************************************** */}

                    {pagename === "socialLinks" ? (
                      item?.examTypeId ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "socialLinks" ? (
                      item?.baseCourseId ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "socialLinks" ? (
                      item?.fb_page ? (
                        <td className="text-break">
                          <a
                            href={item?.fb_page}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.fb_page}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "socialLinks" ? (
                      item?.fb_group ? (
                        <td className="text-break">
                          <a
                            href={item?.fb_group}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.fb_group}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "socialLinks" ? (
                      item?.ig_page ? (
                        <td className="text-break">
                          <a
                            href={item?.ig_page}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.ig_page}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "socialLinks" ? (
                      item?.tg_channel ? (
                        <td className="text-break">
                          <a
                            href={item?.tg_channel}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.tg_channel}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "socialLinks" ? (
                      item?.tg_group ? (
                        <td className="text-break">
                          <a
                            href={item?.tg_group}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.tg_group}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "socialLinks" ? (
                      item?.yt_channel ? (
                        <td className="text-break">
                          <a
                            href={item?.yt_channel}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.yt_channel}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "technicalSupport" ||
                      pagename === "courseSupport" ||
                      pagename === "raiseIssue" ||
                      pagename === "raiseTeacherIssue" ||
                      pagename === "helpdesk" ||
                      pagename === "InfraIssue" ? (
                      <td>
                        {item.status === 1 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-success btn-sm me-4"
                              onClick={() => {
                                handleAddOnButton(
                                  item._id,
                                  pagename === "raiseTeacherIssue"
                                    ? item?.adminUser
                                    : item?.userId
                                      ? item?.userId
                                      : item.teacherId,
                                  item.status,
                                  pagename === "raiseIssue" ||
                                    pagename === "raiseTeacherIssue" ||
                                    pagename === "InfraIssue"
                                    ? item?.queryNo
                                    : ""
                                );
                              }}
                            >
                              New
                            </button>
                            {item?.unreadMsgCountAdmin > 0 ? (
                              <div className="unreadcount bg-success">
                                {item?.unreadMsgCountAdmin}
                              </div>
                            ) : null}
                          </div>
                        ) : item.status === 2 ? (
                          moment.utc(item.createdAt).format("DD-MM-YYYY") !==
                            moment.utc(new Date()).format("DD-MM-YYYY") ? (
                            <div className="position-relative statusbtn">
                              <button
                                className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                                onClick={() =>
                                  handleAddOnButton(
                                    item._id,
                                    item.userId ? item.userId : item.teacherId,
                                    item.status,
                                    pagename === "raiseIssue" ||
                                      pagename === "raiseTeacherIssue" ||
                                      pagename === "InfraIssue"
                                      ? item?.queryNo
                                      : ""
                                  )
                                }
                              >
                                InProgress
                              </button>
                              {item?.unreadMsgCountAdmin > 0 ? (
                                <div className="unreadcount bg-success">
                                  {item?.unreadMsgCountAdmin}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div className="position-relative statusbtn">
                              <button
                                className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                                onClick={() =>
                                  handleAddOnButton(
                                    item._id,
                                    item.userId ? item.userId : item.teacherId,
                                    item.status,
                                    pagename === "raiseIssue" ||
                                      pagename === "raiseTeacherIssue" ||
                                      pagename === "InfraIssue"
                                      ? item?.queryNo
                                      : ""
                                  )
                                }
                              >
                                Open
                              </button>
                              {item?.unreadMsgCountAdmin > 0 ? (
                                <div className="unreadcount bg-success">
                                  {item?.unreadMsgCountAdmin}
                                </div>
                              ) : null}
                            </div>
                          )
                        ) : item.status === 3 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-success btn-sm me-4"
                              onClick={() =>
                                handleAddOnButton(
                                  item._id,
                                  item.userId ? item.userId : item.teacherId,
                                  item.status,
                                  pagename === "raiseIssue" ||
                                    pagename === "raiseTeacherIssue"
                                    ? item?.queryNo
                                    : ""
                                )
                              }
                            >
                              View
                            </button>
                          </div>
                        ) : null}
                      </td>
                    ) : null}

                    {pagename === "infraSupport" ? (
                      <td>
                        {item.status === 1 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-success btn-sm me-4"
                              onClick={() => {
                                handleAddOnButton(
                                  item._id,
                                  pagename === "raiseTeacherIssue"
                                    ? item?.adminUser
                                    : item?.userId
                                      ? item?.userId
                                      : item.teacherId,
                                  item.status,
                                  pagename === "raiseIssue" ||
                                    pagename === "raiseTeacherIssue" ||
                                    pagename === "InfraIssue"
                                    ? item?.queryNo
                                    : ""
                                );
                                startResolve(
                                  item._id,
                                  item?.teacherId?._id,
                                  item.issueDate,
                                  item.category,
                                  item.studioNumber,
                                  item.message,
                                  item.issueRemark
                                );
                              }}
                            >
                              New
                            </button>
                            {item?.unreadMsgCountAdmin > 0 ? (
                              <div className="unreadcount bg-success">
                                {item?.unreadMsgCountAdmin}
                              </div>
                            ) : null}
                          </div>
                        ) : item.status === 2 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                              onClick={() =>
                                handleAddOnButton(
                                  item._id,
                                  item.userId ? item.userId : item.teacherId,
                                  item.status,
                                  pagename === "raiseIssue" ||
                                    pagename === "raiseTeacherIssue" ||
                                    pagename === "InfraIssue"
                                    ? item?.queryNo
                                    : ""
                                )
                              }
                            >
                              InProgress
                            </button>
                          </div>
                        ) : // ) : (
                          //   <div className="position-relative statusbtn">
                          //     <button
                          //       className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                          //       onClick={() =>
                          //         handleAddOnButton(
                          //           item._id,
                          //           item.userId ? item.userId : item.teacherId,
                          //           item.status,
                          //           pagename === "raiseIssue" ||
                          //             pagename === "raiseTeacherIssue" ||
                          //             pagename === "InfraIssue"
                          //             ? item?.queryNo
                          //             : ""
                          //         )
                          //       }
                          //     >
                          //       Open
                          //     </button>
                          //   </div>
                          // )
                          item.status === 3 ? (
                            <div className="position-relative statusbtn">
                              <button
                                className="add_Question-btn btn btn btn-outline-success btn-sm me-4"
                                onClick={() =>
                                  handleAddOnButton(
                                    item._id,
                                    item.userId ? item.userId : item.teacherId,
                                    item.status,
                                    pagename === "raiseIssue" ||
                                      pagename === "raiseTeacherIssue"
                                      ? item?.queryNo
                                      : ""
                                  )
                                }
                              >
                                View
                              </button>
                            </div>
                          ) : null}
                      </td>
                    ) : null}
                    {/* ********************************************************************DoubtSolveReview******************************************************************                                      */}
                    {pagename === "DoubtSolveReview" ? (
                      <td>{item?.supportData?.issueId}</td>
                    ) : null}
                    {pagename === "DoubtSolveReview" ? (
                      <td>
                        {item?.supportData?.createdAt
                          ? moment
                            .utc(item?.supportData?.createdAt)
                            .format("DD/MM/YYYY HH:MM:SS")
                          : "-"}
                      </td>
                    ) : null}
                    {pagename === "DoubtSolveReview" ? (
                      <td>{item?.batchData ? item?.batchData?.name : "-"}</td>
                    ) : null}

                    {pagename === "DoubtSolveReview" ? (
                      <td>
                        {item?.subjectData ? item?.subjectData?.name : "-"}
                      </td>
                    ) : null}
                    {pagename === "DoubtSolveReview" ? (
                      <td>{item?.topicData ? item?.topicData?.name : "-"}</td>
                    ) : null}
                    {pagename === "DoubtSolveReview" ? (
                      <td>
                        {item?.userData ? item?.userData?.firstName : "-"}{" "}
                        {item?.userData ? item?.userData?.lastName : ""}
                      </td>
                    ) : null}
                    {pagename === "DoubtSolveReview" ? (
                      <td>
                        {item?.supportData?.status === 1 ? (
                          <div className="position-relative statusbtn">
                            <button
                              className="add_Question-btn btn btn btn-outline-success btn-sm"
                              onClick={() =>
                                handleAddOnButton(
                                  item.supportData?._id,
                                  item.userData
                                )
                              }
                            >
                              New
                            </button>
                          </div>
                        ) : item?.supportData?.status === 2 ? (
                          moment.utc(item.createdAt).format("DD-MM-YYYY") !==
                            moment.utc(new Date()).format("DD-MM-YYYY") ? (
                            <button
                              className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                              onClick={() =>
                                handleAddOnButton(
                                  item.supportData?._id,
                                  item.userDatatem._id,
                                  item.userId
                                )
                              }
                            >
                              InProgress
                            </button>
                          ) : (
                            <button
                              className="add_Question-btn btn btn btn-outline-primary btn-sm me-4"
                              onClick={() =>
                                handleAddOnButton(
                                  item.supportData?._id,
                                  item.userData
                                )
                              }
                            >
                              Open
                            </button>
                          )
                        ) : item?.supportData?.status === 3 ? (
                          <button
                            className="add_Question-btn btn btn btn-outline-secondary btn-sm me-4"
                            onClick={() =>
                              handleAddOnButton(
                                item.supportData?._id,
                                item.userData
                              )
                            }
                          >
                            Close
                          </button>
                        ) : null}
                      </td>
                    ) : null}

                    {/* *********************** Edit Exam Set */}

                    {pagename === "examSet" ? (
                      item?.en || item?.name ? (
                        <td>{item?.en?.name ? item?.en?.name : item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" ? (
                      item?.totalQuestions >= 0 &&
                        item?.totalAddedQuestion >= 0 ? (
                        <td>
                          {item?.totalAddedQuestion}/{item?.totalQuestions}
                        </td>
                      ) : (
                        <td>0/0</td>
                      )
                    ) : null}
                    {pagename === "examSet" ? (
                      item?.order != null ? (
                        <td>{item?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" ? (
                      item?.isActive != null ? (
                        <td style={{ width: "5%" }} className="removelabel">
                          <ToggleSwitchBtn
                            id={item._id}
                            key={item._id}
                            isActive={item?.isActive ?? false}
                            getToggleval={(e) => onstatusChanged(e, item._id)}
                          />
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "examSet" ? (
                      item?.duration != null ? (
                        <td>{getSeconds(item?.duration)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* Edit Delete package */}

                    {pagename === "package" ||
                      pagename === "referenceLeadCount" ||
                      pagename === "teamReferenceCount" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.section ? (
                        <td>{item?.section}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.language ? (
                        <td>{item?.language?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "basePrice" ? (
                      item?.sectionId?.name ? (
                        <td>{item?.sectionId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "fixedDiscount" ? (
                      item?.examTypeId?.name ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "fixedDiscount" ? (
                      item?.baseCourseId?.name ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "fixedDiscount" ? (
                      item?.primarySubCategoryId?.name ? (
                        <td>{item?.primarySubCategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "basePrice" ? (
                      item?.primarySubCategoryId?.name ? (
                        <td>{item?.primarySubCategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "basePrice" ? (
                      <td>{item?.isCombination ? "True" : "False"}</td>
                    ) : null}

                    {pagename === "package" ? (
                      item?.order ? (
                        <td>{item?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.displayOnHomePage || !item?.displayOnHomePage ? (
                        <td>
                          {item?.displayOnHomePage === true ? "Yes" : "No"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.mainCategoryId ? (
                        <td>{item?.mainCategoryId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.startDate ? (
                        <td>{item?.startDate.slice(0, 10)}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "package" ? (
                      item?.status || item?.status === 0 ? (
                        <td>{item?.status === 1 ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* *********************** Edit Teacher */}

                    {pagename === "teacher" ? (
                      item?.name ? (
                        <td>{item?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "teacher" ? (
                      item?.username ? (
                        <td>{item?.username}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "teacher" ? (
                      item?.email ? (
                        <td>{item?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* *********************** Expert Support */}

                    {pagename === "expertSupport" ? (
                      item?.fullName ? (
                        <td>{item?.fullName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* {pagename === "expertSupport" ? (
                      item?.mobileNumber ? (
                        <td>{item?.mobileNumber}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null} */}

                    {pagename === "expertSupport" ? (
                      item?.updatedAt ? (
                        <td>
                          {moment.utc(item.updatedAt).format("DD - MM - YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "expertSupport" ? (
                      item?.updatedAt ? (
                        <td>
                          {moment.utc(item.updatedAt).format("hh:mm:ss a")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "callExpertSupport" ? (
                      item?.fullName ? (
                        <td>{item?.fullName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "callExpertSupport" ? (
                      item?.updatedAt ? (
                        <td>
                          {moment(item?.updatedAt).format("DD - MM - YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "callExpertSupport" ? (
                      item?.updatedAt ? (
                        <td>{moment(item?.updatedAt).format("hh:mm:ss a")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "callExpertSupport" ? (
                      item?.count ? (
                        <td>{item?.count}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "callExpertSupport" ? (
                      item?.Remark ? (
                        <td>{item?.Remark}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "callExpertSupport" ? (
                      item?.remarkDate ? (
                        <td>
                          {moment(item?.remarkDate).format("DD/MM/YYYY LTS")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* *******************Createtarget************** */}

                    {pagename === "createTarget" ? (
                      item?.examTypeId?.name ? (
                        <td>{item?.examTypeId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.baseCourseId?.name ? (
                        <td>{item?.baseCourseId?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.role ? (
                        <td>{item?.role}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.target?.bronze_level ? (
                        <td>{item?.target?.bronze_level?.leadTarget}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.target?.silver_level ? (
                        <td>{item?.target?.silver_level?.leadTarget}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.target?.gold_level ? (
                        <td>{item?.target?.gold_level?.leadTarget}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "createTarget" ? (
                      item?.target?.platinum_level ? (
                        <td>{item?.target?.platinum_level?.leadTarget}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "youtubeCreateTarget" ? <></> : null}
                    {/* ********************** leadAnalysis ********************* */}
                    {pagename === "leadAnalysisDailyData" ? (
                      item?.dayOfWeek ? (
                        <td>{item?.dayOfWeek}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisDailyData" ? (
                      item?.createdAt ? (
                        <td>
                          {" "}
                          {moment.utc(item?.createdAt).format("DD/MM/YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisWeeklyData" ? (
                      item?.Weeks ? (
                        <td>{item?.Weeks}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisYearlyData" ? (
                      <>
                        <td>{item?.Months}</td>
                        <>
                          {item?.Year?.map((item, index) => {
                            return <td key={index}>{item?.leadAchieved}</td>;
                          })}
                        </>
                      </>
                    ) : null}
                    {pagename === "leadAnalysisMonthlyData" ? (
                      item?.Months ? (
                        <td>{item?.Months}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "leadAnalysisMonthlyData" &&
                      item?.Year?.length ? (
                      item?.Year?.length ? (
                        <>
                          {item?.Year?.map((item, index) => {
                            return <td key={index}>{item?.leadAchieved}</td>;
                          })}
                        </>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisDailyData" ||
                      pagename === "leadAnalysisWeeklyData" ||
                      pagename === "leadAnalysisMonthlyData" ? (
                      item?.leadTarget ? (
                        <td>{item?.leadTarget}</td>
                      ) : (
                        <td>0</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisDailyData" ||
                      pagename === "leadAnalysisWeeklyData" ||
                      pagename === "leadAnalysisMonthlyData" ? (
                      item?.leadAchieved ? (
                        <td>{item?.leadAchieved}</td>
                      ) : (
                        <td>0</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisDailyData" ||
                      pagename === "leadAnalysisWeeklyData" ||
                      pagename === "leadAnalysisMonthlyData" ? (
                      item?.deficit ? (
                        <>
                          {item?.deficit < 0 ? (
                            <td className="red">{item?.deficit}</td>
                          ) : (
                            <td className="colorGreen">{item?.deficit}</td>
                          )}
                        </>
                      ) : (
                        <td>0</td>
                      )
                    ) : null}
                    {pagename === "leadAnalysisDailyData" ||
                      pagename === "leadAnalysisWeeklyData" ||
                      pagename === "leadAnalysisMonthlyData" ? (
                      item?.ratio ? (
                        <td>{item?.ratio} %</td>
                      ) : (
                        <td>0</td>
                      )
                    ) : null}
                    {/* ***************************** Reference Lead Count**************************** */}

                    {pagename === "referenceLeadCount" ||
                      pagename === "teamReferenceCount" ? (
                      item?.studentleadCount >= 0 ? (
                        <td>{item?.studentleadCount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "EmployeeView" ? (
                      <>
                        <td className="text-break " >
                          {item?.firstName} {item?.lastName}
                        </td>
                        <td>{item?.employeeId ? item?.employeeId : "-"}</td>
                        <td className="col-1">{item?.mobileNo ? item?.mobileNo : "-"}</td>
                        <td className="col-2">{item?.company ? item?.company?.name : "-"}</td>
                        <td className="text-break ">{item?.manager ? item?.manager?.name : "-"}</td>
                        <td>
                          {item?.department ? item?.department?.name : "-"}
                        </td>
                        <td>
                          {item?.designation ? item?.designation?.name : "-"}
                        </td>
                        <td>{item?.role ? item?.role?.name : "-"}</td>
                      </>
                    ) : null}
                    {/************************************* Employee ********************************************** */}
                    {pagename === "Employee" ? (
                      item?.firstName ? (
                        <td>
                          {item?.firstName} {item?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "Employee" ? (
                      item?.employeeId ? (
                        <td>{item?.employeeId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.mobileNo ? (
                        <td>{item?.mobileNo}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.company ? (
                        <td>{item?.company?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.manager ? (
                        <td>{item?.manager?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.department ? (
                        <td>{item?.department?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.designation ? (
                        <td>{item?.designation?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "Employee" ? (
                      item?.role ? (
                        <td>{item?.role?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "referCXOPayment" ? (
                      <span
                        style={{ marginTop: 6 }}
                        type="submit"
                        className="text-success me-2 fw-bold cursor"
                        onClick={() => handleEdit(item)}
                      >
                        <td> {item?.status === 2 ? "Pay Now" : 'View'}</td>
                      </span>
                    ) : null}
                    {pagename === "referRevenuePay" ? (
                      <span
                        style={{ marginTop: 6 }}
                        type="submit"
                        className="text-success me-2 fw-bold cursor"
                        onClick={() => handleEdit(item)}
                      >
                        <td> {item?.status === 1 ? "Pay Now" : ''}</td>
                      </span>
                    ) : null}

                    {pagename === "competitorData" ? (
                      <>
                        <td>
                          {item?.examTypeId ? item?.examTypeId?.name : "-"}
                        </td>
                        <td>
                          {item?.subExamTypeId
                            ? item?.subExamTypeId?.name
                            : "-"}
                        </td>
                        <td>
                          {item?.baseCourseId ? item?.baseCourseId?.name : "-"}
                        </td>
                        <td>
                          {item?.competitorId ? item?.competitorId?.name : "-"}
                        </td>
                        <td>{item?.title ? item?.title : "-"}</td>
                        <td>
                          {item?.postedDate
                            ? moment(item?.postedDate).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={item?.videoURL ? item?.videoURL : "#"}
                            rel="noreferrer"
                          >
                            Link
                          </a>
                        </td>
                        <td>
                          {item?.duration ? getSeconds(item?.duration) : "-"}
                        </td>
                        <td>{item?.viewCount ? item?.viewCount : "-"}</td>
                        <td>{item?.likeCount ? item?.likeCount : "-"}</td>
                        <td>{item?.commentCount ? item?.commentCount : "-"}</td>
                      </>
                    ) : null}

                    {/* ***********************ShowResult********************************* */}
                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.firstName ? (
                        <td className="text-break  ">
                          {" "}
                          {item.firstName} {item?.lastName}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.examTypes ? (
                        <td className="text-break  ">
                          {" "}
                          {item.examTypes?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.basecourses ? (
                        <td className="text-break  ">
                          {" "}
                          {item.basecourses?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.createdUser ? (
                        <td className="text-break  ">
                          {" "}
                          {item.createdUser?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.qualifyFor ? (
                        <td className="text-break  "> {item.qualifyFor}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.qualifyYear ? (
                        <td className="text-break  "> {item.qualifyYear}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "showResult" ||
                      pagename === "showResultExport" ? (
                      item?.createdAt ? (
                        <td className="text-break  ">
                          {" "}
                          {moment(item?.createdAt).utc().format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "showResultExport" ? (
                      item?.rank ? (
                        <td className="text-break  "> {item?.rank}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "showResultExport" ? (
                      item?.rollNumber ? (
                        <td className="text-break  "> {item?.rollNumber}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* {pagename === "showResult" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleNavigate(item)}
                      >
                        Details
                      </td>
                    ) : null} */}
                    {/* ***************************ImportQuestion********************* */}
                    {pagename === "importQuestion" ? (
                      item?.questionType ? (
                        <td className="text-break  ">
                          {" "}
                          {item?.questionType === 1
                            ? "MCQ"
                            : item?.questionType === 2
                              ? "MSQ"
                              : "FIXED"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "importQuestion" ? (
                      item?.en?.title ? (
                        <td>
                          {item?.en?.title?.map((value) => {
                            return (
                              <>
                                {value?.descType === "text" ? (
                                  <>{value?.description}</>
                                ) : null}

                                {value?.descType === "img" ? (
                                  <ImagePickerAndViewer
                                    lableName={"Image"}
                                    value={value?.description}
                                    removelabel={true}
                                    disabled
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ************************************************************** */}

                    {/* ********************************************MOM************************************************** */}
                    {pagename === "MOM" ? (
                      item?.date ? (
                        <td> {moment.utc(item?.date).format("DD/MM/YYYY ")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "MOM" ? (
                      <td>
                        {item?.attendeeMembers?.map((i, index) => {
                          return (
                            <tr
                              key={index}
                              style={{ backgroundColor: "transparent" }}
                            >
                              <td>{`${i?.firstName} ${i?.lastName}`}</td>
                            </tr>
                          );
                        })}
                      </td>
                    ) : null}
                    {pagename === "MOM" ? (
                      <td>
                        {item?.presentMembers?.map((i, index) => {
                          return (
                            <tr
                              key={index}
                              style={{ backgroundColor: "transparent" }}
                            >
                              <td>{`${i?.firstName} ${i?.lastName}`}</td>
                            </tr>
                          );
                        })}
                      </td>
                    ) : null}
                    {pagename === "MOM" ? (
                      <td>
                        {item?.absentMembers?.map((i, index) => {
                          return (
                            <tr
                              key={index}
                              style={{ backgroundColor: "transparent" }}
                            >
                              <td>{`${i?.firstName} ${i?.lastName}`}</td>
                            </tr>
                          );
                        })}
                      </td>
                    ) : null}
                    {pagename === "MOM" ? (
                      item?.agenda ? (
                        <td>{item?.agenda}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "MOM" ? (
                      item?.duration ? (
                        <td>{item?.duration}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "examSet" ||
                      pagename === "package" ||
                      pagename === "basePrice" ||
                      pagename === "competitorData" ||
                      pagename === "fixedDiscount" ||
                      pagename === "teacher" ||
                      pagename === "studyMaterialOrders" ||
                      pagename === "copyRecordedLecture" ||
                      pagename === "youtubeDataLectureList" ||
                      pagename === "copyPackageList" ||
                      pagename === "wpConfigData" ||
                      pagename === "createTarget" ||
                      pagename === "Employee" ||
                      pagename === "EmployeeView" ||
                      pagename === "questionIssueList" ||
                      pagename === "callExpertSupport" ||
                      pagename === "questionPollListEdit" ||
                      pagename === "employeeAssetActive" ||
                      pagename === "socialLinks" ||
                      pagename === "showResult" ? (
                      <td>
                        {edit ? (
                          pagename === "studentCount" ||
                            pagename === "callExpertSupport" ||
                            pagename === "EmployeeView" ? (
                            <span
                              type="submit"
                              className="text-success me-2 fw-bold cursor"
                              onClick={() => handleEdit(item)}
                            >
                              View Details
                            </span>
                          ) : (
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor"
                              onClick={() => handleEdit(item)}
                            >
                              Edit
                            </span>
                          )
                        ) : null}
                        {pagename !== "expertSupport" &&
                          pagename !== "callExpertSupport" &&
                          pagename !== "basePrice" &&
                          pagename !== "fixedDiscount" &&
                          pagename !== "questionIssueList" &&
                          pagename !== "employeeAssetActive" &&
                          pagename !== "socialLinks" &&
                          pagename !== "competitorData" &&
                          pagename !== "showResult" &&
                          pagename !== "EmployeeView" ? (
                          <span
                            type="submit"
                            className="text-danger fw-bold cursor"
                            onClick={() => {
                              setDeletePopup(true);
                              let deleteDataName = "";
                              {
                                pagename === "package" || pagename === "teacher"
                                  ? (deleteDataName = item?.name)
                                  : pagename === "copyRecordedLecture"
                                    ? (deleteDataName = "Script")
                                    : pagename === "wpConfigData"
                                      ? (deleteDataName = "Configuration")
                                      : pagename === "examSet"
                                        ? (deleteDataName = item?.en?.name)
                                        : pagename === "youtubeDataLectureList"
                                          ? (deleteDataName = item?.lectureTitle)
                                          : pagename === "Employee"
                                            ? (deleteDataName = `${item?.firstName} ${item?.lastName}`)
                                            : (deleteDataName = "");
                              }

                              setDeleteData({
                                ...item,
                                deleteDataName,
                              });
                            }}
                          >
                            Delete
                          </span>
                        ) : null}
                      </td>
                    ) : null}
                    {/* -------------------------View Student Count------------------------------- */}
                    {pagename === "studentCount" ||
                      pagename === "financeManagement" ||
                      pagename === "referRevenue" ||
                      pagename === "youtubeData" ||
                      pagename === "teacherYTData" ||
                      pagename === "questionDoubt" ||
                      pagename === "studentAnalysisData" ||
                      pagename === "referenceLeadCount" ||
                      pagename === "teamReferenceCount" ||
                      pagename === "youtubeSeoTrackList" ||
                      pagename === "MOM" ? (
                      <td>
                        {edit ? (
                          pagename === "studentCount" ||
                            pagename === "youtubeData" ||
                            pagename === "teacherYTData" ||
                            pagename === "questionDoubt" ||
                            pagename === "studentAnalysisData" ||
                            pagename === "referenceLeadCount" ||
                            pagename === "teamReferenceCount" ||
                            isView ? (
                            <span
                              type="submit"
                              className="text-success me-2 fw-bold cursor"
                              onClick={() => handleEdit(item)}
                            >
                              View Details
                            </span>
                          ) : (
                            <span
                              type="submit"
                              className="text-primary fw-bold me-2 cursor"
                              onClick={() => handleEdit(item)}
                            >
                              Edit
                            </span>
                          )
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No record found.</td>
              </tr>
            )}
          </tbody>
        </table>
        <DeletePopup
          open={deletePopup}
          handleClose={() => setDeletePopup(false)}
          handleSubmit={() => {
            handleDelete(deleteData);
            setDeletePopup(false);
          }}
          data={deleteData.deleteDataName}
        />
      </div>
    </div>
  );
}

export default PaginationTable;
