export const CXOPanelSidebarData = [
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TOTAL REVENUE",
        path: "transactionDetails",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION PUBLICATION",
        path: "transactionpublication",
        cName: "sub-nav",
      },
      {
        title: "PAYMENT IN PUBLICATION",
        path: "paymentPublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },

      {
        title: "REVENUE PROJECTION",
        path: "RevenueProjection",
        cName: "sub-nav",
      },
      {
        title: "REVENUE ANALYSIS",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE NUMBER",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
      // {
      //   title: "ALL EXAM WISE REVENUE NUMBER GRAPH",
      //   path: "examWiseRevenue",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "cxoBatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "datewisecount",
        cName: "sub-nav",
      },
      {
        title: "SECOND INSTALLMENT AMOUNT",
        subNav: [
          {
            title: "ASSURED REVENUE",
            path: "assuredRevenue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT DUE",
            path: "secondInstallmentDue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT PAID",
            path: "secondInstallmentPaid",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },

  {
    title: "LEADS DETAILS",
    subNav: [
      {
        title: "ALL SOURCE DETAILS LEADS",
        path: "registereduser",
        cName: "sub-nav",
      },
      {
        title: "ALL SOURCE LEAD COUNTS",
        path: "allSourceLead",
        cName: "sub-nav",
      },
      {
        title: "WEBSITE LEADS",
        path: "websiteLeadCount",
        cName: "sub-nav",
      },

      {
        title: "CLASSROOM LEADS",
        path: "classroomstudents",
        cName: "sub-nav",
      },
      {
        title: "HOME PAGE LEADS",
        path: "expertsupport",
        cName: "sub-nav",
      },
      {
        title: "SOURCE ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "REFERENCE GENERATED",
        path: "referenceGenerated",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Data",
    subNav: [
      {
        title: "REFERENCE LEADS",
        path: "referenceManagerCount",
        cName: "sub-nav",
      },
      {
        title: "STATUS MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
      {
        title: "Leads Allotted Date Analysis",
        path: "teamLeadAssignedDateAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Leads Analysis",
    subNav: [
      {
        title: "LEAD GENERATION DASHBOARD",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
      {
        title: "LEADS ALLOCATIONS",
        path: "teamLeadsOverview",
        cName: "sub-nav",
      },
    ],
  },

  {
    title: "WHATSAPP MESSAGE",
    subNav: [
      {
        title: "REGISTRATION CONFIGURATION",
        subNav: [
          {
            title: "ADD",
            path: "studentCouponAdd",
            cName: "sub-nav",
          },
          {
            title: "EDIT/DELETE",
            path: "studentCouponEdit",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "POST ADMISSION CONFIGURATION",
        subNav: [
          {
            title: "ADD",
            path: "addWPConfig",
            cName: "sub-nav",
          },
          {
            title: "EDIT/DELETE",
            path: "editWPConfig",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "MESSAGE DELIVERED STATUS",
        path: "messageDeliveredStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TEACHER DETAILS",
    subNav: [
      {
        title: "TEACHER PERFORMANCE",
        path: "teacherPerformance",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN COMPLETE",
        path: "teacherTaskPlanComplete",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN PENDING",
        path: "teacherTaskPlanPending",
        cName: "sub-nav",
      },
      {
        title: "TEACHER TASK PLAN NOT COMPLETED",
        path: "teacherTaskPlanNotCompleted",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "BDE Performance",
        path: "bdePerformance",
        cName: "sub-nav",
      },
      {
        title: "Sales Performance",
        path: "salesPerformance",
        cName: "sub-nav",
      },
      {
        title: "Category Performance",
        path: "categoryPerformance",
        cName: "sub-nav",
      },
      {
        title: "Social Media Performance",
        path: "socialMediaPerformance",
        cName: "sub-nav",
      },
      {
        title: "Youtube Target Performance",
        path: "youtubeTargetPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PAYROLL",
    link: "cxoPayroll",
  },
  {
    title: "REGISTER COUNT",
    link: "registerCount",
  },

  {
    title: "BOARD MEETING",
    subNav: [
      {
        title: "EDUTECH",
        path: "boardMeetingEdutech",
        cName: "sub-nav",
      },
      {
        title: "PUBLICATION",
        path: "boardMeetingPublication",
        cName: "sub-nav",
      },
      {
        title: "LEADS",
        path: "boardMeetingLeads",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PACKAGE DETAILS",
    subNav: [
      {
        title: "EXTRA/OLD PACKAGE EXISTS",
        path: "oldPackageExist",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REPORTS",
    link: "downloadReports",
  },
  {
    title: "INFLUENCER",
    link: "getInfluencer",
  },
  {
    title: "ADD RESULT",
    link: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    link: "studentResult",
  },
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "EMPLOYEE DETAILS",
    link: "employeeDetails",
  },
];
