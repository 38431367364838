import { getUpcomingBatches, searchBatch } from "../Action/courseAction";

// import MovementsOfMeeting from "../Screen/CategoryPanel/MovementsOfMeeting/movementsOfMeeting";

export default {
  auth: {
    login: "/v1/admin/auth/login",
    logout: "/v1/admin/auth/logout",
  },
  general: {
    getState: "/v1/states",
    getCities: "/v1/cities/",
    getDurations: "/v1/durations",
    getSessionList: "/v1/admin/session/list",
    getEmployeeRoleList: "/v1/roles",
    getEmployeeCompanyList: "/v1/companies",
    getEmployeeDepartmentList: "/v1/departments",
    getEmployeeDesignationList: "/v1/designations",
  },
  /***********Super Admin Panel*********** */
  manageEmployee: {
    addEmployee: "/v1/superAdmin/employee/add-employee",
    deleteEmployee: "/v1/superAdmin/employee/delete/",
    getEmployeeList: "/v1/superAdmin/employee/get-employee",
    getEmployeeManagersList: "/v1/superAdmin/employee/managers",
    getEmployeeDetailsById: "/v1/superAdmin/employee/get-employee/",
    restoreEmployee:
      "/v1/admin/incentiveallocation/addIncentiveAllocationWithNewDate",
    updateEmployee: "/v1/superAdmin/employee/update/",
    getEmployeeSalary: "/v1/superAdmin/employee/get-employee-salary",
    addEmployeeAppraisal: "/v1/superAdmin/employee/submit-employee-appraisal",
    uploadEmployeeDocs: "/v1/superAdmin/employee/upload-file",
    employeeProfile: "/v1/superAdmin/employee/employee-Profile",
  },
  Payroll: {
    getPayrollData: "/v1/superAdmin/payroll/fetch",
    getPayrollCtc: "/v1/superAdmin/payroll/ctc",
  },
  Company: {
    addCompany: "/v1/superAdmin/company/add",
    deleteCompany: "/v1/superAdmin/company/delete/",
    getCompanyList: "/v1/superAdmin/company/list",
    updateCompany: "/v1/superAdmin/company/update/",
  },
  Department: {
    addDepartment: "/v1/superAdmin/department/add",
    deleteDepartment: "/v1/superAdmin/department/delete/",
    getDepartmentList: "/v1/superAdmin/department/list",
    updateDepartment: "/v1/superAdmin/department/update/",
  },
  subDepartment: {
    addSubDepartment: "/v1/superAdmin/department/add-sub-department",
    getSubDepartment: "/v1/superAdmin/department/fetch-sub-department",
    updateSubDepartment: "/v1/superAdmin/department/update-sub-department",
    deleteSubDepartment: "/v1/superAdmin/department/delete-sub-department",
  },
  Designation: {
    addDesignation: "/v1/superAdmin/designation/add",
    updateDesignation: "/v1/superAdmin/designation/update/",
    deleteDesignation: "/v1/superAdmin/designation/delete/",
    getDesignationList: "/v1/superAdmin/designation/list",
  },
  /*********** Admin Panel******** */
  exam: {
    createExam: "/v2/admin/exam-type/add-exam-type",
    uploadExamLogo: "/v1/admin/exam-type/upload-exam-logo",
    getExamWithState: "/v2/admin/exam-type/getList/",
    getExam: "/v1/admin/exam-type/list",
    updateExamNew: "/v2/admin/exam-type/update/",
    deleteExam: "/v1/admin/exam-type/delete/",
    getExamByStateNew: "/v2/admin/exam-type/examType-list/",
    // getEditExamCourse: "/v1/admin/exam-type/",
    getExamList: "/v2/admin/exam-type/list/",
    // getExamByState: "/v1/admin/exam-type/getList/", // API not in Used
    // addExam: "/v1/admin/exam-type/add-exam-type", // API not in Used
    // updateExam: "/v1/admin/exam-type/update/", // API not in Used
  },
  subExam: {
    addSubExam: "/v2/admin/sub-exam/add-sub-exam",
    getSubExamNew: "/v2/admin/sub-exam/list",
    deleteSubExam: "/v1/admin/sub-exam/delete/",
    updateSubExamNew: "/v2/admin/sub-exam/update/",
    getSubExamListNew: "/v1/admin/exam-type/list-parent-examType",
    getSubExam: "/v1/admin/sub-exam/list",
    // updateSubExam: "/v1/admin/sub-exam/update/", // API not in Used
    // getSubExamTeacher: "/v1/admin/sub-exam/teacher-list", // API not in Used
    // addSubExam: "/v1/admin/sub-exam/add-sub-exam", // API not in Used
  },
  examStream: {
    addExamStream: "/v1/admin/parent-course/add-parent-course",
    uploadExamStreamLogo: "/v1/admin/parent-course/upload-media",
    getExamStream: "/v1/admin/parent-course/list",
    getExamStreamByExamId: "/v2/admin/parent-course/list/",
    getExamStreamByTeacherId: "/v1/admin/parent-course/teacher-list",
    updateExamStream: "/v1/admin/parent-course/update/",
    deleteExamStream: "/v1/admin/parent-course/delete/",
    // getEditParentCourse: "/v1/admin/parent-course/", // API not in Used
  },
  language: {
    uploadLanguageLogo: "/v1/admin/language/upload-language-icon",
    addLanguage: "/v1/admin/language/add-language",
    getLanguage: "/v1/admin/language/list",
    deleteLanguage: "/v1/admin/language/delete/",
    updateLanguage: "/v1/admin/language/update/",
  },
  assignExam: {
    assignExamList: "/v2/admin/exam-type/assign-exam-name-list/",
    assignExam: "/v1/admin/exam-type/assign-exam-name",
    assignExamNew: "/v2/admin/exam-type/assign-exam-name-new",
    assignExamListParent:
      "/v1/admin/exam-type/assign-exam-name-list-basecourseid/",
    assignExamListParentTeacher:
      "/v1/admin/exam-type/assign-exam-name-list-basecourseid-teacher/",
  },
  subCategory: {
    uploadSubcategoryIcon: "/v1/admin/sub-category/upload-subcategory-icon",
    addSubCategory: "/v1/admin/sub-category/add-sub-category",
    getSubCategory: "/v1/admin/sub-category/list/",
    deleteSubCategory: "/v1/admin/sub-category/delete/",
    uploadSubcategory: "/v1/admin/sub-category/update/",
  },
  subject: {
    addSubject: "/v1/admin/subjects/add",
    getSubjectList: "/v1/admin/subjects/list",
    deleteSubjectList: "/v1/admin/subjects/delete/",
    updateSubject: "/v1/admin/subjects/update/",
    getFilteredSubjectList: "/v1/admin/subjects/list",
    // getSubjectListFromLecture: "/v1/admin/subjects/list-from-recordedLecture", // API not in Used
  },
  mainCategory: {
    addMainCategory: "/v1/admin/main-category/add-main-category",
    getMainCategory: "/v1/admin/main-category/list",
    getMainCategoryNew: "/v1/admin/main-category/get",
    getSubCategoryByMainCat:
      "/v1/admin/main-category/list-subCategory-by-mainCategory/",
    getSubCategoryByMainCatNew: "/v1/admin/main-category/list-subCategory/",
    deleteMainCategory: "/v1/admin/main-category/deleteMaincategory/",
    deleteMainCategoryNew: "/v1/admin/main-category/delete/",
    updateMainCategory: "/v1/admin/main-category/updateMaincategory/",
    updateMainCategoryNew: "/v1/admin/main-category/update/",
    getMainCategoriesById: "/v1/admin/main-category/",
  },
  section: {
    addSection: "/v2/admin/sections/add",
    getSectionList: "/v2/admin/sections/list",
    updateSection: "/v2/admin/sections/update/",
    getSectionTypeList: "/v1/admin/course/get-main-types",
    deleteSection: "/v1/admin/sections/delete/",
    // getSectionList: "/v1/admin/sections/list/", // API not in Used
    // addSection: "/v1/admin/sections/add", // API not in Used
    // updateSection: "/v1/admin/sections/update/", // API not in Used
    // addBindSection: "/v2/admin/sections/add-linked-sections",  // API not in Used
    // getNewSectionList: "/v2/admin/sections/get-new-sections-with-status-5", // API not in Used
    // getOldSectionList: "/v2/admin/sections/get-all-sections-with-status-1/", // API not in Used
  },
  assignBooks: {
    getBooksPackage: "/v1/admin/books/get-package",
    addPackage: "/v1/admin/books/add-package",
    deleteAssignBook: "/v1/admin/books/deassignBook/",
    getAssignedBookList: "/v1/admin/books/get-assigned-booklist",
  },
  package: {
    deletePackage: "/v1/admin/course/delete/",
    addCoursePackage: "/v1/admin/course/package",
    getPackageList: "/v1/admin/course/packageList",
    getPackageListWithFilter: "/v1/admin/course/packageListWithFilter",
    getPackageBasecourseList: "/v1/admin/course/getpackageName",
    updateLectureSeries: "/v1/admin/course/updateLectureSeries/",
    updateCourse: "/v1/admin/course/updateOnlineCourse/",
    updateTestSeries: "/v1/admin/course/updateTestSeries/",
    updateStudyMaterial: "/v1/admin/course/updateStudyMaterial/",
    addLecturesSPackage: "/v1/admin/course/package/5",
    addSMPackage: "/v1/admin/course/package/2",
    getCourseSubscriptionPlan: "/v1/admin/course/get-course-subscriptionPlan",
  },
  packageExtend: {
    deleteExtendedPackage: "/v1/admin/course/delete-extended-package",
    updateExtendedPackage: "/v1/admin/course/update-extended-package/",
    saveExtendedPackage: "/v1/admin/course/save-extended-package",
    getExtendedPackagesList: "/v1/admin/course/get-extended-package",
  },
  teacher: {
    addTeacher: "/v1/admin/teachers/add",
    getTeacherList: "/v1/admin/teachers/list/",
    getTeacherListAll: "/v1/admin/teachers/list",
    getTeacherListNew: "/v2/admin/teachers/list/",
    getAllTeachersList: "/v1/admin/teachers/list",
    getRemainingParentList: "/v1/admin/teachers/basecourses-list",
    getRemainingExamList: "/v1/admin/teachers/examTypes-list",
    getRemainingSubExamList: "/v1/admin/teachers/subExamTypes-list",
    getTeachersData: "/v1/admin/teachers/",
    deleteTeacher: "/v1/admin/teachers/delete/",
    updateTeacher: "/v1/admin/teachers/update/",
    pdfBBBList: "/v1/admin/video-lectures/completed-bbblist",
    uploadPDF: "/v1/admin/video-lectures/recorded/add-pdf",
  },
  assignUnits: {
    addLecturesUnit: "/v1/admin/lectures/add",
    getLecturesList: "/v1/admin/lectures/list",
    deleteLecturesUnit: "/v1/admin/lectures/delete/",
    updateLecturesUnit: "/v1/admin/lectures/update/",
  },
  adBannerType: {
    addBannerType: "/v1/admin/banner/add-banner-type",
    getBannerTypeList: "/v1/admin/banner/banner-type-list",
    deleteBannerType: "/v1/admin/banner/delete-banner-type/",
    updateBannerType: "/v1/admin/banner/update-banner-type/",
  },
  pattern: {
    addExamSection: "/v2/admin/exam-section/add",
    getExamSection: "/v2/admin/exam-section/list",
    getOldPatternList: "/v1/admin/exam-section/get-all-pattern-with-status-1",
    getNewPatternList: "/v1/admin/exam-section/get-new-pattern-with-status-5",
    addBindPatterns: "/v1/admin/exam-section/add-linked-pattern",
    getPatternList: "/v1/admin/exam-section/list-by-examType",
    getExamSectionById: "/v1/admin/exam-section/",
    updateExamSectionById: "/v1/admin/exam-section/update",
    deleteExamSectionById: "/v1/admin/exam-section/delete/",
  },
  admitCard: {},
  edutechCouponCode: {
    addCoupon: "/v1/admin/coupon/add-coupon",
    addCounsellorCouponExam: "/v1/admin/coupon/add-counselor-coupon-in-bulk",
    filterCoupon: "/v1/admin/coupon/listWithFilter",
    deleteCoupon: "/v1/admin/coupon/delete/",
    updateCoupon: "/v1/admin/coupon/update/",
    copyCoupon: "/v1/admin/coupon/copy-counselor-coupon",
  },
  publicationCouponCode: {},
  edutechCounsellorCouponCode: {
    addCounsellorCoupon: "/v1/admin/coupon/add-counselor-coupon",
    addCounsellorCouponNew: "/v2/admin/coupon/add-counselor-coupon",
    getAllCoupons: "/v1/admin/coupon/list",
  },
  counsellor: {
    getCounsellorList: "/v1/admin/counsellor/counsellor-list",
  },
  bbMeetingId: {
    addBBMeetingNew: "/v2/admin/meeting-ids/bbb/add",
    getNewBBBList: "/v2/admin/meeting-ids/get-new-meetings-with-status-5",
    getOldBBBList: "/v2/admin/meeting-ids/get-all-meetings-with-status-1",
    addBindBBBMeetings: "/v2/admin/meeting-ids/add-linked-bbb",
    getBBMeetingListNew: "/v2/admin/meeting-ids/bbb/list",
    deleteBBBMeetingNew: "/v2/admin/meeting-ids/bbb/",
    updateBBBMeetingNew: "/v2/admin/meeting-ids/bbb/",
    // addBBMeeting: "/v1/admin/meeting-ids/bbb/add",
    // getBBMeetingList: "/v1/admin/meeting-ids/bbb/list",
    // deleteBBBMeeting: "/v1/admin/meeting-ids/bbb/",
    // updateBBBMeeting: "/v1/admin/meeting-ids/bbb/",
  },
  branch: {
    getCourseDetail: "/v1/admin/course/get-course-details",
  },
  batchType: {
    addBatchType: "/v1/admin/batchtype/add-batch-type",
    getBatchTypeList: "/v1/admin/batchtype/batch-type-list",
    deleteBatchType: "/v1/admin/batchtype/delete-batch-type/",
    updateBatchType: "/v1/admin/batchtype/update-batch-type/",
  },
  courseType: {
    addCourseType: "/v1/admin/coursetype/add-course-type",
    getCourseTypeList: "/v1/admin/coursetype/course-type-list",
    deleteCourseType: "/v1/admin/coursetype/delete-course-type/",
    updateCourseType: "/v1/admin/coursetype/update-course-type/",
  },
  sopPpt: {
    uploadSopPpt: "/v1/admin/teacher-sop/upload-media",
    addSopPpt: "/v1/admin/teacher-sop/add",
    getSopPptList: "/v1/admin/teacher-sop/list",
    deleteSopPpt: "/v1/admin/teacher-sop/delete/",
  },
  registrationDetails: {
    getRegisteredUser: "/v1/admin/registerUser/getUser/",
    updateUserBaseCourse: "/v1/admin/auth/change-basecourse",
    createNewSubscription: "/v1/admin/registerUser/create/newSubscription",
    updateOldSubscription: "/v1/admin/registerUser/user-subscription-update/",
  },
  user: {
    getAllUser: "/v1/admin/registerUser/get-all-users/",
    deleteUser: "/v1/admin/registerUser/user-delete/",
    updateUser: "/v1/admin/registerUser/user-update/",
  },
  servers: {
    getServersList: "/v1/admin/aws-instance/list",
    getInstanceDetails: "/v1/admin/aws-instance/get-instance-details",
    getOTP: "/v1/admin/aws-instance/get-otp",
    updateServerStatus: "/v1/admin/aws-instance/update-instance-status",
  },
  doubtSolveReview: {
    doubtSolveReview: "/v1/admin/supports/getSupport/doubtSolveReview",
  },
  freeYoutubeLecture: {
    uploadTeacherImg: "/v1/admin/teacher-free-lecture/upload",
    addTeacherData: "/v1/admin/teacher-free-lecture/add",
    getTeacherData: "/v1/admin/teacher-free-lecture/list",
    editTeacherData: "/v1/admin/teacher-free-lecture/update/",
    deleteTeacherData: "/v1/admin/teacher-free-lecture/delete/",
  },
  categoryIncludeDescription: {
    uploadIcn: "/v1/admin/course-category-details/upload",
    addCatDesc: "/v1/admin/course-category-details/add",
    getCatDesc: "/v1/admin/course-category-details/list",
    updateCatDesc: "/v1/admin/course-category-details/update/",
    deleteCatDesc: "/v1/admin/course-category-description/delete/",
    copyCategoryInclude: "/v1/admin/course-category-details/copy",
    getCopyCategoryInclude: "/v1/admin/course-category-details/fetch",
  },
  addRecordedLectureInCourse: {
    addRecordedLectureInCourse:
      "/v1/admin/video-lectures/add-recorded-lectures-in-courses",
  },
  questionDoubt: {
    questionIssueList: "/v1/admin/exam/question-issuelist",
    updateQuestionIssue: "/v1/admin/exam/updateQuestionIssue/",
  },
  extendGlobal: {
    addExtendPackage: "/v1/admin/extend-package/add",
    listExtendPackage: "/v1/admin/extend-package/list",
    updateExtendPackage: (id) => `/v1/admin/extend-package/update/${id}`,
    deleteExtendPackage: (id) => `/v1/admin/extend-package/delete/${id}`,
  },
  upgradeGlobal: {
    addUpgradePackage: "/v1/admin/upgrade-package/add",
    listUpgradePackage: "/v1/admin/upgrade-package/list",
    updateUpgradePackage: (id) => `/v1/admin/upgrade-package/update/${id}`,
    deleteUpgradePackage: (id) => `/v1/admin/upgrade-package/delete/${id}`,
  },
  examMapping: {
    addExmMapping: "/v1/admin/parent-course/addexamtypebasecourse",
    getExmMapping: "/v1/admin/parent-course/exambindinglist",
    deleteExmMapping: "/v1/admin/parent-course/exambinddelete/",
  },
  departmentMapping: {
    addDeptMapping: "/v1/admin/parent-course/addExamStream",
    getDeptMapping: "/v1/admin/parent-course/getStreamBinding",
    deleteDeptMapping: "/v1/admin/parent-course/deleteStreamBinding/",
  },
  addOnMapping: {
    getUpdateAddOn: "/v1/admin/addOnsLinking/add-get-update",
  },
  /*********** Data Entry Panel******** */
  topic: {
    addTopic: "/v1/admin/topics/add",
    getTopicList: "/v1/admin/topics/list",
    getTopicListFromLecture: "/v1/admin/topics/list-from-recordedLecture",
    getFilteredTopicList: "/v1/admin/topics/list-name",
    deleteTopic: "/v1/admin/topics/delete/",
    updateTopic: "/v1/admin/topics/update/",
  },
  subTopic: {
    addSubTopic: "/v1/admin/sub-topics/add",
    getSubTopicList: "/v1/admin/sub-topics/list",
    getSubTopicListFromLecture:
      "/v1/admin/sub-topics/list-from-recordedLecture",
    deleteSubTopic: "/v1/admin/sub-topics/delete/",
    updateSubTopic: "/v1/admin/sub-topics/update/",
  },
  lecture: {
    addLecture: "/v1/admin/newlecture/create",
    getNewLectureList: "/v1/admin/newlecture/list",
    updateNewLecture: "/v1/admin/newlecture/update/",
    deleteNewLecture: "/v1/admin/newlecture/delete/",
  },
  liveVideo: {
    addLiveVideoLectExams: "/v1/admin/video-lectures/live/add",
    addLiveVideoLectExamsNew: "/v2/admin/video-lectures/live/add",
    addLiveVideoLectExamsNewBBB: "/v2/admin/video-lectures/livebbb/addnew",
    getLiveVideoLectExams: "/v1/admin/video-lectures/live/list",
    getRtmpUrl: "/v1/admin/video-lectures/rtmp-teacher-url",
    getLiveLectures: "/v1/admin/video-lectures/live-lectures/fetch",
    updateBatchLive: "/v1/admin/video-lectures/live-lecture/update-batch/",
    updateLiveLecture: "/v1/admin/video-lectures/live-lecture/update/",
    deleteLiveVideoLecture:
      "/v1/admin/video-lectures/delete-live-video-lecture",
  },
  premierVideo: {
    addPremVideoLectExams: "/v1/admin/video-lectures/premier/add",
    addPremVideoLectExams1: "/v1/admin/video-lectures/premier/add-new",
    getPremVideoLectExamsList: "/v1/admin/video-lectures/premier/list-fetch",
    updatePremierLecture: "/v1/admin/video-lectures/premier/update",
    deletePremierVideoLecture: "/v1/admin/video-lectures/premier/delete/",
  },
  freeUserVideo: {
    addFreeUserVideoLectExams: "/v1/admin/video-lectures/freeUser/add",
    getFreeLiveVideoLectureList: "/v1/admin/video-lectures/freeUser/fetch",
    updateFreeLiveLecture: "/v1/admin/video-lectures/freeUser/update/",
    deleteFreeLiveVideoLecture: "/v1/admin/video-lectures/freeUser/delete/",
  },
  liveRecordedVideo: {
    getVideoLectExams: "/v1/admin/video-lectures/recorded/listexam",
    addVideoLectExams: "/v1/admin/video-lectures/recorded/add-new",
    getVideoLectExamsList: "/v1/admin/video-lectures/recorded/list",
    deleteVideoLectExams: "/v2/admin/video-lectures/recorded/delete/",
    updateVideoLectExams: "/v1/admin/video-lectures/recorded/update/",
    updateBatchRec: "/v2/admin/video-lectures/courses/delete/",
  },
  bindCourses: {
    addBindCourses: "/v1/admin/course/add-linked-courses",
    getBindCoursesList: "/v1/admin/course/list-linked-courses/",
    updateBindCourses: "/v1/admin/course/update-linked-courses/",
    deleteBindCourses: "/v1/admin/course/delete-linked-courses/",
  },
  copyRecordedLecture: {
    copyRecordedLecture: "/v1/admin/copyRecordedVideo/add-copy-recorded",
    getCopyRecordedLectureList:
      "/v1/admin/copyRecordedVideo/list-copy-recorded",
    deleteCopyRecordedLecture: "/v1/admin/copyRecordedVideo/delete/",
  },
  notification: {
    addNotification: "/v1/admin/notification/add",
    getNotificationIconList: "/v1/admin/notification/icon/list",
    getNotification: "/v1/admin/notification/list",
    deleteNotification: "/v1/admin/notification/delete/",
    updateNotification: "/v1/admin/notification/update/",
  },
  assignTeacher: {
    asignTeacher: "/v1/admin/teacherTopics/assignTopic",
    getAsignedTeacherList: "/v1/admin/teacherTopics/list",
    getSubjectListWithTopics: "/v1/admin/subjects/list-with-topics/",
    deleteAssignTeacher: "/v1/admin/teacherTopics/deleteTopic/",
    updateAssignTeacher: "/v1/admin/teacherTopics/updateTopic",
  },
  examSet: {
    addDpp: "/v1/admin/exam/add-dpp",
    addUnitTest: "/v1/admin/exam/add-unit-test",
    addMockTest: "/v1/admin/exam/add-mock-test",
    addPYQ: "/v1/admin/exam/add-pyq",
    addScholarshipTest: "/v1/admin/exam/add-scholarship-test",
    uploadPyqPdf: "/v1/admin/exam/upload-pyqpdf",
    getExamSet: "/v1/admin/exam/list",
    deleteExamSet: "/v1/admin/exam/delete/",
    updateExamAdmin: "/v1/admin/exam/update",
    copyExamset: "/v1/admin/exam/copy-questions",
    changeActiveStatusExamset: "/v1/admin/exam/change-active-status",
    getExamQuestionSubjects: "/v1/admin/exam-question/subject-list/",
    getExamQuestionTopics: "/v1/admin/exam-question/topic-list",
    getExamQuestionYear: "/v1/admin/exam-question/year-list",
    getExamQuesList: "/v1/admin/exam-question/list/",
    getExamQuesListNew: "/v1/admin/exam-question/exam-list/",
    uploadMedia: "/v1/admin/exam/upload-media",
  },
  question: {
    getExamByID: "/v1/admin/exam/",
    getExamQuestion: "/v1/admin/exam-question/",
    updateQuestion: "/v1/admin/exam-question/update/",
    addQues: "/v1/admin/exam-question/add",
    deleteExamQuestion: "/v1/admin/exam-question/delete",
    uploadQueImg: "/v1/admin/exam/upload-media",
  },
  technicalSupport: {
    getAdminSupport: "/v1/admin/supports/get-support",
  },
  teacherSupport: {
    teacherIssuesList: "/v1/admin/admin-help-desk/list-issues",
  },
  raiseIssue: {
    addRaiseIssue: "/v1/admin/raiseIssue/add",
    uploadIssueImage: "/v1/admin/raiseIssue/upload-issue-image",
    raiseIssueList: "/v1/admin/raiseIssue/issue-list",
  },
  liveClassSchedule: {
    getLiveLecturesList: "/v1/admin/video-lectures/live/lectureList/fetch",
    updateLiveLecturesById:
      "/v1/admin/video-lectures/liveLecture/scheduleUpdate/",
  },
  teacherTaskPlan: {
    getTeacherTaskList: "/v1/admin/teacher-task/list",
    updateTeacherTaskStatus: "/v1/admin/teacher-task/update-status/",
    uploadTeacherTaskImage: "/v1/admin/teacher-task/upload",
  },
  teacherTaskPlanComplete: {},
  teacherTaskPlanPending: {},
  teacherTaskPlanNotCompleted: {},
  syllabus: {
    addNewSyllabus: "/v1/admin/syllabus/add",
    getSyllabusList: "/v1/admin/syllabus/list",
    getSyllabusById: "/v1/admin/syllabus/databyId/",
    editSyllabus: "/v1/admin/syllabus/update/",
    deleteSyllabus: "/v1/admin/syllabus/delete/",
  },
  copyPackage: {
    addCopyLecture: "/v1/admin/copy-course-data/add",
    getCopyLecture: "/v1/admin/copy-course-data/list",
    deleteCopyLecture: (id) => `/v1/admin/copy-course-data/delete/${id}`,
  },
  /**************** Category Panel********************* */
  leadDashboard: {
    getLeadDashboardAnalysis:
      "/v1/admin/targetDashboard/get-Studentlead-ExamStreamWise",
    getDailyTargetStudentLeadCount:
      "/v1/admin/targetDashboard/get-Studentlead-DayWiseCount",
    getWeeklyTargetStudentLeadCount:
      "/v1/admin/targetDashboard/get-Studentlead-WeekWiseCount",
    getMonthlyTargetStudentLeadCount:
      "/v1/admin/targetDashboard/get-Studentlead-YearWiseCount",
    getLeads: "/v1/get-lead-count",
    getStateLeadCount: "/v1/admin/studentsCount/getCount-statewise-web-app",
  },
  targetDashboard: {
    getLoggedInUserExamList: "/v1/examType-list",
    getLoggedInUserExamStreamList: "/v1/baseCourse-list/",
    getTargetStatusDetails: "/v1/admin/targetDashboard/getTargetStatus",
    monthWiseRevenue: "/v1/admin/admissionCount/monthly-revenue",
    shopifyRevenue: "/v1/admin/admissionCount/shopify-revenue",
  },
  youtubeAnalytics: {
    getYTAnalytics: "/v1/admin/youtube/analytics",
    getYTAnalyticsCategory: "/v1/admin/youtube/user-analytics",
  },
  admissionAnalysis: {
    getBatchwiseAdmissionDetails:
      "/v2/admin/admissionCount/get-batchwise-details/",
    // getBatchWiseCountOld: "/v1/admin/admissionCount/getBatchWiseCount",
    getBatchWiseCount: "/v2/admin/admissionCount/getBatchWiseCount",
    getDateWiseCount: "/v2/admin/admissionCount/getDateWiseCount",
    getActiveUser: "/v2/admin/admissionCount/get-count",
  },

  /************************************ */
  configuration: {
    getConfiguration: "/v1/admin/configuration/get",
    updateConfiguration: "/v1/admin/configuration/update-Data/",
    getConfigurationEmail: "/v1/admin/configSupportEmail/getEmail",
    updateConfigurationEmail: "/v1/admin/configSupportEmail/update-Email/",
    getConfigurationAdminEmail: "/v1/admin/configSupportAdminEmail/getEmail",
    updateConfigurationAdminEmail:
      "/v1/admin/configSupportAdminEmail/update-Email/",
    getAdditionalDiscount: "/v1/admin/configuration/additional-discount",
    // updateAdditionalDiscount:
    //   "/v1/admin/configuration/update-additional-discount/",
    getCountryCode: "/v1/countries",
    updateInstallment: "/v1/admin/configuration/update-installments-setting",
    getInstallment: "/v1/admin/configuration/installments-setting",
    getLiveVideoSetting: "/v1/admin/configuration/live-video-setting",
    getTaxSetting: "/v1/admin/configuration/tax-setting",
  },

  course: {
    getSubDepartment: "/v1/sub-departments",
    addBooks: "/v1/admin/books/add",
    addEBooks: "/v1/admin/books/add-ebook",
    updateBook: "/v1/admin/books/update/",
    getBooksList: "/v1/admin/books/list",
    deleteBook: "/v1/admin/books/delete/",
    uploadBookCover: "/v1/admin/books/upload-file/",
    getBatchName: "/v1/admin/video-lectures/premier/courselist",
    getBatchNameWithSessions:
      "/v1/admin/video-lectures/premier/coursesessionlist",
    getBatchList: "/v1/admin/course/list-courses-by-examType",
    addYoutube: "/v1/admin/meeting-ids/youtube/add",
    getYoutubeList: "/v1/admin/meeting-ids/youtube/list",
    youtubeMeeting: "/v1/admin/meeting-ids/youtube/",
    uploadBanner: "/v1/admin/banner/upload",
    addBanner: "/v1/admin/banner/add",
    updateExamSection: "/v1/admin/exam/update-section-in-exam/",
    updateRecordedImportVideo:
      "/v1/admin/video-lectures/recorded-import/update/",
    getLiveschedule: "/v1/admin/teachers/live/schedule",
    updateLiveSchedule: "/v1/admin/teachers/live/schedule/update",
    updateLiveFreeSchedule: "/v1/admin/teachers/free/schedule/update",
    getCourseSupportList: "/v1/admin/supports/get-support",
    getPremierVideoLectExam: "/v1/admin/video-lectures/premier/list-fetch",
    addfreelecturebanner: "/v1/admin/free-lecture/upload",
    addFreeLecture: "/v1/admin/free-lecture/add",
    addFreeLectureV2: "/v2/admin/free-lecture/add",
    getPackageListByID: "/v1/admin/course/get-for-extend/",
    getAppVersion: "/v1/admin/appversion/list",
    updateAppVersion: "/v1/admin/appversion/update/",
    getMessageList: "/v1/admin/supports/list-messages/",
    getRaiseIssueMessageList: "/v1/admin/raiseIssue/admin-message-list/",
    getRaiseIssueTeacherMessageList: "/v1/admin/teachers/issue-message-list/",
    addAdminIssue: "/v1/admin/raiseIssue/admin-send-message",
    closeRaiseIssue: "/v1/admin/raiseIssue/close-issue/",
    addTeacherIssue: "/v1/admin/teachers/send-issue-message",
    addSupportMessage: "/v1/admin/supports/add-support-message",
    uploadSupportMedia: "/v1/admin/supports/upload-file/",
    counsellorCouponList: "/v1/admin/coupon/list-counselor-coupon",
    counsellorCouponListNew: "/v2/admin/coupon/list-counselor-coupon",
    filterCouponByName: "/v1/admin/coupon/byName/",
    updateCounsellorCoupon: "/v1/admin/coupon/counselor-coupons-edit",
    // getStudentCount: "/v1/admin/studentsCount/getCount",
    getStudentCount: "/v2/admin/studentsCount/getCount",
    getWebAppLeads: "/v2/admin/studentsCount/getCount-web-app",
    getStateStudentCount: "/v1/admin/studentsCount/getCount-statewise",
    getupcomingExpiry: "/v1/admin/upcomingExpiry/list",
    getexpiredAccount: "/v1/admin/expiredAccount/list",
    getRegisteredUserList: "/v1/admin/registerUser/getUsers",
    getExpertSupportList: "/v1/auth/sale-lead",
    getBranchList: "/v1/branch/list",
    getrtmplist: "/v1/teacher-live/rtmp-list/",
    getUpcomingLiveList: "/v1/teacher-live/list",
    getBatchListFilter: "/v1/admin/batch/list-filter",
    getClassroomRegisteredList:
      "/v1/admin/registerUser/getUsers-registration-details",
    getRevenueCount: "/v1/admin/admissionCount/get-revenue",
    getExamWiseAnalysis: "/v2/admin/admissionCount/exam-wise",
    getExamWiseRevenue: "/v2/admin/admissionCount/date-wise",
    getExamResult: "/v1/admin/registerUser/exam-users-details",
    getStudyMaterialOrderList: "/v1/admin/orders/study-materials-orders",
    getPrintSMOrderList: "/v1/admin/orders/study-material-invoice",
    getBookStockList: "/v1/admin/stocks/getBookStockList",
    uploadBookReceipt: "/v1/admin/stocks/upload-file",
    addBookStock: "/v1/admin/stocks/addBookStock",
    getStockBookList: "/v1/admin/stocks/stock-book-list",
    submitStockOut: "/v1/admin/stocks/submit-stock-out",
    getStockHistory: "/v1/admin/stocks/getStockHistory/",
    getUserExamResultDetails:
      "/v1/admin/registerUser/user-exam-result-details/",
    getEdutechTransaction:
      "/v1/admin/transactions/getUsersTransactions/edutech",
    getEdutechOnlineCourse:
      "/v2/admin/transactions/getUsersTransactions/edutech",
    getEdutechTransactionNew:
      "v1/admin/transactions/getUsersTransactions/edutechforSales",
    getPublicationTransaction:
      "/v1/admin/transactions/getUsersTransactions/publications",
    getPayPublication:
      "/v1/admin/transactions/getUsersTransactions/edutechfrompublication",
    updateSMTrackingorder:
      "/v1/admin/orders/study-materials-orders-update-status",
    getSellingSourceList: "/v1/admin/stocks/get-selling-source-list",
    getUserFeedbackData: "/v1/admin/feedback/getUserFeedbackdata",
    getUserFeedbackDataByTeacher:
      "/v1/admin/feedback/getUserFeedbackdataByTeacher",
    getCourseFeedback: "/v1/admin/feedback/getCourseFeedback/",
    getLectureFeedback: "/v1/admin/feedback/getLectureFeedback/",
    getCourseTeacherFeedback: "/v1/admin/feedback/getLectureFeedbackDetails/",
    addimportRecordedvideo: "/v1/admin/video-lectures/recorded-import/add",
    getClassroomBatch: "/v1/admin/batch/list-filter",
    updateClassroomBatch: "/v1/admin/batch/update/",
    deletebatch: "/v1/admin/batch/delete/",
    addBatch: "/v1/admin/batch/add-batch",
    getAdBannerlist: "/v1/admin/banner/list",
    updateAdBanner: "/v1/admin/banner/update/",
    deleteAdBanner: "/v1/admin/banner/delete/",
    getLiveScheduleFiltered: "/v1/admin/teachers/live/scheduleDataNewForbbb",
    updateBatchPre: "/v1/admin/video-lectures/premier/update-batch/",
    updateCourseSupportResolve: "/v1/admin/supports/resolve-support/",
    getTeacherSubjectList: "/v1/admin/subjects/list-for-teacher",
    getTeacherBatchList: "/v1/admin/teachers/courses-list",
    uploadstockcover: "/v1/admin/stocks/upload-file/",
    getStockHistoryList: "/v1/admin/stocks/stocks-history",
    deleteStock: "/v1/admin/stocks/delete/",
    getTeacher: "/v1/admin/teachers/teacherlist",
    addPYQPdf: "/v1/admin/pyqPDF/add",
    uploadPyqPdfCategory: "/v1/admin/pyqPDF/upload-media",
    getPyqPdfList: "/v1/admin/pyqPDF/list",
    updatePyqPdf: "/v1/admin/pyqPDF/update/",
    deletePyqPdf: "/v1/admin/pyqPDF/delete/",
    uploadStudentPhotoVideo: "/v1/admin/exam-result/upload-file/",
    uploadExamStudentPhotoVideo: "/v1/admin/exam-results/upload-file/",
    addResults: "/v1/admin/exam-result/add",
    getYearList: "/v1/admin/exam-result/years-list",
    getMonthList: "/v1/admin/exam-result/months-list",
    getResultList: "/v1/admin/exam-result/list",
    getExamResultDetails: "/v1/admin/exam-results/get-result-details",
    replaceResultDate: "/v1/admin/exam-result/replace-date",
    copyResultList: "/v1/admin/exam-result/copy",
    deleteStudentResult: "/v1/admin/exam-result/delete/",
    deleteExamStudentResult: "/v1/admin/exam-results/delete",
    updateExamResult: "/v1/admin/exam-results/update-result-details",
    updateStudentResult: "/v1/admin/exam-result/edit/",
    updateExamStudentResult: "/v1/admin/exam-results/edit",
    addExamResults: "/v1/admin/exam-results/add",
    getExamResultsTitleDesc: "/v1/admin/exam-results/get-result-details",
    getExamYearList: "/v1/admin/exam-results/years-list",
    getExamMonthList: "/v1/admin/exam-results/months-list",
    getExamResultList: "/v1/admin/exam-results/list",
    addTeacherTask: "/v1/admin/teacher-task/add",
    getTeacherTaskRankingList: "/v1/admin/teacher-task/list-rank",
    deleteTeacherTask: "/v1/admin/teacher-task/delete/",
    updateTeacherTask: "/v1/admin/teacher-task/edit/",
    getListTeacherTask: "/v1/admin/teacher-task/list-for-teacher",
    addYoutubeRecordedVideo: "/v1/admin/video-lectures/youtubeLecture/add",
    addYoutubeRecordedVideoV2: "/v2/admin/video-lectures/youtubeLecture/add",
    getYoutubeRecordedVideoList: "/v1/admin/video-lectures/youtubeLecture/list",
    uploadYoutubeRecPdf: "/v1/admin/video-lectures/upload-file",
    getAssuredRevenue: "/v1/admin/studentsCount/installments",
    getAllReferDetails: "/v1/admin/refer-and-earn/get-all-referrals-details",
    getReferrerDetails: "/v1/admin/refer-and-earn/get-referrer-details",
    getReferPayment: "/v1/admin/refer-and-earn/get-referrer-payment-details",
    updateReferPayment:
      "/v1/admin/refer-and-earn/update-referrer-payment-details/",
    getSupportCount: "/v1/admin/supports/get-supportcount",
    getStudyMaterialTransaction:
      "/v2/admin/transactions/edutech-study-material",
  },
  teacherHours: {
    closeIssue: "/v1/admin/admin-help-desk/close-issue/",
    getTeacherHoursList: "/v1/admin/teachers/teacher-hours-list",
    addTeacherHelpDeskIssue: "/v1/admin/teacher-help-desk/add-request",
    getHelpDeskIssueList: "/v1/admin/teacher-help-desk/list-requests/",
    getTeacherHelpDeskMessagesList:
      "/v1/admin/teacher-help-desk/list-messages/",
    getTeacherAdminHelpDeskMessagesList:
      "/v1/admin/admin-help-desk/list-messages/",
    addMessage: "/v1/admin/teacher-help-desk/add-message",
    addMessageAdmin: "/v1/admin/admin-help-desk/add-message",
    addTeacherHours: "/v1/admin/teacherHours/addHours",
    editTeacherHoursList: "/v1/admin/teacherHours/list",
    deleteTeacherHoursList: "/v1/admin/teacherHours/remove/",
    updateTeacherHours: "/v1/admin/teacherHours/update/",
    addIssueType: "/v1/admin/raiseIssue/add-issue-type",
    issueTypeList: "/v1/admin/raiseIssue/list-issue-type",
    editIssueType: "/v1/admin/raiseIssue/edit-issue-type/",
    deleteIssueType: "/v1/admin/raiseIssue/delete-issue-type/",
    teacherRaiseIssueList: "/v1/admin/teachers/teacher-issue-list",
    uploadTeacherHelpdeskImage: "/v1/admin/teacher-help-desk/upload-file",
  },
  aboutExam: {
    addExamSyllabus: "/v1/admin/examSyllabus/add",
    updateExamSyllabusTitleDesc: "/v1/admin/examSyllabus/update",
    getExamSyllabusList: "/v1/admin/examSyllabus/get",
    updateExamSyllabus: "/v1/admin/examSyllabus/update/",
    deleteExamSyllabus: "/v1/admin/examSyllabus/delete/",
    addAllAboutExam: "/v1/admin/about-exam/add",
    getAllAboutExam: "/v1/admin/about-exam/list",
    updateAllAboutExam: "/v1/admin/about-exam/update/",
    addExamPaperAnalysis: "/v1/admin/exam-paper-analysis/add",
    getExamPaperAnalysis: "/v1/admin/exam-paper-analysis/list",
    updateExamPaperAnalysis: "/v1/admin/exam-paper-analysis/update/",
    getExamDatesIcons: "/v1/admin/exam-dates/get-icon",
    addExamDates: "/v1/admin/exam-dates/add",
    getExamDates: "/v1/admin/exam-dates/list",
    updateExamDates: "/v1/admin/exam-dates/update/",
    startEndLiveStatus: "/v1/admin/supports/end-user-live",
    getScholarshipExamResult: "/v1/admin/exam/scholarship-exam-results",
    updateOrder: "/v1/admin/exam-question/update-order",
  },
  Job: {
    addJobPosition: "/v1/admin/job-position/add",
    addJobTypeLogo: "/v1/admin/job-position/upload-jobposition-logo",
    getJobPosition: "/v1/admin/job-position/list",
    updateJobPosition: "/v1/admin/job-position/update/",
    deleteJobPosition: "/v1/admin/job-position/delete/",
  },
  career: {
    addCareer: "/v1/admin/career/add-career",
    getCareerList: "/v1/admin/career/get-career-list",
    getdesignations: "/v1/admin/career/get-designation-list",
    updateCareer: "/v1/admin/career/update/",
    deleteCareer: "/v1/admin/career/delete/",
  },
  infra: {
    addissue: "/v1/admin/infra-issue/add",
    issuelist: "/v1/admin/infra-issue/issue-list",
    getInfraSupportList: "/v1/admin/infra-issue/listInfra",
    infralistActiveClose: "/v1/admin/infra-issue/infralist-requests",
    issueRemark: "/v1/admin/infra-issue/issue-type/",
    issueDuration: "/v1/admin/infra-issue/issue-duration",
  },
  cutOff: {
    getCutOff: "/v1/admin/exam-paper-analysis/cutoff/list",
    updateCutOff: "/v1/admin/exam-paper-analysis/cutoff/update/",
    addCutOff: "/v1/admin/exam-paper-analysis/cutoff-analysis/add",
  },
  finance: {
    uploadMediaInvoice: "/v2/admin/invoice-management/upload-media",
    addInvoice: "/v2/admin/invoice-management/add-invoice",
    getInvoiceList: "/v2/admin/invoice-management/invoice-list",
    patchInvoice: "/v2/admin/invoice-management/update-invoice/",
    addRefund: "/v1/admin/transactions/refunded",
    getAllRefund: "/v1/admin/transactions/refund-details",
    roleList: "/v1/admin/auth/role/list",
    getCounsellorReferenceLeadDetails:
      "/v1/admin/targetDashboard/getCounsellorReferenceLeadDetails",
    getCounsellorRevenueDetails:
      "/v1/admin/targetDashboard/getCounsellorRevenueDetails",
    getCounsellorList: (id) => `/v1/admin/general/counsellor-list/${id}`,
    getIncentiveTargetDetails:
      "/v1/admin/targetDashboard/getIncentiveTargetDetails",
    // getInvoice: "/v2/admin/invoice-management/invoice-list/",
  },
  seo: {
    addExamTypeSEO: "/v1/admin/examType-seo/add",
    getExamTypeListSEO: "/v1/admin/examType-seo/list",
    patchExamTypeSEO: (id) => `/v1/admin/examType-seo/edit/${id}`,
    deleteExamTypeSEO: (id) => `/v1/admin/examType-seo/delete/${id}`,

    addCourseSEO: `/v1/admin/courseType-seo/add`,
    getCourseListSEO: "/v1/admin/courseType-seo/list",
    patchCourseSEO: (id) => `/v1/admin/courseType-seo/edit/${id}`,
    deleteCourseSEO: (id) => `/v1/admin/courseType-seo/delete/${id}`,

    addExamStreamSEO: "/v1/admin/baseCourse-seo/add",
    getExamStreamListSEO: "/v1/admin/baseCourse-seo/list",
    patchExamStreamSEO: (id) => `/v1/admin/baseCourse-seo/edit/${id}`,
    deleteExamStreamSEO: (id) => `/v1/admin/baseCourse-seo/delete/${id}`,

    addFAQType: "/v1/admin/courseFAQ/add-faq-type",
    getFAQType: "/v1/admin/courseFAQ/list-faq-type",
    addFAQ: "/v1/admin/courseFAQ/add",
    getFAQList: "/v1/admin/courseFAQ/list",
    updateFAQ: "/v1/admin/courseFAQ/edit/",
    deleteFAQ: "/v1/admin/courseFAQ/delete/",

    addProductSchema: "/v1/admin/product-schema/add",
    getProductSchema: "/v1/admin/product-schema/list",
    updateProductSchema: "/v1/admin/product-schema/edit/",
    deleteProductSchema: (id) => `/v1/admin/product-schema/delete/${id}`,

    setUploadAuthorImage: "/v1/admin/author/upload",
    addAuthor: "/v1/admin/author/add",
    getAuthor: "/v1/admin/author/get-list",
    updateAuthorDetails: "/v1/admin/author/update-author/",
    deleteAuthor: (id) => `/v1/admin/author/delete-author/${id}`,

    // getResourcePage: "/v1/admin/resourceSeo/get-resource-pages", Old API replaced with NEw
    getResourcePage: "/v1/admin/resourcePageDetails/resource-pages",

    addResourceSEO: "/v1/admin/resourceSeo/add",
    getResourceSEO: "/v1/admin/resourceSeo/get",

    getOnlineCoachingDetail: "/v1/admin/onlineCoaching/get",
    addOnlineCoachingDetail: "/v1/admin/onlineCoaching/add-update",

    getResourceContent: "/v1/admin/resourceContent/get",
    addResourceContent: "/v1/admin/resourceContent/add",

    getAddExamTypeSEO: "/v1/admin/examType-seo/get-add-update",
    getAddSubExamTypeSEO: "/v1/admin/examType-seo/get-add-update",
    getAddExamStreamTypeSEO: "/v1/admin/baseCourse-seo/get-add-update",

    uploadSEOBlogsBanner: "/v1/admin/blog/uploadImage",
    addSEOBlogs: "/v1/admin/blog/addBlog",
    getSEOBlogs: "/v1/admin/blog/getBlog",
    updateSEOBlogs: "/v1/admin/blog/updateBlog/",
    deleteSEOBlogs: "/v1/admin/blog/deleteBlog/",

    addResourseFAQ: "/v1/admin/resourceFAQ/add",
    getResourseFAQList: "/v1/admin/resourceFAQ/get",
    updateResourseFAQ: "/v1/admin/resourceFAQ/update/",
    deleteResourseFAQ: "/v1/admin/resourceFAQ/delete/",

    getResourceProduct: "/v1/admin/product-schema/listResoursesSchema",
    addResourceProduct: "/v1/admin/product-schema/Resourses-schema/add",
    updateResourceProduct: "/v1/admin/product-schema/editResoursesSchema/",
    uploadResourceProductImg: "/v1/admin/product-schema/uploadResoursesleImage",

    addSEOExam: "v1/admin/pageDetails/add",
    getListSEOExam: "/v1/admin/pageDetails/get",
    updateSEOExam: "/v1/admin/pageDetails/update/",
    getResourcePageDetail: "/v1/admin/resourcePageDetails/add-get-update",

    getTeacherByExam: "/v1/teachers",
    uploadExamBanner: "/v1/admin/examType-details/upload",
    addExamDetails: "/v1/admin/examType-details/add",
    getExamDetails: "/v1/admin/examType-details/list",
    updateExamDetails: "/v1/admin/examType-details/update/",
    deleteExamDetails: (id) => `/v1/admin/examType-details/delete/${id}`,
  },

  questionBank: {
    questionConfigurationAdd: "/v1/admin/question-configuration/create",
    questionConfigurationList: "/v1/admin/question-configuration/get",
    questionConfigurationUpdate: (id) =>
      `/v1/admin/question-configuration/update/${id}`,
    questionConfigurationDelete: (id) =>
      `/v1/admin/question-configuration/delete/${id}`,
    addQuestionInBank: "/v1/admin/question-bank/add",
    // addQuestionInBank: "/v2/admin/question-bank/add",
    updateQuestionBank: (id) => `/v1/admin/question-bank/update/${id}`,
    deleteQuestionBank: (id) => `/v1/admin/question-bank/delete/${id}`,
    listQuestionInBank: "/v1/admin/question-bank/list",
    getFoldersLocations: "/v1/admin/aws-instance/get-folders-locations",
    awsURLCreate: "/v1/admin/aws-instance/s3-url",
    uploadTeacherHandOut: `/v1/admin/teachers/upload-file`,
    createDpp: "/v1/admin/exam/add-dpp-by-teacher",
    createDppRecorded: "/v1/admin/exam/add-dpp-by-teacher-for-recorded",
    addRecordedVideoFromTeacher: "/v1/admin/video-lectures/recorded/add-bulk",
    getDataWithOutDpp: "/v1/admin/video-lectures/get-live-lecture-details",
    getCompleteList: "/v1/admin/video-lectures/completed-list",
    updateStatusLiveToRecorded:
      "/v1/admin/video-lectures/update-live-to-recorded-status",
    updateLectureTitle: "/v1/admin/video-lectures/update-lecture-title",
    updateLectureId: "/v1/admin/question-bank/updatenewLectureId",
    assignQuestion: "/v1/admin/exam/assign-questions",
  },
  webinar: {
    getCollegeList: "/v1/admin/generalUserDetails/college-name-list",
    getWebinarList: "/v1/admin/generalUserDetails/list-webinar",
  },
  paidAds: {
    getPaidAdsList: "/v1/admin/generalUserDetails/listPaidAds",
  },
  socialMediaAds: {
    getSocialMediaAd: "/v1/admin/generalUserDetails/listSocialMediaUsers",
    addSocialMediaLinks: "/v1/admin/socialMediaLink/add",
    getSocailMediaLinkList: "/v1/admin/socialMediaLink/get",
    updateSocialMediaLink: "/v1/admin/socialMediaLink/update/",
    // updateSocialMediaLink: (id) => `/v1/admin/socialMediaLink/update/${id}`,
  },
  config_coupon: {
    addConfigCoupon: "/v1/admin/coupon-config/add",
    getConfigCoupon: "/v1/admin/coupon-config/list",
    editConfigCoupon: (id) => `/v1/admin/coupon-config/update/${id}`,
    deleteConfigCoupon: `/v1/admin/coupon-config/delete/`,
    uploadConfigCouponFile: "/v1/admin/coupon-config/upload-file",
  },
  analysis: {
    sourceAnalysis: "/v2/admin/admissionCount/source-wise",
    stateWiseAnalysis: "/v2/admin/admissionCount/state-wise",
  },
  crm: {
    getTotalLeadCountByDate: "/v1/admin/leads/getTotalLeadCountByDate",
    getManagerList: "/v1/admin/auth/manager-list/",
    getEmployeetByManagerId: "/v1/admin/teamAnalysis/employee-list/",
    getTeamLeadAssignedOverview: "/v1/admin/leads/getTl-newLeads-Details/",
    getCounsellorLeadAssignedOverview:
      "/v1/admin/leads/getCounsellor-newLeads-Details/",
    getLeadAssignedOverviewAnalysis: "/v1/admin/leads/overall-lead-Details/",
    getSourceTypeList: "/v1/admin/auth/source-type/list",
    getStatusList: "/v1/admin/auth/lead-status/list",
    getAssignedStudentLeadDetails:
      "/v1/admin/teamAnalysis/get-leadAnalysis/studentslead-details",
    getStudentDetailsById: "/v1/admin/lead-analysis/get/",
    getCallHistory: "/v1/admin/lead-analysis/getCallHistory",
    getLeadPaymentHistory: "/v1/admin/teamAnalysis/order-history",
    addManagerRemark: "/v1/admin/lead-analysis/addReamrk/",
    getTotalLeadCount: "/v1/admin/leads/getLeadCount",
    getLeadCountExamWise: "/v1/admin/leads/getLeadCountExamWise",
    getLeadCountStateWise: "/v1/admin/leads/getLeadCountStateWise",
    getDailyMeetingForManager:
      "/v1/admin/superManagerDashboard/get-manager-records",
    getReferenceAnalysisSuperManager:
      "/v1/admin/superManagerDashboard/referenceAnalysisForSuperManager",
    getReferenceAnalysisManager:
      "/v1/admin/superManagerDashboard/referenceAnalysisForManager",
    getReferencesTeamLeadList: "/v1/admin/teamAnalysis/reference-analysis",
    getRevenueLeadsAnalysisList:
      "/v1/admin/revenue-analysis/assigned-leads-revenue-between-dates",
    getLeadOrdersSearch: "/v1/admin/Leads/get-all-users/",
    addLeadInCRM: "/v1/admin/Leads/addLeads/",
    addLeadOrderInCRM: "/v1/admin/order/crm/insert-lead-order",
    checkOrderStatus: "/v1/orders/check-status/",
    updateOrderStatus: "/v1/admin/orders/update-payment-order-status/",
    getSMManagerList: "/v1/admin/superManagerDashboard/get-managerList/",
  },
  bde: {
    getBDETargetDetailsList:
      "/v1/bdeTeamlead/targetDashboard/get-BDEtarget-Details",
  },
  youtubeSession: {
    addYTSession: "/v1/admin/youTubeSession/add-youtube-session",
    uploadYoutubeReferredImage: "/v1/admin/youTubeSession/upload-file",
    getDuplicateYoutube: "/v1/admin/youTubeSession/getDuplicateList",
    getYTSession: "/v1/admin/youTubeSession/get-youtube-session-list",
    getYTLectureList: "/v1/admin/youTubeSession/get-youtube-lecture-list/",
    getYTLectureDetail: "/v1/admin/youTubeSession/get-youtube-lecture-details/",
    updateYTLectureDetail:
      "/v1/admin/youTubeSession/update-youtube-lecture-details/",
    addYTLecture: "/v1/admin/youTubeSession/add-youtube-lecture/",
    deleteYTLectureDetail: "/v1/admin/youTubeSession/delete-youtube-lecture/",
  },
  whatsAppConfig: {
    getWhatsAppRole: "/v1/get-crm-roles",
    addWhatsappConfig: "/v1/admin/crm-cron-config/add",
    updateWhatsappConfig: "/v1/admin/crm-cron-config/update/",
    deleteWhatsappConfig: "/v1/admin/crm-cron-config/delete/",
    getWhatsappConfig: "/v1/admin/crm-cron-config/list",
  },
  examShotVideo: {
    uploadFileShort: "/v1/admin/shortvideos/upload-file",
    addShotVideo: "/v1/admin/shortvideos/add",
    getShotVideo: "/v1/admin/shortvideos/list",
    updateShotVideo: "/v1/admin/shortvideos/update/",
    deleteShotVideo: "/v1/admin/shortvideos/delete/",
  },
  studentAnalysis: {
    getStudentList: "/v1/admin/exam/studentAnalysisListByCourseId",
    getStudentDetails: "/v1/admin/exam/StudentAnalysisDetails",
  },
  academicHead: {
    addAcademicHead: "/v1/admin/academicHead/add",
    getAcademicHeadList: "/v1/admin/academicHead/get",
    getAcademicHeadDetail: (id) => `/v1/admin/academicHead/get/${id}`,
    deleteAcademicHeadDetail: (id) => `/v1/admin/academicHead/delete/${id}`,
    updateAcademicHeadDetail: (id) => `/v1/admin/academicHead/update/${id}`,
    getLeadCount: "/v1/academicHead/get-lead-count",
    getExamList: "/v1/academicHead/get-examType-list",
    getSubExamList: (id) => `/v1/academicHead/get-subExamType-list/${id}`,
    getBasecourse: "/v1/academicHead/get-basecourse-list",
    getTeacherList: "/v1/academicHead/get-teachers-list",
  },
  oldPackageExist: {
    getOldPackageExist: "/v1/admin/course/get/activeCoursesList",
  },
  msgDeliveredStatus: {
    getMsgDeliveredStatus: "/v1/admin/whatsApp-message/list",
  },
  whatsAppMsg: {
    sendWhatsAppMsg: "/v1/admin/whatsApp-message/send-whatsApp-msg-manually",
  },
  transactionDetails: {
    getTransactionDetails:
      "/v1/admin/transactions/getUsersTransactions/transaction-details",
  },
  questionIssues: {
    getQuestionIssue: "/v1/admin/exam/question-issuelist",
    questionDoubtUpdate: "/v1/admin/exam/updateQuestionDoubt/",
  },
  cxo: {
    getUserTransactionDetails:
      "/v1/admin/transactions/getUsersTransactions/datewise-transaction-details",
    gettransactionforecast:
      "/v1/admin/transactions/getUsersTransactions/transaction-forecast",
    getMonthlyRevenue:
      "/v1/admin/transactions/getUsersTransactions/monthly-revenue",
  },
  sourceAnalysisLead: {
    getLeadCountSource: "/v2/admin/studentsCount/get-datewise-count",
  },
  IncentiveAllocation: {
    getIncentiveAllocationList:
      "/v1/admin/incentiveallocation/incentiveAllocationList",
    addIncentiveTarget: "/v1/admin/incentiveallocation/addincentiveAllocation",
    addIncentiveTargetNewDate:
      "/v1/admin/incentiveallocation/addIncentiveAllocationWithNewDate",
    updateIncentiveTarget: "/v1/admin/incentiveallocation/update/",
    deleteIncentiveTarget: "/v1/admin/incentiveallocation/delete/",
  },
  questionIssueTypes: {
    addQuestionIssueType: "/v1/users/exam/createissueType",
    getQuestionIssueType: "/v1/users/questiontypelist",
    updateQuestionIssueType: "/v1/users/update/issueType/",
    deleteQuestionIssueType: "/v1/users/delete/",
  },
  mapping: {
    getMappingList: "/v1/mapping",
  },
  referenceGenerated: {
    // getReferenceGenerated: "/v1/admin/resourceContent/get-referenceGenerated", //OLD
    getReferenceGenerated: "/v2/admin/studentsCount/get-reference-generated", //NEW
  },
  resultCompilation: {
    getVerifyStudentNumber: "/v1/resultCompilation/verify-student/",
    getCasteCategoryList: "/v1/resultCompilation/caste-catagory-list",
    uploadResultImage: "/v1/resultCompilation/upload-file/",
    addResultCompilation: "/v1/resultCompilation/add",
    getResultCompilation: "/v1/resultCompilation/get",
    addUpdateResultCompilation: "/v1/resultCompilation/remark-comment/",
    importResultCompilation: "/v1/resultCompilation/import-result",
    updateResultCompilation: "/v1/resultCompilation/update-result/",
    uploadStudentImg: "/v1/resultCompilation/upload-file/",
  },
  RegisteredLead: {
    getAddUpdateRegisterCount: "/v1/admin/registeredLead/get-add-update",
  },
  dispatchCharges: {
    getDispatchCharges: "/v1/admin/books/getDispatchCharges/get",
    addDispatchCharges: "/v1/admin/books/addDispatchCharges",
    updateDispatchCharges: "v1/admin/books/updateDispatchCharges",
    deleteDispatchCharges: "/v1/admin/books/deleteDispatch",
  },
  testDiscussionVideo: {
    updateTestDiscussionVideo: "/v1/admin/teacher-task/update-exam-set/",
  },
  boardMeeting: {
    getBoardMeetingEdutech: "/v1/admin/admissionCount/get-Revenue-SourceWise",
    getBoardMeetingPublication:
      "/v1/admin/admissionCount/get-Revenue-SourceWise-ForPublication",
    getBoardMeetingLead: "/v1/cxo/lead-performance",
  },
  cxoPerformance: {
    targetPerformance: "/v1/cxo/target-performance",
    salesPerformance: "/v1/cxo/sale-performance",
    youtubeTargetPerformance: "/v1/admin/youtubeTarget/get-Youtube-Performance",
  },
  youtubeTarget: {
    getChannelList:
      "/v1/admin/YoutubeIncentiveTarget/get-Youtube-ChannelDetails",
    getYoutubeTarget: "/v1/admin/YoutubeIncentiveTarget/get-Youtube-Target",
    getTargetStatusYoutube: "/v1/admin/youtubeTarget/get-Youtube-Target",
    addYoutubeTarget:
      "/v1/admin/YoutubeIncentiveTarget/add-Youtube-IncentiveTarget",
    addYoutubeTargetNewDate:
      "/v1/admin/YoutubeIncentiveTarget/addYoutubeIncentiveTargetWithNewDate",
    updateYoutubeTarget: "/v1/admin/YoutubeIncentiveTarget/update/",
    deleteYoutubeTarget: "/v1/admin/YoutubeIncentiveTarget/delete/",
  },
  cxoreports: {
    getResports:
      "/v1/admin/transactions/getUsersTransactions/transaction-all-data",
    addTransactionEdutechReports:
      "/v1/admin/transactions/SaveTransactionEdutech",
    addTransactionEdutechExtendedReports:
      "/v1/admin/transactions/SaveTransactionEdutechExtended",
    addTransactionEdutechUpgradeReports:
      "/v1/admin/transactions/SaveTransactionEdutechUpgrade",
    addTransactionStudyMaterialReports:
      "/v1/admin/transactions/SaveTransactionStudyMaterial",
    addTransactionEdutechExtendedandUpgradeReports:
      "/v1/admin/transactions/SaveTransactionEdutechExtendedandUpgrade",
    addTransactionPublicationReports:
      "/v1/admin/transactions/SaveTransactionPublication",
  },
  whatsappShootData: {
    getWhatsappShootData: "/v1/admin/registerUser/whtasapp-shoot-data",
  },
  /*********** SM Team Panel******** */
  youtubeLive: {
    addFreeLectureV2: "/v2/admin/free-lecture/add",
    getFreeLecture: "/v1/admin/free-lecture/list",
    deleteFreeLecture: "/v1/admin/free-lecture/delete/",
    uploadYoutubeIcon: "/v1/admin/free-lecture/upload",
    updateYoutubeLecture: "/v1/admin/free-lecture/update/",
    uploadYoutubeRecPdf: "/v1/admin/video-lectures/upload-file",
  },
  youtubeRecorded: {
    addYoutubeRecordedVideoV2: "/v2/admin/video-lectures/youtubeLecture/add",
    getYoutubeRecordedVideoList: "/v1/admin/video-lectures/youtubeLecture/list",
  },
  primeClass: {
    updateBatchPre: "/v1/admin/video-lectures/premier/update-batch/",
  },
  asset: {
    getAsset: "/v1/admin/assets/get-assets",
    addAsset: "/v1/admin/assets/add-assets",
    updateAsset: "/v1/admin/assets/update/",
  },
  employeeAsset: {
    getEmployeeDetails: "/v1/admin/employeeAssets/employee-details/",
    getAssetDetailByBarcode: "/v1/admin/employeeAssets/devices/",
    getEmployeeAsset: "/v1/admin/infraAssets/employee-details/", //get
    addEmployeeAsset: "/v1/admin/infraAssets/assign-assets", //post
    getEmployeeAssetList: "/v1/admin/infraAssets/assets-list", //post
    deleteEmployeeAsset: "/v1/admin/infraAssets/assets-remove/", //delete
    updateEmployeeAsset: "/v1/admin/infraAssets/assets-update/", //delete
    addUpdateEmployeeAsset: "/v1/admin/employeeAssets/employee-devices",
    getEmployeeOtp: "/v1/auth/employee/get-otp",
    verifyEmployeeOtp: "/v1/auth/employee/verify-otp",
    getAllEmployeeAssetList: "/v1/admin/employeeAssets/device-list",
    getMyAssetsList: "/v1/admin/employeeAssets/get-assets-list",
  },
  orderInvoice: {
    getOrderInvoice: "/v1/orders/get-order-invoice",
  },
  callToExpertSupport: {
    getCallToExpertSupportList: "/v1/admin/technicalSupport/expert-sale-lead",
    getCallToExpertSupportRemarkList:
      "/v1/admin/technicalSupport/getRemarkList/",
    addExpertSupportRemark: "/v1/admin/technicalSupport/add-Remark",
  },
  youtubePoll: {
    getYoutubeLiveLectures:
      "/v1/admin/youTubePollIntegration/get-live-lectures",
    addYoutubeQuestionPoll:
      "/v1/admin/youTubePollIntegration/add-Youtube-Poll/",
    getQuestionPollsList:
      "/v1/admin/youTubePollIntegration/get-all-youtube-polls",
    updateQuestionPollsList:
      "/v1/admin/youTubePollIntegration/update-Youtube-Poll/",
    deleteYoutubeQuestionPoll:
      "/v1/admin/youTubePollIntegration/delete-Youtube-Poll/",
    getUpcomingQuestionPollLectures: (lectureId) =>
      `/v1/admin/youTubePollIntegration/getPollsByLectureId/${lectureId}`,
    startYoutubePoll: "/v1/admin/youTubePollIntegration/start-Youtube-Poll/",
    getUpcomingYoutubePoll:
      "/v1/admin/youTubePollIntegration/getUpcomingLectureWithItsPolls/",
  },
  inventoryManagement: {
    uploadInvoice: "/v1/admin/InfraAssets/upload-invoice",
    uploadDeviceImage: "/v1/admin/InfraAssets/upload-image",
    addDevice: "/v1/admin/infraAssets/add-device",
    getDevice: "/v1/admin/infraAssets/get-device",
    getDeviceById: "/v1/admin/infraAssets/get-device/",
    updateDeviceId: "/v1/admin/infraAssets/update-device/",
    importDevices : "/v1/admin/infraAssets/import-devices",
  },
  deviceCompany: {
    addDeviceCompany: "/v1/admin/devices/add-company",
    getDeviceCompany: "/v1/admin/devices/get-company",
    updateDeviceCompany: "/v1/admin/devices/update-company/",
    deleteDeviceCompany: "/v1/admin/devices/delete-company/",
  },
  deviceModel: {
    addDeviceModel: "/v1/admin/devices/add-model",
    getDeviceModel: "/v1/admin/devices/get-model",
    updateDeviceModel: "/v1/admin/devices/update-model/",
    deleteDeviceModel: "/v1/admin/devices/delete-model/",
  },
  deviceSeries: {
    getDeviceSeries: "/v1/admin/devices/get-serial-numbers",
  },

  baseOfferDiscount: {
    addBaseOfferDiscount: "/v1/admin/coupon-config/base-offer-discount/add",
    getBaseOfferDiscount: "/v1/admin/coupon-config/base-offer-discount/get",
    updateBaseOfferDiscount:
      "/v1/admin/coupon-config/base-offer-discount/update",
  },
  influencer: {
    addInfluencer: "/v1/admin/influencer/add",
    getInfluencer: "/v1/admin/influencer/get",
    updateInfluencer: "/v1/admin/influencer/update/",
    deleteInfluencer: "/v1/admin/influencer/delete/",
    activeInfluencer: "/v1/admin/influencer/list",
    baseCourseList: "/v1/base-courses",
    getCategoryList: "/v1/admin/influencer/categoryList",
    uploadAgreement: "/v1/admin/influencer/upload-file",
    getGuidelineAgreement: "/v1/admin/influencer/get-details",
    getAllInfluencer: "/v1/admin/influencer/list-inluencer",
    fetchInfluencerPay: "/v1/admin/refer-and-earn/get-influencer-payments",
    updateInfluencerPay: "/v1/admin/refer-and-earn/update-influencer-payments",
    getInfluencerPayment: "/v1/admin/influencer/influencer-payment",
  },

  competitorAnalysis: {
    competitorList: "/v1/admin/competitorAnalysis/list-competitor",
    addCompetitorVideo: "/v1/admin/competitorAnalysis/add-video",
    getCompetitorVideo: "/v1/admin/competitorAnalysis/analysis-video",
    addCompetitorName: "/v1/admin/competitorAnalysis/add-competitor",
    updateCompetitorVideo: (id) =>
      `/v1/admin/competitorAnalysis/update-video/${id}`,
  },
  facebookGroup: {
    getFacebookGroup: "/v1/admin/socialMedia/fb/get-group",
    addFacebookGroup: "/v1/admin/socialMedia/fb/add-group",
    updateFacebookGroup: "/v1/admin/socialMedia/fb/update-group/",
    deleteFacebookGroup: "/v1/admin/socialMedia/fb/delete-group/",
    fetchFacebookGroup: "/v1/admin/socialMedia/fb/fetch-groups",
    approveFacebookGroup: "/v1/admin/socialMedia/fb/approve-group/",
  },

  upcomingBatch: {
    addUpcomingBatch: "/v1/admin/batch/add-upcoming-batch",
    dateSearchBatch: "/v1/admin/batch/upcoming-batch-analysis",
    listUpcomingBatches: "/v1/admin/batch/list-upcoming-batches",
    getUpcomingBatches: "/v1/admin/batch/get-upcoming-batch/",
    updateBatches: "/v1/admin/batch/update-upcoming-batch/",
    BatchaAnalisis: "/v1/admin/batch/upcoming-batch-analysis",
  },
  importQuestions: {
    importQuestionBank: "/v1/admin/question-bank/import-questionBank",
  },
  dummyAccount: {
    getDummyAccData: "/v1/admin/socialMedia/get-account",
    addDummyAccData: "/v1/admin/socialMedia/add-account",
    updateDummyAccData: "/v1/admin/socialMedia/update-account/",
    deleteDummyAccData: "/v1/admin/socialMedia/delete-account/",
  },
  basePrice: {
    addBasePrice: "/v1/admin/PackageBasePrice/add",
    basePriceList: "/v1/admin/PackageBasePrice/list",
    getBasePrice: (id) => `/v1/admin/PackageBasePrice/get/${id}`,
    updateBasePrice: (id) => `/v1/admin/PackageBasePrice/update/${id}`,
  },
  employeeList: {
    getAllEmployeeList: "/v1/employee-list/",
  },
  incentiveRevenue: {
    getIncentiveRevenue:
      "/v1/admin/transactions/getUsersTransactions/getTransactionReview",
  },
  fixedDiscount: {
    addFixedDiscount: "/v1/admin/OfferedPrice/add",
    getFixedDiscount: "/v1/admin/OfferedPrice/list",
    getFixedDiscountId: "/v1/admin/OfferedPrice/get/",
    updateFixedDiscount: "/v1/admin/OfferedPrice/update/",
  },
  influencerRevenue: {
    getInfluencerRevenue: "/v1/admin/influencer/get-influencer-revenue",
  },
  influencerRank: {
    getInfluencerRank: "/v1/admin/influencer/rank-wise-revenue",
  },
  influencerDashboard: {
    getDashboardData: "/v1/admin/influencer/dashboard",
  },
  influencerExamWise: {
    influencerExamRevenue: "/v1/admin/influencer/exam-wise-revenue",
  },

  MovementsOfMeeting: {
    fetchDepartmentEmployees: "/v1/cxo/meeting-members",
    addMeetingDetails: "/v1/cxo/add-meeting-details",
    getMeetingDetails: "/v1/admin/mom/get-meeting-details",
    updateMeetingDetails: "/v1/cxo/update-meeting-task/",
  },
  kraKpi: {
    getKraKpi: "/v1/superAdmin/krakpi/fetch",
    addUpdateKraKpi: "/v1/superAdmin/krakpi/add-update",
    getUserKraKpi: "/v1/superAdmin/kraKpi/krakpibyId",
    getUserPolicy: "/v1/superAdmin/kraKpi/policybyId",
    uploadPolicyMedia: "/v1/superAdmin/kraKpi/upload-file",
    getAddUpdateUserPolicy: "/v1/superAdmin/kraKpi/get-add-update-policy",
  },
  aboutUs: {
    aboutCompanyContent: "/v1/admin/aboutCompanyContent/addContent",
    getAboutCompanyContent: "/v1/admin/aboutCompanyContent/getContent",
  },
  youtubeSeoTracking: {
    getLectureWiseList: "/v1/admin/youTubeSession/get-lecture-wise-list",
    getLectureWiseListById: "/v1/admin/youTubeSession/get-lecture/",
    updateLectureWiseList: "/v1/admin/youTubeSession/update-lecture/",
    uploadLectureWiseImg: "/v1/admin/youTubeSession/upload-image",
  },
  sellingSource: {
    addSellingSource: "/v1/admin/sellingSource/add",
    getSellingSource: "/v1/admin/sellingSource/list",
    getSellingSourceById: "/v1/admin/sellingSource/get/",
    updateSellingSource: "/v1/admin/sellingSource/update/",
    deleteSellingSource: "/v1/admin/sellingSource/delete/",
  },
  contactUs: {
    addContactUs: "/v1/admin/contactUs/addContent",
    getContactUs: "/v1/admin/contactUs/getContent",
    uploadContactImg: "/v1/admin/contactUs/upload",
  },
  tds: {
    addTds: "/v1/admin/tds/add",
    getTds: "/v1/admin/tds/getTds",
    updateTds: "/v1/admin/tds/updateTds/",
    deleteTds: "/v1/admin/tds/deleteTds/",
  },
  publicationvendor: {
    addVender: "/v1/admin/vendor/add-vendor",
    getVenderList: "/v1/admin/vendor/list-vendor",
    getVenderListById: "/v1/admin/vendor/get-vendor/",
    updateVendor: "/v1/admin/vendor/update-vendor/",
    deleteVendor: "/v1/admin/vendor/delete-vendor/",
  },
  publicationStock: {
    scanBarCodeStock: "/v1/admin/publication/scan-boook/",
    updatePublicationStock: "/v1/admin/publication/update-stock",
    uploadStockMedia: "/v1/admin/publication/uploadMedia",
    getDamagedBook: "/v1/admin/publication/damage-book",
    getPubStockHistoryList: "/v1/admin/publication/book-history",
    getStockAnalysis: "/v1/admin/publication/stock-analysis",
    getCXOPubBookHistory:"/v1/admin/publication/book-order-history",
    CxoPubSourceAnalysis:"/v1/admin/publication/selling-source-wise-revenue"
  },
  oldUrlRedirection: {
    addurl: "/v1/admin/seoUrl/add",
    getOldUrlList: "/v1/admin/seoUrl/get",
    uploadOldUrlList: "/v1/admin/seoUrl/update/",
    searchOldUrl: "/v1/admin/seoUrl/getSeoUrlByKey",
    deleteOldUrl: "/v1/admin/seoUrl/delete/",
  },
  onboardingForm: {
    getOnboardingList: "/v1/superAdmin/employee/get-OnboardingEmployee",
    updateOnBoardingList: "/v1/superAdmin/employee/onboard-employee",
  },
};
