/* eslint-disable react/prop-types */
import React from "react";

function InputField({
  labelName,
  id,
  placeholder,
  value,
  setInputText,
  Isrequired,
  maxlength,
  minlength,
  disabled,
  defaultValue,
  styling,
}) {
  const onChange = (e) => {
    setInputText(allowOnlyOneSpace(e.target.value));
  };

  const allowOnlyOneSpace = (str) => {
    return str.endsWith(" ") ? str.trim() + " " : str.trim();
  };
  return (
    <div className={styling ? styling : "d-flex mb-2 align-items-center"}>
      {labelName ? (
        <div className="d-flex mb-2">
          <label className="label">
            {labelName}
            {styling ? (
              ""
            ) : (
              <>
                {Isrequired ? (
                  <span className="required ms-1 fs-6">*</span>
                ) : null}
              </>
            )}
          </label>
        </div>
      ) : null}

      <input
        id={id}
        type="text"
        placeholder={placeholder}
        value={value}
        className={styling ? "form-control" : "form-control inputField m-0"}
        onChange={(e) => onChange(e)}
        required={Isrequired}
        maxLength={maxlength}
        minLength={minlength}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default InputField;
