import React, { useEffect, useState } from "react";
import PageTitle from "../../Module/pageTitle";
import LabelField from "../../Component/labelField";
import Dropdown from "../../Component/dropdown";
import SubmitButton from "../../Component/SubmitButton";
import { monthData, yearData } from "../../Utils/dataConstants";
import { useDispatch } from "react-redux";
import { getCompanyList, getPayrollCtc } from "../../Action/courseAction";

const PayRollCtc = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    year: yearData.find((item) => item.value === new Date().getFullYear()),
    startMonth: monthData.find(
      (item) => item.value === new Date().getMonth() + 1
    ),
    endMonth: monthData.find(
      (item) => item.value === new Date().getMonth() + 1
    ),
    company: "",
    companyList: [],
    payRollData: {
      companyDetails: {},
      data: [],
    },
  });
  const updateState = async (key) => {
    await setState((prevState) => ({
      ...prevState,
      ...key,
    }));
  };

  const APICall = (companyData) => {
    const { year, startMonth, endMonth } = state;
    console.log("handleSubmit 1", companyData);
    let reqBody = {
      year: year?._id,
      startMonth: startMonth?._id,
      endMonth: endMonth?._id,
      companyId: companyData?._id,
    };
    if (companyData === "") delete reqBody.companyId;
    dispatch(
      getPayrollCtc(reqBody, (resp) => {
        if (resp?.statusCode === 200) {
          updateState({ payRollData: resp });
        } else {
          updateState({
            payRollData: {
              companyDetails: {},
              data: [],
            },
          });
        }
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { company } = state;
    APICall(company);
  };
  const renderSearch = () => {
    const { year, startMonth, endMonth, company, companyList } = state;
    return (
      <form className="p-2" onSubmit={(event) => handleSubmit(event, company)}>
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center">
            <LabelField labelName={"Year"} isRequired={true} />
            <Dropdown
              labelName="Select Year"
              setInputText={(value) => updateState({ year: value })}
              options={yearData}
              value={year}
            />
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <LabelField labelName={"Start Month"} isRequired={true} />
            <Dropdown
              labelName="Select Start Month"
              setInputText={(value) => updateState({ startMonth: value })}
              options={monthData}
              value={startMonth}
            />
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <LabelField labelName={"End Month"} isRequired={true} />
            <Dropdown
              labelName="Select End Month"
              setInputText={(value) => updateState({ endMonth: value })}
              options={monthData}
              value={endMonth}
            />
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center">
            <LabelField labelName={"Company"} />
            <Dropdown
              labelName="Select Company"
              setInputText={(value) => updateState({ company: value })}
              options={companyList}
              disabled={true}
              value={company}
            />
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <SubmitButton validations={validations()} name="Search" />
          </div>
          <div className="col d-flex justify-content-center align-items-center"></div>
        </div>
      </form>
    );
  };
  const renderList = () => {
    return (
      <div className="performanceTable table-responsive">
        <table className="table align-middle table-bordered">
          <thead>
            <tr className="table-secondary">
              <th scope="col" className="text-center align-middle text-white">
                S.No.
              </th>
              <th scope="col" className="text-center align-middle text-white">
                Department
              </th>
              <th scope="col" className="text-center align-middle text-white">
                Sub Dept
              </th>
              <th scope="col" className="text-center align-middle text-white">
                Monthly CTC
              </th>
              <th scope="col" className="text-center align-middle text-white">
                Sub Contribution
              </th>
              <th scope="col" className="text-center align-middle text-white">
                Total Contribution
              </th>
            </tr>
          </thead>
          <tbody>
            {state?.payRollData?.data?.length > 0 ? (
              <>
                {state?.payRollData?.data?.map((item, index) =>
                  item?.subDepartments?.map((sd, idx) => (
                    <tr>
                      {idx === 0 && (
                        <>
                          <td
                            className="text-center"
                            rowSpan={item?.subDepartments?.length}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="text-center"
                            rowSpan={item?.subDepartments?.length}
                          >
                            {item?.department}
                          </td>
                        </>
                      )}
                      <td>{sd?.subDepartmentName}</td>
                      <td>{sd?.totalSalary}</td>
                      <td>{sd?.subdepContributionPercentage}</td>
                      {idx === 0 && (
                        <td
                          className="text-center"
                          rowSpan={item?.subDepartments?.length}
                        >
                          {item?.depContributionPercentage}
                        </td>
                      )}
                    </tr>
                  ))
                )}
                <th className="text-center" colSpan={2}>
                  Total Salary
                </th>
                <th className="text-center" colSpan={4}>
                  {state?.payRollData?.companyDetails?.totalSalary}
                </th>
              </>
            ) : (
              <tr>No record found</tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const validations = () => {
    const { year, startMonth, endMonth } = state;
    return year === "" || startMonth === "" || endMonth === "";
  };

  useEffect(() => {
    dispatch(
      getCompanyList((res) => {
        if (res?.length > 0) {
          const data = res.find((itm) =>
            itm?.name?.toUpperCase().includes("EDUTECH")
          );
          updateState({ companyList: [data], company: data });
          APICall(data);
        } else {
          updateState({ companyList: [] });
          APICall({});
        }
      })
    );
  }, []);
  return (
    <div className="contentBox">
      <PageTitle name={"Salary Analysis"} />
      <div className="formDiv contentScrollbar">
        <div className="col-md-12 formWidth">
          {renderSearch()}
          {renderList()}
        </div>
      </div>
    </div>
  );
};

export default PayRollCtc;
