export const DigitalMarketingData = [
  {
    title: "PAID ADS",
    link: "paidAds",
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
  {
    title: "LEAD COUNT",
    link: "leadCountDigital",
  },
  {
    title: "YOUTUBE",
    subNav: [
      {
        title: "YOUTUBE PERFORMANCE",
        path: "youtubePerformance",
        cName: "sub-nav",
      },
      {
        title: "OFF TOPIC PERFORMANCE",
        path: "offTopicPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET CREATION",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createDigitalTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatusDigital",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Social Media Team",
        path: "socialMediaTeam",
        cName: "sub-nav",
      },
      {
        title: "Youtube Target",
        path: "youtubeTarget",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD ANALYSIS SECTION",
    link: "digitalLeadAnalysis",
  },
  {
    title: "FACEBOOK GROUP",
    link: "facebookGroup",
  },
  {
    title: "SHOPIFY PAYMENT",
    link: "shopifyPayment",
  },
];
