/* eslint-disable react/prop-types */
import React from "react";

function GstViewButton({
  totalActualAmount,
  totalGSTAmount,
  totalShopifyAmount,
  isPublication,
  totalPublicationAmount,
  totalTransactionAmount,
}) {
  return (
    <div className="dropdown">
      <button
        className="btn btn-success btn-sm  rounded-pill dropdown-toggle ms-1 font-10"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        View
      </button>
      <ul className="dropdown-menu">
        <li className="d-flex justify-content-between px-1">
          <span className="font-12">Actual Price : </span>
          <span className="text-end font-12">
            {"\u20B9"}
            {totalActualAmount?.toFixed(2)}
          </span>
        </li>
        <li className="d-flex justify-content-between px-1">
          <span className="font-12">GST : </span>
          <span className="text-end font-12">
            {"\u20B9"}
            {totalGSTAmount?.toFixed(2)}
          </span>
        </li>
        <li className="d-flex justify-content-between px-1">
          <span className="font-12">Shopify : </span>
          <span className="text-end font-12">
            {"\u20B9"}
            {totalShopifyAmount?.toFixed(2)}
          </span>
        </li>
        {isPublication ? (
          <li className="d-flex justify-content-between px-1">
            <span className="font-12">Publication : </span>
            <span className="text-end font-12">
              {"\u20B9"}
              {totalPublicationAmount?.toFixed(2)}
            </span>
          </li>
        ) : null}

        <li className="d-flex justify-content-between px-1">
          <span className="font-12">Total Price : </span>
          <span className="text-end font-12">
            {"\u20B9"}
            {totalTransactionAmount?.toFixed(2)}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default GstViewButton;
