import "../../App.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Module/pageTitle";
import LabelField from "../../Component/labelField";
import {
  getBatchWiseCount,
  getBatchwiseAdmissionDetailslist,
  getExamListNew,
  getParentCourseNew,
  setParentCourse,
} from "../../Action/courseAction";
import AdminTable from "../../Component/adminTable";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Pagination } from "@mui/material";
import usePagination from "../../Component/usePagination";
import PaginationView from "../../Component/paginationView";
import { getTableDataSlots } from "../../Utils/dataConstants";
import { getSubExam } from "../../Action/adminPanelAction";
import Dropdown from "../../Component/dropdown";
import SubmitButton from "../../Component/SubmitButton";
import YearPicker from "../../Component/yearPicker";

function CxoBatchwiseCount() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [exam, setExam] = useState("");
  const [subExam, setSubExam] = useState("");
  const [examStream, setExamStream] = useState("");
  const [year, setYear] = useState("");

  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [batchWiseCountList, setBatchWiseCountList] = useState([]);
  const [admissionDetails, setAdmissionDetails] = useState([]);

  //pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const onChangeExam = (value) => {
    setExam(value);
    setSubExam("");
    setSubExamList([]);
    setExamStream("");
    setExamStreamList([]);
    setBatchWiseCountList([]);
    setCount(0);
    if (value) {
      dispatch(
        getSubExam(
          {
            examTypeId: value._id,
          },
          (res) => {
            if (res.data.length > 0) setSubExamList(res.data);
            else
              dispatch(
                getParentCourseNew(`${value._id}`, (res) => {
                  setExamStreamList(res);
                })
              );
          }
        )
      );
    }
  };

  const onChangeSubExam = (value) => {
    setSubExam(value);
    setExamStream("");
    setExamStreamList([]);
    setBatchWiseCountList([]);
    setCount(0);
    if (value) {
      dispatch(
        getParentCourseNew(`${exam._id}/${value._id}`, (res) => {
          setExamStreamList(res);
        })
      );
    }
  };

  const onChangeExamStream = (value) => {
    setExamStream(value);
    setBatchWiseCountList([]);
    setCount(0);
  };

  const handleEdit = (value) => {
    dispatch(
      getBatchwiseAdmissionDetailslist(value._id, (res) => {
        setAdmissionDetails(res);
      })
    );
    dispatch(setParentCourse("isEditMode", true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      baseCourseId: examStream._id,
      examTypeId: exam._id,
      skip: 0,
      limit: 10,
    };
    if (subExam) {
      postData["subExamTypeId"] = subExam._id;
    }
    if (year) postData.year = year;
    dispatch(
      getBatchWiseCount(
        postData,
        (res) => {
          setBatchWiseCountList(res.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="col-auto formWidth">
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap">
            <div className="d-flex col-6 align-items-center pe-2">
              <LabelField labelName={"Exam Name"} isRequired={true} />
              <div className="w-100">
                <Dropdown
                  labelName="Select Exam Name"
                  setInputText={(value) => onChangeExam(value)}
                  options={examList}
                  value={exam}
                />
              </div>
            </div>
            {subExamList?.length > 0 ? (
              <div className="d-flex col-6 align-items-center pe-2">
                <LabelField labelName={"Sub Exam Name"} isRequired={true} />
                <div className="w-100">
                  <Dropdown
                    labelName="Select Sub Exam Name"
                    setInputText={(value) => onChangeSubExam(value)}
                    options={subExamList}
                    value={subExam}
                  />
                </div>
              </div>
            ) : null}
            <div className="d-flex col-6 align-items-center pe-2">
              <LabelField labelName={"Exam Stream"} isRequired={true} />
              <div className="w-100">
                <Dropdown
                  labelName="Select Exam Stream"
                  setInputText={(value) => onChangeExamStream(value)}
                  options={examStreamList}
                  value={examStream}
                />
              </div>
            </div>
            <div className="d-flex col-6 align-items-center pe-2">
              <LabelField labelName={"Year"} isRequired={true} />
              <YearPicker handleYearChange={(year) => setYear(year)} />
            </div>
          </div>
          <SubmitButton name="Search" validations={validations()} />
        </form>

        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          count={count}
          tableData={batchWiseCountList ? batchWiseCountList : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <AdminTable
          pagename={"academicBatchWiseCount"}
          tableHead={[
            "S.N",
            "BATCH NAME",
            "Admission Count",
            // "Total Revenue",
            "Action",
          ]}
          tableData={batchWiseCountList ? batchWiseCountList : []}
          handleEdit={(value) => handleEdit(value)}
          edit={true}
        />
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="col-auto formWidth">
        <div className="text-end">
          {admissionDetails?.length > 0 ? (
            <DownloadTableExcel
              filename="Batchwise Admission Details"
              sheet="sheet 1"
              currentTableRef={tableRef.current}
            >
              <button
                type="button"
                className="btn btn-primary rounded-pill font-12"
              >
                Export Details
              </button>
            </DownloadTableExcel>
          ) : null}
        </div>
        <div ref={tableRef}>
          <AdminTable
            pagename={"admissionHistory"}
            tableHead={[
              "S.N",
              "FIRST NAME",
              "LAST NAME",
              // "MOBILE NUMBER",
              "STATE",
              "ADMISSION DATE",
              // "PAYMENT",
              "EXPIRY DATE",
              "",
            ]}
            tableData={admissionDetails ? admissionDetails : []}
            edit={false}
          />
        </div>
      </div>
    );
  };

  const validations = () => {
    return (
      examStream === "" ||
      exam === "" ||
      (subExamList?.length > 0 && subExam === "")
    );
  };

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  const onPageChange = (page, rowsPerPage, flag) => {
    /************ page change****************** */
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        baseCourseId: examStream._id,
        examTypeId: exam?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        baseCourseId: examStream._id,
        examTypeId: exam?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        baseCourseId: examStream._id,
        examTypeId: exam?._id,
      };
    }
    if (subExam) {
      data["subExamTypeId"] = subExam._id;
    }
    if (year) data.year = year;
    dispatch(
      getBatchWiseCount(
        data,
        (res) => {
          setBatchWiseCountList(res.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleCancelEditMode = () => {
    dispatch(setParentCourse("isEditMode", false));
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"BATCHWISE COUNT"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />
      <div className="formDiv contentScrollbar">
        {!isEditMode ? renderListMode() : renderEditMode()}
      </div>
    </div>
  );
}

export default CxoBatchwiseCount;
