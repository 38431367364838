/* eslint-disable no-useless-escape */
import React from "react";
import ReactHtmlParser from "react-html-parser";

export const getStringQuestionType = (questionType) => {
  /**
   * returns string/descriptive name of question type from number`
   * @param {number} questionType
   */
  const hashmap = {
    1: "MCQ",
    2: "MSQ",
    3: "NAT",
  };
  return questionType in hashmap ? hashmap[questionType] : "";
};

export const renderQuestions = (questions) => {
  return questions.map((item) => {
    const data = String(item.description);
    const removeData = data.replace(/\\n/g, " ");
    const modifiedHtml = removeData.replace(/\\t/g, " ");
    return (
      <>
        {item.descType === "text" ? (
          ReactHtmlParser(modifiedHtml)
        ) : (
          <img
            src={item.description}
            style={{ maxWidth: "100%", maxHeight: 100 }}
            alt="images"
          />
        )}
      </>
    );
  });
};

export const renderCorrectAns = (answers) => {
  let newArr = answers?.filter((item) => item?.isCorrect);
  return newArr.map((item) => {
    const data = String(item.description);
    const removeData = data.replace(/\\n/g, " ");
    const modifiedHtml = removeData.replace(/\\t/g, " ");
    return (
      <>
        {item.descType === "text" ? (
          ReactHtmlParser(modifiedHtml)
        ) : (
          <img
            src={item.description}
            style={{ maxWidth: "100%", maxHeight: 100 }}
            alt="images"
          />
        )}
      </>
    );
  });
};

export const madeCorrectURL = (imageUrl) => {
  let key = imageUrl;
  key = key.replace(/\%28/g, "(");
  key = key.replace(/\%29/g, ")");
  key = key.replace(/\%21/g, "!");
  key = key.replace(/\%7E/g, "~");
  key = key.replace(/\%20/g, "+");
  key = key.replace(/\%26/g, "AND");
  return key;
};

export const timeConvert = (n) => {
  if (n) {
    const totalSeconds = n * 60;
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSecondsAfterHours = totalSeconds % 3600;
    const mins = Math.floor(remainingSecondsAfterHours / 60);
    const seconds = remainingSecondsAfterHours % 60;

    console.log("sdkmckm", hours, mins, seconds);

    // Format with leading zeros
    const formattedTime = [
      hours.toString().padStart(2, "0"),
      mins.toString().padStart(2, "0"),
      Math.round(seconds)?.toFixed(0).toString().padStart(2, "0"),
    ].join(":");

    return formattedTime;

    // const second = (n + "").split(".")[1] ? (n + "").split(".")[1] : "00";
    // const num = n;
    // const hours = num / 60;
    // const rhours = Math.floor(hours);
    // const minutes = (hours - rhours) * 60;
    // const rminutes = Math.round(minutes);
    // return `${rhours < 10 ? "0" + rhours : rhours}:${rminutes < 10 ? "0" + rminutes : rminutes}:${
    //   second?.length === 1 ? "0" + second : second
    // }`;
  } else return "";
};
