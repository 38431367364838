import React, { useState, useEffect } from "react";
import "../../../App.css";
import PageTitle from "../../../Module/pageTitle";
import InputField from "../../../Component/inputField";
import { useDispatch } from "react-redux";
import {
  getAdditionalDiscount,
  //   updateAdditionalDiscount,
} from "../../../Action/courseAction";
import { showSuccessSnackbar } from "../../../Action/snackbarAction";
import { containsNumber, length_Two } from "../../../Utils/validators";
import { showErrorSnackbar } from "../../../Action/snackbarAction";
import SubmitButton from "../../../Component/SubmitButton";

function Influencer() {
  const dispatch = useDispatch();
  const [discPer, setPer] = useState("");

  useEffect(() => {
    // Fetch additional discount data on component mount
    const data = {
      type: "INFLUENCER_ADDITIONAL_DISCOUNT",
    };
    dispatch(
      getAdditionalDiscount(
        data,
        (res) => {
          if (res) {
            setPer(res?.data?.discPer); // Set discount percentage from API response
          }
        },
        () => {}
      )
    );
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      getAdditionalDiscount(
        { discPer, type: "STUDENTS_ADDITIONAL_DISCOUNT" },
        () => {
          dispatch(showSuccessSnackbar("Data updated successfully"));
        }
      )
    );
  };

  const onChangeDiscount = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only numbers are allowed"));
        return;
      }
    }
    setPer(value);
  };

  const validations = () => {
    return discPer !== "";
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Additional Discount"} />

      <div className="formDiv">
        <form className="formWidth" onSubmit={handleSubmit}>
          <div className="col-md-12 mt-3">
            <div className="col-6">
              <div className="d-flex mb-2 align-items-center">
                <InputField
                  labelName={"Percentage"}
                  setInputText={onChangeDiscount}
                  onChange={onChangeDiscount}
                  value={discPer}
                  maxlength={length_Two}
                />
              </div>
            </div>
          </div>

          <div className="col-auto d-flex justify-content-center mt-4">
            <SubmitButton name="Update" validations={validations()} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Influencer;
