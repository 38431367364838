import React, { useEffect, useState } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import moment from "moment";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import DatePicker from "../../Component/datePicker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getUserTransactionDetails } from "../../Action/courseAction";
import SubmitButton from "../../Component/SubmitButton";
import GstViewButton from "../../Component/gstViewButton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CxoDashboard() {
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState("");
  const [startDate, setStartDate] = useState(
    moment
      .utc(new Date().setDate(new Date().getDate() - 30))
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const defaultVal = {
    edutechActualAmount: 0,
    edutechGSTAmount: 0,
    edutechRazorPay: 0,
    publicationRazorPay: 0,
    totalRevenue: 0,
  };
  const [transactionAmount, setTransactionAmount] = useState(defaultVal);
  const [transactionData, setTransactionData] = useState([]);
  const [transDetailData, setTransDetailData] = useState([]);
  const [selectedTransType, setSelectedTransType] = useState("edutech");
  const [labelDataArr, setlabelDataArr] = useState([]);

  const [transDateWise, setTransDateWise] = useState("day");
  useEffect(() => {
    setMinDate(moment.utc(new Date()).format("YYYY-MM-DD"));
    getData();
  }, []);
  const getData = () => {
    dispatch(
      getUserTransactionDetails(
        {
          startDate,
          endDate,
        },
        (res) => {
          setTransDateWise("day");
          if (res?.length) {
            setTransactionData(res);
            let labelArr = [],
              totalEduArr = [];
            res?.map((item) => {
              const [day, month, year] = item?._id?.split("-");
              const dateObject = new Date(`${year}-${month}-${day}`);
              const options = { month: "short", day: "numeric" };
              const formattedDate = dateObject.toLocaleDateString(
                "en-US",
                options
              );
              labelArr?.push(formattedDate);
            });
            setlabelDataArr(labelArr);
            let edutech = 0,
              totalRevenue = 0;
            let actualPrice = 0;
            let GSTPrice = 0;
            let publicationPrice = 0;
            res?.map((item) => {
              totalEduArr.push(item?.edutech);
              actualPrice = actualPrice + item?.actualAmount || 0;
              GSTPrice = GSTPrice + item?.totalGstAmount || 0;
              edutech = edutech + item?.edutech;
              publicationPrice = publicationPrice + item?.publication;
              totalRevenue = totalRevenue + item?.edutech + item?.publication;
            });
            setTransDetailData(totalEduArr);
            setTransactionAmount({
              edutechActualAmount: actualPrice,
              edutechGSTAmount: GSTPrice,
              edutechRazorPay: edutech,
              publicationRazorPay: publicationPrice,
              totalRevenue: totalRevenue,
            });
          } else {
            setTransDetailData([]);
            setTransactionAmount(defaultVal);
          }
        }
      )
    );
  };
  const validations = () => {
    return startDate === "" || endDate === "";
  };

  const handleEndDateChange = (value) => {
    if (value < startDate) {
      dispatch(showErrorSnackbar("End date must be greater than start date"));
      return;
    }
    setEndDate(value);
  };
  const handleStartDateChange = (value) => {
    if (value > endDate) {
      dispatch(showErrorSnackbar("start date must be smaller than end date"));
      return;
    }
    setStartDate(value);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart",
      },
    },
  };

  const data = {
    labels: labelDataArr,
    datasets: [
      {
        label: "Total Revenue",
        data: transDetailData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };
  const handleSelection = (key) => {
    setSelectedTransType(key);
    if (transDateWise === "day") {
      groupByDay(key);
    } else if (transDateWise === "month") {
      groupByMonth(key);
    } else if (transDateWise === "week") {
      groupByWeek();
    } else {
      groupByDay(key);
    }
  };
  const groupByDay = (type) => {
    setTransDateWise("day");
    let data = transactionData;
    let totalEduArr = [];
    let labelArr = [];
    data?.map((item) => {
      let totalCountForDate = 0;
      const [day, month, year] = item?._id.split("-");
      const dateObject = new Date(`${year}-${month}-${day}`);
      const options = { month: "short", day: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("en-US", options);
      labelArr?.push(formattedDate);

      if (type === "edutech") {
        totalCountForDate = totalCountForDate + item?.edutech;
      }
      if (type === "publication") {
        totalCountForDate = totalCountForDate + item?.publication;
      }
      totalEduArr?.push(totalCountForDate);
    });
    setlabelDataArr(labelArr);
    setTransDetailData(totalEduArr);
  };

  const groupByWeek = () => {
    setTransDateWise("week");
    const groupedData = {};
    transactionData?.forEach((entry) => {
      const date = new Date(entry.date);
      const weekNumber = getWeekNumber(date);
      if (!groupedData[weekNumber]) {
        groupedData[weekNumber] = [];
      }
      groupedData[weekNumber].push(entry);
    });
    return groupedData;
  };

  // Function to get the ISO week number of a date
  const getWeekNumber = (date) => {
    const oneJan = new Date(date.getFullYear(), 0, 1);
    const millisecondsInDay = 86400000;
    return Math.ceil(
      ((date - oneJan) / millisecondsInDay + oneJan.getDay() + 1) / 7
    );
  };

  // Function to group data by month
  const groupByMonth = (type) => {
    setTransDateWise("month");
    const groupedData = {};
    let labelArr = [];
    transactionData?.forEach((entry) => {
      const [day, month, year] = entry?._id.split("-");
      const dateObject = new Date(`${year}-${month}-${day}`);
      const monthYear = `${new Date(dateObject).getFullYear()}-${
        new Date(dateObject).getMonth() + 1
      }`;
      if (!groupedData[monthYear]) {
        labelArr?.push(monthYear);
        groupedData[monthYear] = [];
      }
      groupedData[monthYear].push(entry);
    });
    setlabelDataArr(labelArr);

    let totalAmountArr = [];
    labelArr?.forEach((month) => {
      let totalAmount = 0;
      if (groupedData[month]) {
        groupedData[month].forEach((dayData) => {
          if (type === "edutech") {
            totalAmount += dayData?.edutech;
          } else {
            totalAmount += dayData?.publication;
          }
        });
      }
      totalAmountArr?.push(totalAmount);
    });
    setTransDetailData(totalAmountArr);
    return groupedData;
  };

  const renderListMode = () => {
    return (
      <div className="row mx-0 mt-2">
        <div className="row d-flex mx-0">
          <div
            className={`boxshadow card cardBox cursor ${
              selectedTransType === "edutech" ? "active" : null
            }`}
            onClick={() => handleSelection("edutech")}
          >
            <div className="d-flex align-items-center">
              <h3 className="count fw-bold">{`\u20B9 ${transactionAmount?.edutechActualAmount?.toFixed(
                2
              )}`}</h3>
              <GstViewButton
                totalActualAmount={transactionAmount?.edutechActualAmount}
                totalGSTAmount={transactionAmount?.edutechGSTAmount}
                totalTransactionAmount={transactionAmount?.edutechRazorPay}
              />
            </div>
            <h5 className="card-title">Edutech Razopay</h5>
          </div>
          <div
            className={`boxshadow card cardBox cursor ${
              selectedTransType === "publication" ? "active" : null
            }`}
            onClick={() => handleSelection("publication")}
          >
            <div className="d-flex align-items-center">
              <h3 className="count fw-bold">{`\u20B9 ${transactionAmount?.publicationRazorPay?.toFixed(
                2
              )}`}</h3>
              <GstViewButton
                totalActualAmount={0}
                totalGSTAmount={0}
                totalPublicationAmount={transactionAmount?.publicationRazorPay}
                isPublication={true}
                totalTransactionAmount={transactionAmount?.publicationRazorPay}
              />
            </div>
            <h5 className="card-title">Publication Razopay</h5>
          </div>
          <div className="boxshadow card cardBox">
            <div className="d-flex align-items-center">
              <h3 className="count fw-bold blu">{`\u20B9 ${transactionAmount?.totalRevenue?.toFixed(
                2
              )}`}</h3>
              <GstViewButton
                totalActualAmount={transactionAmount?.edutechActualAmount}
                totalGSTAmount={transactionAmount?.edutechGSTAmount}
                totalPublicationAmount={transactionAmount?.publicationRazorPay}
                isPublication={true}
                totalTransactionAmount={transactionAmount?.totalRevenue}
              />
            </div>
            <h5 className="card-title">Total Revenue</h5>
          </div>
        </div>

        <div className="col-12 mx-auto">
          <div className="text-center mt-3">
            <button
              className={`btn rounded-0 ${
                transDateWise === "day"
                  ? "btn-outline-primary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => groupByDay(selectedTransType)}
            >
              Daily
            </button>
            <button
              className={`btn rounded-0 ${
                transDateWise === "month"
                  ? "btn-outline-primary"
                  : "btn-outline-secondary"
              }`}
              onClick={() => groupByMonth(selectedTransType)}
            >
              Monthly
            </button>
          </div>
          <div className="chart-Box rounded">
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"REVENUE ANALYSIS"} />
      <div className="formDiv ">
        <div className="col-12 formWidth">
          <form onSubmit={handleSubmit}>
            <div className="col-auto">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <div className="col-md-4">
                    <LabelField labelName={"Start Date"} isRequired={true} />
                  </div>
                  <div className="col-md-8">
                    <DatePicker
                      setInputText={(value) => {
                        handleStartDateChange(value);
                      }}
                      value={startDate}
                      maxdate={minDate}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <div className="col-md-4">
                    <LabelField labelName={"End Date"} isRequired={true} />
                  </div>
                  <div className="col-md-8">
                    <DatePicker
                      setInputText={(value) => handleEndDateChange(value)}
                      value={endDate}
                      maxdate={minDate}
                    />
                  </div>
                </div>
                <SubmitButton name="Search" validations={validations()} />
              </div>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}

export default CxoDashboard;
