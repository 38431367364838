import React, { useState, useEffect } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import "../../App.css";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getSectionList,
  getDurations,
  getParentCourseNew,
  getExamListNew,
  getSubCategory,
  getExmMapping,
  addBasePrice,
} from "../../Action/courseAction";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  containsNumber,
  length_Six,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import { validateIsNumberOnlyErrMsg } from "../../Utils/errorMsg";
import SubmitButton from "../../Component/SubmitButton";
import FormInputField from "../../Component/FormInputField";
import { getSubExam } from "../../Action/adminPanelAction";
import SquareCheckBox from "../../Component/SquareCheckBox";

function CreateBasePrice() {
  const dispatch = useDispatch();

  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subExam, setSubExam] = useState("");
  const [combo, setCombo] = useState("");
  const [baseCourseCombo, setBaseCourseCombo] = useState("");

  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [durations, setDurations] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [comboList, setComboList] = useState([]);

  const [resetExam, setResetExam] = useState(true);
  const [resetSubExam, setResetSubExam] = useState(true);
  const [resetParentCourse, setResetParentCourse] = useState(true);
  const [resetSection, setResetSection] = useState(true);
  const [resetSubCategory, setResetSubCategory] = useState(true);
  const [resetCombo, setResetCombo] = useState(true);

  const [isBaseCombo, setIsBaseCombo] = useState(false);

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );

    dispatch(
      getDurations(
        "",
        (res) => {
          let List = res;
          const newDurationList = List.map((item) => {
            return {
              durationId: item._id,
              months: item.months,
              price: 0,
              isRecommended: false,
              isActive: false,
            };
          });

          setDurations(newDurationList);
        },
        () => {}
      )
    );

    dispatch(
      getSectionList(
        "",
        (res) => {
          // const newArr = res?.data?.filter((item) => item?.type === 1);
          setSectionList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const resetForm = async () => {
    await setparentCourseVal("");
    await setExamName("");
    await setSectionName("");
    await setSubCategory("");
    await setSubExam("");
    await setCombo("");
    await setBaseCourseCombo("");

    await setParentCourseList([]);
    await setExamList([]);
    await setSubExamList([]);
    await setSectionList([]);
    await setDurations([]);
    await setSubCategoryList([]);
    await setComboList([]);

    await setResetExam(true);
    await setResetSubExam(true);
    await setResetParentCourse(true);
    await setResetSection(true);
    await setResetSubCategory(true);
    await setResetCombo(true);
    await setIsBaseCombo(false);
    await dispatch(
      getDurations(
        "",
        (res) => {
          let List = res;
          const newDurationList = List.map((item) => {
            return {
              durationId: item._id,
              months: item.months,
              price: 0,
              isRecommended: false,
              isActive: false,
            };
          });

          setDurations(newDurationList);
        },
        () => {}
      )
    );
  };

  const onChangeExamName = (value) => {
    setExamName(value);
    setparentCourseVal("");
    setSectionName("");
    setSubCategory("");
    setSubExam("");
    setParentCourseList([]);
    setSubExamList([]);
    setSubCategoryList([]);
    setResetExam(false);
    setResetSubExam(true);
    setResetParentCourse(true);
    setResetSection(true);
    setResetSubCategory(true);
    setCombo("");
    setBaseCourseCombo("");
    setComboList([]);
    setResetCombo(true);
    setIsBaseCombo(false);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res?.data.length > 0) {
            setSubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExamName = (value) => {
    setSubExam(value);
    setparentCourseVal("");
    setSectionName("");
    setSubCategory("");
    setParentCourseList([]);
    setSubCategoryList([]);
    setResetSubExam(false);
    setResetParentCourse(true);
    setResetSection(true);
    setResetSubCategory(true);
    setCombo("");
    setBaseCourseCombo("");
    setComboList([]);
    setResetCombo(true);
    setIsBaseCombo(false);
    dispatch(
      getParentCourseNew(`${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const onChangeParentCourse = (value) => {
    setparentCourseVal(value);
    setSectionName("");
    setSubCategory("");
    setSubCategoryList([]);
    setResetParentCourse(false);
    setResetSection(true);
    setResetSubCategory(true);
    setCombo("");
    setBaseCourseCombo("");
    setComboList([]);
    setResetCombo(true);
    setIsBaseCombo(false);
  };

  const onSectionNameChange = (value) => {
    setSectionName(value);
    setSubCategory("");
    setSubCategoryList([]);
    setResetSection(false);
    setResetSubCategory(true);
    setCombo("");
    setBaseCourseCombo("");
    setComboList([]);
    setResetCombo(true);
    setIsBaseCombo(false);
    dispatch(
      getSubCategory(
        value.type,
        (res) => {
          setSubCategoryList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubcategory = (value) => {
    setSubCategory(value);
    setResetSubCategory(false);
  };

  const onCheck = (event, index, type) => {
    let newArray = JSON.parse(JSON.stringify(durations));
    if (type === "check")
      if (event.target.checked) {
        newArray[index]["isActive"] = true;
        setDurations(newArray);
      } else {
        newArray[index]["isActive"] = false;
        setDurations(newArray);
      }
    if (type === "recommended") {
      for (let i = 0; i < newArray.length; i++) {
        newArray[i].isRecommended = false;
      }
      newArray[index].isRecommended = event;
      setDurations(newArray);
    }
    if (type === "price") {
      if (event !== "") {
        if (!containsNumber(event)) {
          dispatch(showErrorSnackbar("Only Numbers are allowed!"));
          return;
        } else if (!validateValueWithoutSpace(event)) {
          dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
          return;
        } else {
          newArray[index].price = event ? parseInt(event) : 0;
          setDurations(newArray);
        }
      } else {
        newArray[index].price = event ? parseInt(event) : 0;
        setDurations(newArray);
      }
    }
  };

  const packageFlagCombo = (event) => {
    setIsBaseCombo(event.target.checked);
    setCombo("");
    setBaseCourseCombo("");
    setComboList([]);
    setResetCombo(true);
    if (event.target.checked && parentCourseVal && examName && sectionName) {
      const data = {
        fromBaseCourseId: parentCourseVal?._id,
        fromExamTypeId: examName?._id,
      };
      dispatch(
        getExmMapping(data, (resp) => {
          const newData = resp?.data?.map((item) => {
            return {
              ...item,
              name: `${item.toBaseCourseName} - ${item.toExamTypeName}`,
            };
          });
          setComboList(newData);
        })
      );
    } else {
      dispatch(
        showErrorSnackbar(
          "Please Select Exam Name, Exam Stream and Section Name First"
        )
      );
    }
  };

  const packageFlagComboBatch = (value) => {
    setCombo(value);
    setResetCombo(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqBody = {
      baseCourseId: parentCourseVal?._id,
      examTypeId: examName?._id,
      sectionId: sectionName?._id,
      sectionType: sectionName?.type,
      subscriptionPlan: durations,
      primarySubCategoryId: subCategory?._id,
    };
    if (subExam) reqBody.subExamTypeId = subExam?._id;
    if (isBaseCombo) reqBody.comboId = combo?._id;
    if (isBaseCombo) reqBody.isCombination = isBaseCombo;
    dispatch(
      addBasePrice(reqBody, () => {
        resetForm();
      })
    );
  };

  const validateArray = () => {
    let result = false;
    const indx = durations.filter((elm) => elm.isActive && elm.price);
    const indxActive = durations.filter((elm) => elm.isActive);
    const indxPrice = durations.filter((elm) => elm.price);
    if (indxActive?.length === indxPrice?.length) {
      if (indx?.length > 0) {
        const recommended = indx.filter((elm) => elm.isRecommended);
        if (recommended.length > 0) result = false;
        else result = true;
      } else result = true;
    } else result = true;
    return result;
  };

  const validations = () => {
    return (
      parentCourseVal === "" ||
      examName === "" ||
      (subExamList.length > 0 && subExam === "") ||
      sectionName === "" ||
      subCategory === "" ||
      (isBaseCombo && combo === "") ||
      validateArray()
    );
  };

  const renderPackage = () => {
    return (
      <>
        <div className="position-relative">
          <div className="d-flex mb-2">
            <LabelField labelName={"Select Durations"} />
            <div className="border p-2 rounded">
              {durations?.length > 0 ? (
                <div className="position-relative">
                  <div>
                    <table className="w-100 mb-3">
                      <tr>
                        <th></th>
                        <th>Course Duration</th>
                        <th>Recommended</th>
                        <th>Course Price</th>
                      </tr>
                      <tbody>
                        {durations.map((c, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <input
                                id={c._id}
                                className="check"
                                type="checkbox"
                                checked={c.isActive}
                                onChange={(event) => {
                                  onCheck(event, index, "check");
                                }}
                              />
                            </td>
                            <td>{c.months} Months</td>
                            <td className="text-center">
                              <input
                                id=""
                                className="check "
                                type="checkbox"
                                checked={c.isRecommended}
                                name="recommended"
                                onClick={() =>
                                  onCheck(true, index, "recommended")
                                }
                              />
                            </td>
                            <td className="removelable">
                              <FormInputField
                                placeholder={"Enter Package Price"}
                                setInputText={(value) =>
                                  onCheck(value, index, "price")
                                }
                                maxLength={length_Six}
                                value={c.price}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE BASE PRICE"} />

      <div className="formDiv contentScrollbar">
        <form className="col-md-6 formWidth" onSubmit={handleSubmit}>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} isRequired={true} />
            <CustomDropdown
              setInputText={(value) => onChangeExamName(value)}
              defaultValue={examName?._id}
              options={examList}
              labelName="Select Exam Name"
              reset={resetExam}
            />
          </div>

          {subExamList?.length > 0 ? (
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} isRequired={true} />
              <CustomDropdown
                setInputText={(value) => onChangeSubExamName(value)}
                defaultValue={subExam?._id}
                options={subExamList}
                labelName="Select Sub Exam Name"
                reset={resetSubExam}
              />
            </div>
          ) : null}

          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} isRequired={true} />
            <CustomDropdown
              setInputText={(value) => onChangeParentCourse(value)}
              defaultValue={parentCourseVal?._id}
              options={parentCourseList}
              labelName="Select Exam Stream"
              reset={resetParentCourse}
            />
          </div>

          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Section Name"} isRequired={true} />
            <CustomDropdown
              labelName="Section Name"
              setInputText={(value) => onSectionNameChange(value)}
              defaultValue={sectionName?._id}
              options={sectionList}
              reset={resetSection}
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Primary Subcategory"} isRequired={true} />
            <CustomDropdown
              labelName="Select Primary Subcategory"
              setInputText={(value) => onChangeSubcategory(value)}
              defaultValue={subCategory?._id}
              options={subCategoryList}
              reset={resetSubCategory}
            />
          </div>
          <div className="col-12 d-flex mb-2">
            <LabelField />
            <div className="align-items-center">
              <div className="d-flex align-items-center fw-bold">
                <SquareCheckBox
                  name={"Package Flag"}
                  value={isBaseCombo}
                  checked={isBaseCombo}
                  onChange={packageFlagCombo}
                  labelName={"Combo Package Flag"}
                />
              </div>
              <div>
                <span>
                  (If this flag is selected then Base Course Will be added to
                  this Package)
                </span>
              </div>
            </div>
          </div>
          {isBaseCombo == true ? (
            <>
              <div className="d-flex mb-2 align-items-center">
                <LabelField
                  labelName={"Combo Exam Name Stream"}
                  isRequired={true}
                />
                <CustomDropdown
                  labelName="Select Exam Name Stream"
                  setInputText={(value) => packageFlagComboBatch(value)}
                  defaultValue={combo?._id}
                  options={comboList}
                  reset={resetCombo}
                />
              </div>
            </>
          ) : null}
          {renderPackage()}
          <SubmitButton name="Save" validations={validations()} />
        </form>
      </div>
    </div>
  );
}

export default CreateBasePrice;
