/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from "react";
import ChatBody from "./ChatBody";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const ChatRTMP = ({ socket }) => {
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);
  const { streamKey } = useSelector((state) => ({
    streamKey: state?.Auth?.streamKey,
  }));

  useEffect(() => {
    const socket = io("https://stagingbackend.ifasonline.com", {
      transports: ["websocket", "polling"], // Ensures WebSocket connection
      autoConnect: false,
      secure: true,
      query: {
        room: streamKey,
      },
      withCredentials: true,
    });

    socket.connect();

    // Handle connection
    socket.on("connect", () => {
      console.log("Connected to WebSocket server:", "IFAS-CdcBAoiWnSr");
    });

    // Handle incoming messages
    socket.on("messageResponse", (data) => {
      setMessages((prev) => [...prev, data]);
    });

    // Handle disconnection
    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Handle errors
    socket.on("connect_error", (err) => {
      console.error("Connection Error:", err.message);
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className={"chat"}>
      <div className="chat__main">
        <ChatBody messages={messages} lastMessageRef={lastMessageRef} />
      </div>
    </div>
  );
};

export default ChatRTMP;
