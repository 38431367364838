import React from "react";
import LabelField from "./labelField";

function FormInputField({
  id,
  placeholder,
  value,
  setInputText,
  maxLength,
  minLength,
  disabled,
  defaultValue,
  styling,
}) {
  const onChangeInput = (e) => {
    setInputText(allowOnlyOneSpace(e.target.value));
  };

  const allowOnlyOneSpace = (str) => {
    return str.endsWith(" ") ? str.trim() + " " : str.trim();
  };
  return (
    <div className={styling ? styling : "d-flex mb-2 align-items-center w-100"}>
      <input
        id={id}
        type="text"
        placeholder={placeholder}
        value={value}
        className={styling ? "form-control" : "form-control  m-0"}
        onChange={(e) => onChangeInput(e)}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default FormInputField;
