export const TechSupportSidebarData = [
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtsolvereview",
  },
  // {
  //   title: "TEACHER SUPPORT",
  //   link: "teachersupport",
  // },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
  {
    title: "LIVE CLASS SCHEDULE",
    link: "liveclassschedule",
  },
  {
    title: "USER DETAILS",
    link: "userDetails",
  },
  {
    title: "CALL TO SUPPORT REQUEST LEADS",
    link: "expertsupport",
  },
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDYMATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
];
