import React, { useEffect, useState } from "react";
import PageTitle from "../../../Module/pageTitle";
import LabelField from "../../../Component/labelField";
import FormInputField from "../../../Component/FormInputField";
import DatePicker from "../../../Component/datePicker";
import AdminTable from "../../../Component/adminTable";
import {
  getExamListNew,
  getParentCourseNew,
  getUpcomingBatches,
  listUpcomingBatches,
  setParentCourse,
  updateUpcomingbatches,
} from "../../../Action/courseAction";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "../../../Component/dropdown";
import {
  containsNumber,
  validateValueWithoutSpace,
} from "../../../Utils/validators";
import { showErrorSnackbar } from "../../../Action/snackbarAction";
import SubmitButton from "../../../Component/SubmitButton";

function EditUpcomingBatches() {
  const dispatch = useDispatch();
  const [addName, setAddName] = useState([]);
  const [examName, SetExamName] = useState("");
  const [addStrem, setAddStrem] = useState([]);
  const [examStream, setExamStream] = useState("");
  const [addType, setAddType] = useState("");
  const [startDate, SetStartDate] = useState("");
  const [endDate, SetEndDate] = useState("");
  const [upcomingId, setUpcomoingId] = useState("");
  const [getBatchList, setGetBatchList] = useState([]);
  const [batchName, setBatchName] = useState("");
  const [batchTrgete, setBatchTrgete] = useState(100);
  const [datePickers, setDatePickers] = useState("");
  const [liestArray, setLiestArray] = useState();
  const [batchId, setBatchId] = useState("");

  const typeData = [
    {
      _id: 1,
      name: "ONLINE COURSE BY VIP",
    },
    {
      _id: 6,
      name: "CRASH COURSE BY VIP",
    },
  ];
  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  useEffect(() => {
    dispatch(
      listUpcomingBatches({}, (res) => {
        setGetBatchList(res.data);
      })
    );
    dispatch(
      getExamListNew((res) => {
        setAddName(res);
      })
    );
  }, []);

  const handleEdit = (value) => {
    dispatch(setParentCourse("isEditMode", true));
    dispatch(
      getUpcomingBatches(value?._id, (res) => {
        setLiestArray(res[0]);
        setUpcomoingId(value?._id);
        SetExamName(value?.examTypeId?.name);
        setExamStream(value?.baseCourseId?.name);
        setDatePickers(value?.batchDate);
        setBatchTrgete(value?.admissionTarget);
        setBatchName(value?.upcomingBatchName);
        setAddType(value?.section.name);
        setBatchId(value?.courseId);
      })
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("type are", liestArray.section.type);
    const postData = {
      examTypeId: liestArray.examTypeId._id,
      baseCourseId: liestArray.baseCourseId._id,
      upcomingBatchName: batchName,
      type: liestArray.section.type,
      admissionTarget: batchTrgete,
      batchDate: datePickers,
      courseId: batchId,
    };
    dispatch(
      updateUpcomingbatches(upcomingId, postData, (res) => {
        if (res) {
          dispatch(setParentCourse("isEditMode", false));
          handelSearch();
        }
      })
    );
  };

  const resetInputsData = () => {
    setDatePickers("");
    setBatchTrgete("");
    setBatchName("");
    setExamStream("");
    setAddName("");
    setAddType("");
    setAddStrem(" ");
  };

  // const handleDelete = (value) => {
  //   dispatch(
  //     deleteUpcomingBatches(value._id, () => {
  //       const deleterow = getBatchList.filter(
  //         (item, i) => item._id !== value._id
  //       );
  //       setGetBatchList(deleterow);
  //     })
  //   );
  // };
  const renderEditMode = () => {
    return (
      <div className="col-6">
        <form onSubmit={handleUpdate}>
          <div className="d-flex mb-2 align-items-center w-100">
            <LabelField labelName={"Exam Name"} isRequired={true} />
            <Dropdown
              labelName={examName}
              setInputText={(value) => onchangeExamName(value)}
              options={addName}
              value={examName}
              disabled={isEditMode}
              // names="Exam Name"
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} isRequired={true} />
            <Dropdown
              labelName={examStream}
              setInputText={(value) => setExamStream(value)}
              options={addStrem}
              valuealue={examStream}
              disabled={isEditMode}
              // name="Exam Stream"
            />
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Type"} />
            <Dropdown
              labelName={addType}
              setInputText={(value) => setAddType(value)}
              options={typeData}
              Value={addType}
              disabled={isEditMode}
            />
          </div>
          <div className="d-flex align-items-center">
            <LabelField labelName={"Batch ID"} />
            <FormInputField
              key={"ex2"}
              placeholder={"Enter Batch ID"}
              setInputText={(value) => setBatchId(value)}
              value={batchId}
              // disabled={true}
            />
          </div>
          <div className="d-flex align-items-center">
            <LabelField labelName={"Batch Name"} isRequired={true} />
            <FormInputField
              key={"ex2"}
              placeholder={"Enter Batch Name"}
              setInputText={(value) => setBatchName(value)}
              value={batchName}
            />
          </div>
          <div className="d-flex align-items-center">
            <LabelField labelName={"Admission Target"} isRequired={true} />
            <FormInputField
              key={"ex2"}
              placeholder={"Enter Target"}
              setInputText={(value) => onChangeExamNameOrder(value)}
              value={batchTrgete}
              name="batchTrgete"
            />
          </div>
          <div className="d-flex mb-2 align-items-center  ">
            <LabelField labelName={"Batch Date :"} isRequired={true} />
            <DatePicker
              setInputText={(value) => {
                setDatePickers(value);
              }}
              value={datePickers?.substring(0, 10)}
            />
          </div>
          <SubmitButton name="Save & update" />
        </form>
      </div>
    );
  };
  const onchangeExamName = (value) => {
    SetExamName(value);
    dispatch(
      getParentCourseNew(`${value._id}`, (res) => {
        setAddStrem(res);
      })
    );
  };
  const onChangeExamNameOrder = (value) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!"));
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
        return;
      } else {
      }
      setBatchTrgete(value);
    }
    setBatchTrgete(value);
  };
  const handelSearch = () => {
    const dateSearch = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      listUpcomingBatches(dateSearch, (res) => {
        if (res) {
          setGetBatchList(res?.data);
        }
      })
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="container">
          <div className="row d-flex align-items-end  justify-content-center">
            <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
              <div className="d-flex align-items-center  ">
                <LabelField labelName={"Start Date :"} isRequired={true} />
                <DatePicker
                  setInputText={(value) => {
                    SetStartDate(value);
                  }}
                  value={startDate}
                />
              </div>
            </div>
            <div className="col-5 col-sm-5 col-lg-5 col-xl-5">
              <div className="d-flex  align-items-center  ">
                <LabelField labelName={"End Date :"} isRequired={true} />
                <DatePicker
                  setInputText={(value) => {
                    SetEndDate(value);
                  }}
                  value={endDate}
                />
              </div>
            </div>
            <div className="col-2 col-sm-2 col-lg-2 col-xl-2">
              <button
                className="btn btn-primary mb-2 text-white"
                onClick={handelSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <AdminTable
          tableData={getBatchList ? getBatchList : []}
          pagename={"UpcomingBatchesEdit"}
          tableHead={[
            "S.N",
            "Exam Name",
            "Exam Stream",
            "Batch Name",
            "Batch Date",
            "Target",
            "Action",
          ]}
          handleEdit={(value) => handleEdit(value)}
          // handleDelete={(value) => handleDelete(value)}
          edit={true}
        />
      </>
    );
  };

  const handleCancelEditMode = () => {
    dispatch(setParentCourse("isEditMode", false));
  };

  return (
    <div>
      <div className="contentBox">
        <PageTitle
          name={"EDIT / UPCOMING BATCHES"}
          isEditMode={isEditMode}
          onClick={handleCancelEditMode}
        />
        <div className="formDiv contentScrollbar">
          <div className="col-auto formWidth">
            {!isEditMode ? renderListMode() : renderEditMode()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUpcomingBatches;
