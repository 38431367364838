import React, { useState, useEffect } from "react";
import "../../App.css";
import PageTitle from "../../Module/pageTitle";
import { useDispatch } from "react-redux";
import "../../App.css";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getParentCourseNew,
  getExamListNew,
  getBasePriceList,
  getBasePrice,
  setParentCourse,
  updateBasePrice,
  getExmMapping,
} from "../../Action/courseAction";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import {
  containsNumber,
  length_Six,
  validateValueWithoutSpace,
} from "../../Utils/validators";
import Pagination from "@mui/material/Pagination";
import { validateIsNumberOnlyErrMsg } from "../../Utils/errorMsg";
import SubmitButton from "../../Component/SubmitButton";
import FormInputField from "../../Component/FormInputField";
import { getSubExam } from "../../Action/adminPanelAction";
import { useSelector } from "react-redux";
import PaginationView from "../../Component/paginationView";
import PaginationTable from "../../Component/paginationTable";
import usePagination from "../../Component/usePagination";
import { data, getTableDataSlots } from "../../Utils/dataConstants";
import InputTextField from "../../Component/inputTextField";
import Dropdown from "../../Component/dropdown";

function EditDeleteBasePrice() {
  const dispatch = useDispatch();

  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subExam, setSubExam] = useState("");
  const [combo, setCombo] = useState("");
  const [baseCourseCombo, setBaseCourseCombo] = useState("");
  const [isCombo, setIsCombo] = useState("");

  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [durations, setDurations] = useState([]);

  const [resetExam, setResetExam] = useState(true);
  const [resetSubExam, setResetSubExam] = useState(true);
  const [resetParentCourse, setResetParentCourse] = useState(true);

  const [isBaseCombo, setIsBaseCombo] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [id, setId] = useState("");

  const { isEditMode } = useSelector((state) => ({
    isEditMode: state.courseReducer?.isEditMode,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e, page) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page, rowsPerPage, flag) => {
    /************ page change****************** */
    let data = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        baseCourseId: parentCourseVal._id,
        examTypeId: examName._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        baseCourseId: parentCourseVal,
        examTypeId: examName._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        baseCourseId: parentCourseVal._id,
        examTypeId: examName._id,
      };
    }

    if (subExam) data.subExamTypeId = subExam._id;

    dispatch(
      getBasePriceList(
        data,
        (res) => {
          setCount(res?.length);
          setPackageList(res);
        },
        () => {}
      )
    );
  };

  const handleCancelEditMode = () => {
    // resetForm();
    dispatch(setParentCourse("isEditMode", false));
  };

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);

  const resetForm = async () => {
    await setparentCourseVal("");
    await setExamName("");
    await setSectionName("");
    await setSubCategory("");
    await setSubExam("");
    await setCombo("");
    await setBaseCourseCombo("");

    await setParentCourseList([]);
    await setExamList([]);
    await setSubExamList([]);
    await setDurations([]);

    await setResetExam(true);
    await setResetSubExam(true);
    await setResetParentCourse(true);
    await setIsBaseCombo(true);
    await setPackageList([]);
  };

  const onChangeExamName = (value) => {
    setExamName(value);
    setparentCourseVal("");
    setSectionName("");
    setSubCategory("");
    setSubExam("");
    setParentCourseList([]);
    setSubExamList([]);
    setResetExam(false);
    setResetSubExam(true);
    setResetParentCourse(true);
    setCombo("");
    setBaseCourseCombo("");
    setIsBaseCombo(false);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res?.data.length > 0) {
            setSubExamList(res.data);
          } else {
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };

  const onChangeSubExamName = (value) => {
    setSubExam(value);
    setparentCourseVal("");
    setSectionName("");
    setSubCategory("");
    setParentCourseList([]);
    setResetSubExam(false);
    setResetParentCourse(true);
    setCombo("");
    setBaseCourseCombo("");
    setIsBaseCombo(false);
    dispatch(
      getParentCourseNew(`${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const onChangeParentCourse = (value) => {
    setparentCourseVal(value);
    setSectionName("");
    setSubCategory("");
    setResetParentCourse(false);
    setCombo("");
    setBaseCourseCombo("");
    setIsBaseCombo(false);
    setCount(0);
    setPackageList([]);
    const data = {
      skip: 0,
      limit: rowsPerPage,
      baseCourseId: value._id,
      examTypeId: examName._id,
    };
    if (subExam) data.subExamTypeId = subExam._id;
    if (isCombo) data.isCombination = isCombo._id;

    dispatch(
      getBasePriceList(
        data,
        (res) => {
          setCount(res?.length);
          setPackageList(res);
        },
        () => {}
      )
    );
  };

  const onCheck = (event, index, type) => {
    let newArray = JSON.parse(JSON.stringify(durations));
    if (type === "check")
      if (event.target.checked) {
        newArray[index]["isActive"] = true;
        setDurations(newArray);
      } else {
        newArray[index]["isActive"] = false;
        setDurations(newArray);
      }
    if (type === "recommended") {
      for (let i = 0; i < newArray.length; i++) {
        newArray[i].isRecommended = false;
      }
      newArray[index].isRecommended = event;
      setDurations(newArray);
    }
    if (type === "price") {
      if (event !== "") {
        if (!containsNumber(event)) {
          dispatch(showErrorSnackbar("Only Numbers are allowed!"));
          return;
        } else if (!validateValueWithoutSpace(event)) {
          dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg));
          return;
        } else {
          newArray[index].price = event ? parseInt(event) : 0;
          setDurations(newArray);
        }
      } else {
        newArray[index].price = event ? parseInt(event) : 0;
        setDurations(newArray);
      }
    }
  };

  const onHandleCombo = (value) => {
    setIsCombo(value ? value : "");
    const data = {
      skip: 0,
      limit: rowsPerPage,
      baseCourseId: parentCourseVal._id,
      examTypeId: examName._id,
    };
    if (subExam) data.subExamTypeId = subExam._id;
    if (value) data.isCombination = value._id;

    dispatch(
      getBasePriceList(
        data,
        (res) => {
          setCount(res?.length);
          setPackageList(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (val) => {
    dispatch(setParentCourse("isEditMode", true));
    setId(val?._id);
    dispatch(
      getBasePrice(val?._id, (data) => {
        const {
          primarySubCategoryId,
          sectionId,
          isCombination,
          comboId,
          subscriptionPlan,
        } = data;
        setSectionName(sectionId);
        setDurations(subscriptionPlan);
        setSubCategory(primarySubCategoryId);
        setIsBaseCombo(isCombination ? isCombination : false);
        if (isCombination) {
          const dataReq = {
            fromBaseCourseId: parentCourseVal?._id,
            fromExamTypeId: examName?._id,
          };
          dispatch(
            getExmMapping(dataReq, (resp) => {
              resp?.data?.forEach((item) => {
                if (item._id === comboId._id) {
                  setBaseCourseCombo({
                    ...item,
                    name: `${item.toBaseCourseName} - ${item.toExamTypeName}`,
                  });
                }
              });
            })
          );
        }
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(
      updateBasePrice(`${id}`, { subscriptionPlan: durations }, (data) => {
        dispatch(setParentCourse("isEditMode", false));
      })
    );
  };

  const validateArray = () => {
    let result = false;
    const indx = durations.filter((elm) => elm.isActive && elm.price);
    const indxActive = durations.filter((elm) => elm.isActive);
    const indxPrice = durations.filter((elm) => elm.price);
    if (indxActive?.length === indxPrice?.length) {
      if (indx?.length > 0) {
        const recommended = indx.filter((elm) => elm.isRecommended);
        if (recommended.length > 0) result = false;
        else result = true;
      } else result = true;
    } else result = true;
    return result;
  };

  const validations = () => {
    return validateArray();
  };

  const renderPackage = () => {
    return (
      <>
        <div className="position-relative">
          <div className="d-flex mb-2">
            <LabelField labelName={"Select Durations"} />
            <div className="border p-2 rounded">
              {durations?.length > 0 ? (
                <div className="position-relative">
                  <div>
                    <table className="w-100 mb-3">
                      <tr>
                        <th></th>
                        <th>Course Duration</th>
                        <th>Recommended</th>
                        <th>Course Price</th>
                      </tr>
                      <tbody>
                        {durations.map((c, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <input
                                id={c._id}
                                className="check"
                                type="checkbox"
                                checked={c.isActive}
                                onChange={(event) => {
                                  onCheck(event, index, "check");
                                }}
                              />
                            </td>
                            <td>{c.months} Months</td>
                            <td className="text-center">
                              <input
                                id=""
                                className="check "
                                type="checkbox"
                                checked={c.isRecommended}
                                name="recommended"
                                onClick={() =>
                                  onCheck(true, index, "recommended")
                                }
                              />
                            </td>
                            <td className="removelable">
                              <FormInputField
                                placeholder={"Enter Package Price"}
                                setInputText={(value) =>
                                  onCheck(value, index, "price")
                                }
                                maxLength={length_Six}
                                value={c.price}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="formDiv contentScrollbar">
        <form className="col-md-6 formWidth" onSubmit={handleSubmit}>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Section Name"} isRequired={true} />
            <div className="d-flex mb-2 flex-fill w-100">
              <FormInputField disabled={true} value={sectionName?.name} />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <LabelField labelName={"Primary Subcategory"} isRequired={true} />
            <div className="d-flex mb-2 flex-fill w-100">
              <FormInputField disabled={true} value={subCategory?.name} />
            </div>
          </div>
          {isBaseCombo == true ? (
            <>
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"Combo Batch"} isRequired={true} />
                <div className="d-flex mb-2 flex-fill w-100">
                  <FormInputField
                    disabled={true}
                    value={baseCourseCombo?.name}
                  />
                </div>
              </div>
            </>
          ) : null}
          {renderPackage()}
          <SubmitButton name="Save" validations={validations()} />
        </form>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {packageList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={packageList ? packageList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={packageList ? packageList : []}
          pagename={"basePrice"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Section Name",
            "Primary Subcategory",
            "Is Combination",
            "Actions",
          ]}
          edit={true}
          handleEdit={(value) => handleEdit(value)}
        />
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT DELETE BASE PRICE"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />

      <div className="formDiv">
        <div className="col-12 formWidth">
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Name"} isRequired={true} />
              <CustomDropdown
                setInputText={(value) => onChangeExamName(value)}
                defaultValue={examName?._id}
                options={examList}
                labelName="Select Exam Name"
                reset={resetExam}
              />
            </div>

            {subExamList?.length > 0 ? (
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"Sub Exam Name"} isRequired={true} />
                <CustomDropdown
                  setInputText={(value) => onChangeSubExamName(value)}
                  defaultValue={subExam?._id}
                  options={subExamList}
                  labelName="Select Sub Exam Name"
                  reset={resetSubExam}
                />
              </div>
            ) : null}

            <div className="d-flex mb-2 align-items-center">
              <LabelField labelName={"Exam Stream"} isRequired={true} />
              <CustomDropdown
                setInputText={(value) => onChangeParentCourse(value)}
                defaultValue={parentCourseVal?._id}
                options={parentCourseList}
                labelName="Select Exam Stream"
                reset={resetParentCourse}
              />
            </div>
            {!isEditMode ? (
              <div className="d-flex mb-2 align-items-center">
                <LabelField labelName={"IS Combination"} />
                <Dropdown
                  labelName="Select Combination Flag"
                  setInputText={(value) => onHandleCombo(value)}
                  options={data}
                  value={isCombo}
                />
              </div>
            ) : null}
          </div>
          {!isEditMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </div>
  );
}

export default EditDeleteBasePrice;
