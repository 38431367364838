import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { DigitalMarketingData } from "../../Module/DigitalMarketingPanel/digitalMarketingSidebarData";

const PaidAds = lazy(() => import("../PaidAdsPanel/paidAds"));
const SocialAds = lazy(() => import("../SocialMedialPanel/socialAds"));
const LeadCountDigital = lazy(() => import("./leadCountDigital"));
const YoutubePerformance = lazy(() =>
  import("./Youtube/youtubePerformanceDigital")
);
const OffTopicPerformance = lazy(() =>
  import("./Youtube/offTopicPerformanceDigital")
);
const TargetStatusDigital = lazy(() =>
  import("./DigitalTarget/targetStatusDigital")
);
const CreateDigitalTarget = lazy(() =>
  import("./DigitalTarget/createDigitalTarget")
);
const SocialMediaTeam = lazy(() =>
  import("../CXOPanel/socialMediaPerformance")
);
const YoutubeTarget = lazy(() =>
  import("../CXOPanel/youtubeTargetPerformance")
);
const DigitalLeadAnalysis = lazy(() => import("./leadAnalysisDigital"));
const FacebookGroup = lazy(() => import("./facebookGroup"));
const ShopifyPayment = lazy(() => import("./shopifyPayment"));

const DigitalMarketingDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={DigitalMarketingData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<PaidAds />} />
              <Route path="paidAds" element={<PaidAds />} />
              <Route path="socialAds" element={<SocialAds />} />
              <Route path="leadCountDigital" element={<LeadCountDigital />} />
              <Route
                path="youtubePerformance"
                element={<YoutubePerformance />}
              />
              <Route
                path="offTopicPerformance"
                element={<OffTopicPerformance />}
              />
              <Route
                path="createDigitalTarget"
                element={<CreateDigitalTarget />}
              />
              <Route
                path="targetStatusDigital"
                element={<TargetStatusDigital />}
              />
              <Route path="socialMediaTeam" element={<SocialMediaTeam />} />
              <Route path="youtubeTarget" element={<YoutubeTarget />} />
              <Route
                path="digitalLeadAnalysis"
                element={<DigitalLeadAnalysis />}
              />
              <Route path="facebookGroup" element={<FacebookGroup />} />
              <Route path="shopifyPayment" element={<ShopifyPayment />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default DigitalMarketingDashboard;
