export const FinanceSidebarData = [
  {
    title: "REVENUE DETAILS",
    subNav: [
      {
        title: "TOTAL REVENUE",
        path: "transactionDetails",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDYMATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION PUBLICATION",
        path: "transactionpublication",
        cName: "sub-nav",
      },
      {
        title: "PAYMENT IN PUBLICATION",
        path: "paymentPublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "REVENUE PROJECTION",
        path: "RevenueProjection",
        cName: "sub-nav",
      },
      {
        title: "REVENUE ANALYSIS",
        path: "revenueAnalysis",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
      {
        title: "EXAM WISE REVENUE",
        path: "examWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION - EDUTECH",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
      {
        title: "REVENUE DETAILS",
        path: "RevenueDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSURED REVENUE",
    link: "assuredrevenue",
  },
  {
    title: "Target Status Finance",
    link: "targetStatusFinance",
  },
  // {
  //   title: "REFER & EARN DETAILS",
  //   link: "referEarn",
  // },
  {
    title: "REFERRAL PAYMENT",
    link: "referralPay",
  },
  {
    title: "INVOICE MANAGEMENT",
    subNav: [
      {
        title: "Add Invoice",
        path: "addInvoice",
        cName: "sub-nav",
      },
      {
        title: "Pending For Process",
        path: "pendingForProcess",
        cName: "sub-nav",
      },
      {
        title: "Pending For Payment",
        path: "pendingForPayment",
        cName: "sub-nav",
      },
      {
        title: "Paid Invoice",
        path: "paidInvoice",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REFUNDED AMOUNT",
    link: "refundList",
  },
  {
    title: "ACTIVE USER",
    link: "activeUser",
  },
  {
    title: "CLASSROOM STUDENTS",
    link: "financeClassroomStudent",
  },
  {
    title: "USER INVOICE",
    link: "userInvoice",
  },
  {
    title: "ADD / DOWNLOAD REPORTS",
    subNav: [
      {
        title: "ADD DATA TO REPORTS",
        path: "addDataToReports",
        cName: "sub-nav",
      },
      {
        title: "DOWNLOAD REPORTS",
        path: "downloadReports",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INCENTIVE REVENUE",
    link: "incentiveRevenue",
  },
  {
    title: "INFLUENCER PAYMENT",
    link: "influencerPayment",
  },
];

export const FinanceEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE DETAILS",
    subNav: [
      {
        title: "TOTAL REVENUE",
        path: "transactionDetails",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDYMATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION PUBLICATION",
        path: "transactionpublication",
        cName: "sub-nav",
      },
      {
        title: "PAYMENT IN PUBLICATION",
        path: "paymentPublication",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "REVENUE PROJECTION",
        path: "RevenueProjection",
        cName: "sub-nav",
      },
      {
        title: "REVENUE ANALYSIS",
        path: "revenueAnalysis",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
      {
        title: "EXAM WISE REVENUE",
        path: "examWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION - EDUTECH",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "BatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "DatewiseCount",
        cName: "sub-nav",
      },
      {
        title: "REVENUE DETAILS",
        path: "RevenueDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ASSURED REVENUE",
    link: "assuredrevenue",
  },

  {
    title: "REFER & EARN DETAILS",
    link: "referEarn",
  },
  {
    title: "REFERRAL PAYMENT",
    link: "referralPay",
  },
  {
    title: "INVOICE MANAGEMENT",
    subNav: [
      {
        title: "Add Invoice",
        path: "addInvoice",
        cName: "sub-nav",
      },
      {
        title: "Pending For Process",
        path: "pendingForProcess",
        cName: "sub-nav",
      },
      {
        title: "Pending For Payment",
        path: "pendingForPayment",
        cName: "sub-nav",
      },
      {
        title: "Paid Invoice",
        path: "paidInvoice",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REFUNDED AMOUNT",
    link: "refundList",
  },
  {
    title: "CLASSROOM STUDENTS",
    link: "financeClassroomStudent",
  },
  {
    title: "USER INVOICE",
    link: "userInvoice",
  },
  {
    title: "REPORTS",
    link: "downloadReports",
  },
  {
    title: "INCENTIVE REVENUE",
    link: "incentiveRevenue",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INFLUENCER PAYMENT",
    link: "influencerPayment",
  },
];
