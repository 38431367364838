/* eslint-disable no-undef */
import axios from "axios";
import { store } from "../store";
import { setInitialState } from "../Action/authAction";

const $http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

const $httpCRM = axios.create({
  baseURL: process.env.REACT_APP_CRMBASEURL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

const $httpNew = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

$http.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error?.message?.includes("403")) {
      handleApiError();
    }
    return Promise.reject(error);
  }
);

$httpCRM.interceptors.response.use(
  (config) => config,
  (error) => error
);

const handleApiError = () => {
  const { dispatch } = store;
  dispatch(setInitialState());
  setTimeout(() => {
    window.location = "/";
    return;
  }, 1000);
};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.Auth?.authToken;
        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

$httpCRM.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.Auth?.authToken;
        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

$httpNew.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.Auth?.authToken;

        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  serverError: 500,
};

const postApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .post(endPoint, JSON.stringify(params))
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postApiCallCRM = (endPoint, params, successCallback, errorCallback) => {
  $httpCRM
    .post(endPoint, JSON.stringify(params))
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postApiCallNew = (endPoint, params, successCallback, errorCallback) => {
  $httpNew
    .post(endPoint, JSON.stringify(params))
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postUploadApiCall = (
  endPoint,
  params,
  successCallback,
  errorCallback
) => {
  $http
    .post(endPoint, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const putApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .put(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const postUploadMedia = (endPoint, params, successCallback, errorCallback) => {
  $http
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postUploadPDF = (
  endPoint,
  params,
  queryParams,
  successCallback,
  errorCallback
) => {
  $http
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
      params: queryParams,
    })
    .then((response) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const getApiCall = (endPoint, paramsData, successCallback, errorCallback) => {
  $http
    .get(endPoint + paramsData, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const getApiCallCRM = (
  endPoint,
  paramsData,
  successCallback,
  errorCallback
) => {
  $httpCRM
    .get(endPoint + paramsData, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const patchApiCall = (endPoint, params, successCallback, errorCallback) => {
  $http
    .patch(endPoint, JSON.stringify(params))
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const deleteApiCall = (
  endPoint,
  paramsData,
  successCallback,
  errorCallback
) => {
  $http
    .delete(endPoint + paramsData, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

export default {
  postUploadMedia,
  postApiCall,
  postApiCallCRM,
  postApiCallNew,
  postUploadApiCall,
  getApiCall,
  getApiCallCRM,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  handleApiError,
  setAuthorizationToken,
  postUploadPDF,
  status_code,
  $http,
};
