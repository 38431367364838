export const InfluencerPanelSidebarData = [
  {
    title: "DASHBOARD",
    link: "dashboard",
  },
  {
    title: "REVENUE ANALYSIS",
    link: "revenueAnalysis",
  },
  {
    title: "TOTAL REVENUE",
    link: "totalRevenue",
  },
  {
    title: "LEAD ORDERS",
    link: "leadOrder",
  },
  {
    title: "INFLUENCER RANK",
    link: "influencerRank",
  },
];
