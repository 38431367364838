import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { InfraManagerPanelSidebarData } from "../../Module/infraManagerPanelSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router-dom";

const AddAsset = lazy(() => import("./Asset/addAsset"));
const GetAsset = lazy(() => import("./Asset/getAsset"));
const AddInventoryManagement = lazy(() =>
  import("./InventoryManagement/addInventoryManagement")
);
const EditInventoryManagement = lazy(() =>
  import("./InventoryManagement/editInventoryManagement")
);
const CreateDeviceCompany = lazy(() =>
  import("./DeviceCompany/createDeviceCompany")
);
const EditDeviceCompany = lazy(() =>
  import("./DeviceCompany/editDeviceCompany")
);
const CreateDeviceModel = lazy(() => import("./DeviceModel/createDeviceModel"));
const EditDeviceModel = lazy(() => import("./DeviceModel/editDeviceModel"));

const InfraManagerPanelDashboard = () => {
  return (
    <div className="AppBox">
      <Sidebar data={InfraManagerPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<AddAsset />} />
              <Route path="/addAsset" element={<AddAsset />} />
              <Route path="/getAsset" element={<GetAsset />} />
              <Route
                path="/addInventory"
                element={<AddInventoryManagement />}
              />
              <Route
                path="/editInventory"
                element={<EditInventoryManagement />}
              />
              <Route
                path="/createDeviceCompany"
                element={<CreateDeviceCompany />}
              />
              <Route
                path="/editDeviceCompany"
                element={<EditDeviceCompany />}
              />
              <Route
                path="/createDeviceModel"
                element={<CreateDeviceModel />}
              />
              <Route path="/editDeviceModel" element={<EditDeviceModel />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default InfraManagerPanelDashboard;
