import "../../App.css";
import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  CategoryPanelManagerSidebarData,
  CategoryPanelSidebarData,
} from "../../Module/categoryPanelSidebarData";
import EditDeleteSyllabus from "./editSyllabus";
import CxoBatchwiseCount from "../Finance/cxobatchwiseCount";
import AdmissionDetails from "../Finance/admissionDetails";
import { useSelector } from "react-redux";

const DatewiseCount = React.lazy(() => import("../Finance/datewiseCount"));
const RevenueDetails = React.lazy(() => import("../Finance/revenueDetails"));
const CreatePattern = React.lazy(() => import("./createPattern"));
const EditDeletePattern = React.lazy(() => import("./editDeletePattern"));

const StudentCount = React.lazy(() => import("../CXOPanel/studentCount"));
const LeadDashboard = React.lazy(() => import("./leadDashboard"));
const LeadCount = React.lazy(() => import("./leadCount"));
const LeadAnalysis = React.lazy(() => import("./leadAnalysis"));
const ClassroomRegistration = React.lazy(() =>
  import("./classroomRegistration")
);
const MonthWiseRevenue = React.lazy(() => import("./monthWiseRevenue"));

const TeacherFeedback = React.lazy(() => import("../CXOPanel/teacherFeedback"));
const TeacherLectureFeedback = React.lazy(() =>
  import("../CXOPanel/teacherLectureFeedback")
);

const CreateAssignBooks = React.lazy(() =>
  import("../Publication/createAssignBooks")
);
const EditDeleteAssignBooks = React.lazy(() =>
  import("../Publication/editDeleteAssignBooks")
);

const TargetStatus = React.lazy(() => import("./targetStatus"));
const CreateTarget = React.lazy(() => import("./createTarget"));

const CreateAssignUnits = React.lazy(() => import("./createAssignUnits"));
const EditDeleteAssignUnit = React.lazy(() => import("./editDeleteAssignUnit"));

const CreatePackage = React.lazy(() => import("./createPackage"));
const EditDeletePackage = React.lazy(() => import("./editDeletePackage"));

const AddAdBaner = React.lazy(() => import("./addAdBaner"));
const EditDeleteAdBaner = React.lazy(() => import("./editDeleteAdBaner"));

const ExamSyllabus = React.lazy(() => import("./createExamSyllabus"));
const EditDeleteExamSyllabus = React.lazy(() =>
  import("./editDeleteExamSyllabus")
);

const AllAboutExam = React.lazy(() => import("./createAllAboutExam"));

const ExamDates = React.lazy(() => import("./createExamDates"));

const CreatePyqPdf = React.lazy(() => import("./createPyqPdf"));
const EditDeletePyqPdf = React.lazy(() => import("./editDeletePyqPdf"));

const PaperAnalysis = React.lazy(() => import("./createPaperAnalysis"));

const CutOff = React.lazy(() => import("./createCutOff"));

const ScholarshipExamResult = React.lazy(() =>
  import("../SalesUpsellingPanel/scholarshipExamResult")
);

const AddResults = React.lazy(() => import("./addResults"));
const EditDeleteResults = React.lazy(() => import("./editDeleteResults"));
const CopyResults = React.lazy(() => import("./copyResultsList"));

const AddExamResults = React.lazy(() => import("./addExamResults"));
const EditDeleteExamResults = React.lazy(() =>
  import("./editDeleteExamResults")
);

const AddTeacherTask = React.lazy(() => import("./addTeacherTask"));
const EditDeleteTeacherTask = React.lazy(() =>
  import("./editDeleteTeacherTask")
);
const AddSyllabus = React.lazy(() => import("./addSyllabus"));
const AddYoutubeData = React.lazy(() => import("./addYoutubeData"));
const EditYoutubeData = React.lazy(() => import("./editYoutubeData"));
const TeacherTaskPlan = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlan")
);
const TeacherTaskPlanComplete = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanComplete")
);
const TeacherTaskPlanPending = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanPending")
);

const TeacherTaskPlanNotCompleted = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanNotCompleted")
);

const AddAcademicHeadEmployee = React.lazy(() =>
  import("../AcademicHeadPanel/addAcademicHeadEmployee")
);
const EditDeleteAcademicHeadEmployee = React.lazy(() =>
  import("../AcademicHeadPanel/editDeleteAcademicHeadEmployee")
);
const TeacherPerformance = React.lazy(() =>
  import("./teacherPerformanceCategory")
);
const ResultCompilation = React.lazy(() => import("./resultCompilation"));
const StudentResult = React.lazy(() => import("./studentResult"));
const AddFreeUserVideo = React.lazy(() =>
  import("../DataEntryPanel/addFreeUserVideo")
);
const EditDeleteFreeUserVideo = React.lazy(() =>
  import("../DataEntryPanel/editDeleteFreeUserVideo")
);
const AddTeacherTaskCategory = React.lazy(() =>
  import("./TeacherTask/addTeacherTaskCategory")
);
const EditDeleteTeacherTaskCategory = React.lazy(() =>
  import("./TeacherTask/editTeacherTaskCategory")
);
const AddInfluencer = React.lazy(() => import("./Influencer/addInfluencer"));
const GetInfluencer = React.lazy(() => import("./Influencer/getInfluencer"));
const AddCompetitor = lazy(() => import("./Competitor/addCompetitor"));
const EditCompetitor = lazy(() => import("./Competitor/editCompetitor"));
const AddLiveCompetitorAnalysis = lazy(() =>
  import(
    "../CategoryPanel/CompetitorAnalysis/LiveAnalysis/addLiveCompetitorAnalysis"
  )
);
const EditLiveCompetitorAnalysis = lazy(() =>
  import(
    "../CategoryPanel/CompetitorAnalysis/LiveAnalysis/editLiveCompetitorAnalysis"
  )
);
const AddOffVideoCompetitorAnalysis = lazy(() =>
  import(
    "../CategoryPanel/CompetitorAnalysis/OffVideoAnalysis/addOffVideoAnalysis"
  )
);
const EditOffVideoCompetitorAnalysis = lazy(() =>
  import(
    "../CategoryPanel/CompetitorAnalysis/OffVideoAnalysis/editOffVideoAnalysis"
  )
);
const SocialMediaLinks = lazy(() =>
  import("../SocialMedialPanel/socialMediaLinks")
);
const EditDeleteSocialMediaLink = lazy(() =>
  import("../SocialMedialPanel/editDeleteSocialMediaLink")
);
const OffTopicPerformance = lazy(() =>
  import("../CategoryPanel/offTopicPerformance")
);
function CategoryPanelDashboard() {
  const { designation } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
  }));
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={
          designation === "CATEGORY MANAGER"
            ? CategoryPanelManagerSidebarData
            : CategoryPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<LeadDashboard />} />
              <Route path="leadDashboard" element={<LeadDashboard />} />
              {/* <Route path="BatchwiseCount" element={<BatchwiseCount />} /> */}
              <Route path="DatewiseCount" element={<DatewiseCount />} />
              <Route path="RevenueDetails" element={<RevenueDetails />} />

              <Route path="BatchwiseCount" element={<CxoBatchwiseCount />} />
              <Route path="admissionDetails" element={<AdmissionDetails />} />

              <Route path="StudentCount" element={<StudentCount />} />
              <Route path="LeadCount" element={<LeadCount />} />
              <Route
                path="classroomRegistration"
                element={<ClassroomRegistration />}
              />
              <Route path="teacherFeedback" element={<TeacherFeedback />} />
              <Route
                path="teacherLectureFeedback"
                element={<TeacherLectureFeedback />}
              />

              <Route path="createAssignBooks" element={<CreateAssignBooks />} />
              <Route
                path="EditDeleteAssignBooks"
                element={<EditDeleteAssignBooks />}
              />

              <Route path="createAssignUnits" element={<CreateAssignUnits />} />
              <Route
                path="editDeleteAssignUnit"
                element={<EditDeleteAssignUnit />}
              />
              <Route path="createPackage" element={<CreatePackage />} />
              <Route path="editDeletePackage" element={<EditDeletePackage />} />

              <Route path="addAdBaner" element={<AddAdBaner />} />
              <Route path="editDeleteAdBaner" element={<EditDeleteAdBaner />} />

              <Route path="createExamSyllabus" element={<ExamSyllabus />} />
              <Route
                path="editDeleteExamSyllabus"
                element={<EditDeleteExamSyllabus />}
              />
              <Route path="allAboutExam" element={<AllAboutExam />} />

              <Route path="createExamDates" element={<ExamDates />} />

              <Route path="createPyqPdf" element={<CreatePyqPdf />} />
              <Route path="editDeletePyqPdf" element={<EditDeletePyqPdf />} />

              <Route
                path="createExamPaperAnalysis"
                element={<PaperAnalysis />}
              />
              <Route path="cutOff" element={<CutOff />} />
              <Route
                path="ScholarshipExamResult"
                element={<ScholarshipExamResult />}
              />

              <Route path="addResults" element={<AddResults />} />
              <Route path="editDeleteResults" element={<EditDeleteResults />} />
              <Route path="copyResultsList" element={<CopyResults />} />

              <Route path="addExamResults" element={<AddExamResults />} />
              <Route
                path="editDeleteExamResults"
                element={<EditDeleteExamResults />}
              />

              <Route path="addTeacherTask" element={<AddTeacherTask />} />
              <Route
                path="editDeleteTeacherTask"
                element={<EditDeleteTeacherTask />}
              />

              <Route path="addNewSyllabus" element={<AddSyllabus />} />
              <Route path="editSyllabus" element={<EditDeleteSyllabus />} />
              <Route path="addYoutubeData" element={<AddYoutubeData />} />
              <Route path="editYoutubeData" element={<EditYoutubeData />} />
              <Route path="teacherTaskPlan" element={<TeacherTaskPlan />} />
              <Route
                path="teacherTaskPlanComplete"
                element={<TeacherTaskPlanComplete />}
              />
              <Route path="createPattern" element={<CreatePattern />} />
              <Route path="editDeletePattern" element={<EditDeletePattern />} />
              <Route
                path="teacherTaskPlanPending"
                element={<TeacherTaskPlanPending />}
              />
              <Route
                path="teacherTaskPlanNotCompleted"
                element={<TeacherTaskPlanNotCompleted />}
              />
              <Route
                path="/addAcademicHeadEmployee"
                element={<AddAcademicHeadEmployee />}
              />
              <Route
                path="/editDeleteAcademicHeadEmployee"
                element={<EditDeleteAcademicHeadEmployee />}
              />
              <Route path="/createTarget" element={<CreateTarget />} />
              <Route path="/targetStatus" element={<TargetStatus />} />
              <Route path="/leadAnalysis" element={<LeadAnalysis />} />
              <Route path="monthWiseRevenue" element={<MonthWiseRevenue />} />
              <Route
                path="/teacherPerformanceCat"
                element={<TeacherPerformance />}
              />
              <Route
                path="offTopicPerformance"
                element={<OffTopicPerformance />}
              />
              <Route path="resultCompilation" element={<ResultCompilation />} />
              <Route path="studentResult" element={<StudentResult />} />
              <Route path="addFreeVideo" element={<AddFreeUserVideo />} />
              <Route
                path="editFreeVideo"
                element={<EditDeleteFreeUserVideo />}
              />
              <Route
                path="addTeacherTaskCategory"
                element={<AddTeacherTaskCategory />}
              />
              <Route
                path="editTeacherTaskCategory"
                element={<EditDeleteTeacherTaskCategory />}
              />
              <Route path="addInfluencer" element={<AddInfluencer />} />
              <Route path="getInfluencer" element={<GetInfluencer />} />
              <Route path="addCompetitor" element={<AddCompetitor />} />
              <Route path="editCompetitor" element={<EditCompetitor />} />
              <Route
                path="addLiveCompetitorAnalysis"
                element={<AddLiveCompetitorAnalysis />}
              />
              <Route
                path="editLiveCompetitorAnalysis"
                element={<EditLiveCompetitorAnalysis />}
              />
              <Route
                path="addOffVideoCompetitorAnalysis"
                element={<AddOffVideoCompetitorAnalysis />}
              />
              <Route
                path="editOffVideoCompetitorAnalysis"
                element={<EditOffVideoCompetitorAnalysis />}
              />
              <Route path="socialMediaLinks" element={<SocialMediaLinks />} />
              <Route
                path="editDeleteSocialMediaLink"
                element={<EditDeleteSocialMediaLink />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default CategoryPanelDashboard;
